var one = function one(el, type, fn) {
  var handler = function handler(event) {
    el.removeEventListener(type, handler);
    fn(event);
  };

  el.addEventListener(type, handler);
};

var on = function on(element, events, callback) {
  var capture = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  if (Array.isArray(events)) {
    events.forEach(function (evt) {
      element.addEventListener(evt, function (e) {
        callback.call(element, e);
      }, capture);
    });
  } else {
    element.addEventListener(events, function (e) {
      callback.call(element, e);
    }, capture);
  }
};

var off = function off(element, events, callback, capture) {
  if (Array.isArray(events)) {
    events.forEach(function (evt) {
      element.removeEventListener(evt, function (e) {
        callback.call(element, e);
      }, capture);
    });
  } else {
    element.removeEventListener(events, function (e) {
      callback.call(element, e);
    }, capture);
  }

  return callback;
};

var trigger = function trigger(el, events) {
  var e = document.createEvent('HTMLEvents');

  if (Array.isArray(events)) {
    events.forEach(function (type) {
      e.initEvent(type, false, true);
      el.dispatchEvent(e);
    });
  } else {
    e.initEvent(events, false, true);
    el.dispatchEvent(e);
  }
};

export { trigger, on, one, off };