import Handlebars from 'handlebars';

Handlebars.getTemplate = function (name, temp, cache) {
  return new Promise(function (resolve, reject) {
    if (Handlebars.templates === undefined || Handlebars.templates[name] === undefined) {
      $.ajax({
        url: SETTINGS.templates + temp + '.html',
        cache: !cache ? cache : true,
        dataType: "text",
        success: function success(data) {
          if (Handlebars.templates === undefined) {
            Handlebars.templates = {};
          }

          Handlebars.templates[name] = Handlebars.compile(data);
          var tmp = Handlebars.templates[name];
          resolve(tmp);
        },
        error: function error(err) {
          reject(err);
        }
      });
    }
  });
};

Handlebars.registerHelper('decodeURIComponent', function (inputData) {
  var html = decodeURIComponent(inputData);
  return new Handlebars.SafeString(html);
});
Handlebars.registerHelper('dateFormated', function (inputData) {
  var meses = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre'
  };
  var html = inputData.split('T')[0].split('-');
  html = "<span class=\"fecha-historial\">".concat(html[2], "</span> <span class=\"fecha-mes\">").concat(meses[parseInt(html[1])], "</span>");
  return new Handlebars.SafeString(html);
});
Handlebars.registerHelper('formatDate', function (inputData) {
  var html = parseDateByInput(inputData);
  return html.full;
});
Handlebars.registerHelper('if_equals_2', function (value, options) {
  console.log(value);

  if (parseInt(value) === 2) {
    // console.log(value, value.length);
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
Handlebars.registerHelper('if_equals_1', function (value, options) {
  if (value.length === 1) {
    // console.log(value, value.length);
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
Handlebars.registerHelper('decodeURIComponent', function (inputData) {
  var html = decodeURIComponent(inputData);
  return new Handlebars.SafeString(html);
});
Handlebars.registerHelper('formatDate', function (inputData) {
  var html = parseDateByInput(inputData);
  return html.full;
});
Handlebars.registerHelper('if_exist', function (a, opts) {
  if (a === null || a === undefined) {
    return opts.inverse(this);
  }

  var bool = '';

  if (typeof a === 'string') {
    bool = !a.length > 0;
  } else {
    isEmpty(a);
  }

  if (Array.isArray(a)) {
    bool = !a.length > 0;
  }

  if (!bool) {
    return opts.fn(this);
  } else {
    return opts.inverse(this);
  }
}); // Handlebars.registerHelper('if_equals_1', function (value, options) {
//     if (value.length === 1) {
//         return options.fn(this);
//     } else {
//         return options.inverse(this);
//     }
// });

function parseDateByInput(d) {
  var date = new Date(d);
  var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(),
      h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
      hora = h + ':' + m;
  var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
      mm = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
      mmindex = date.getMonth(),
      yyyy = date.getFullYear();
  var meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  return {
    f: dd + '/' + mm + '/' + yyyy,
    h: hora,
    fh: [yyyy, mm, dd, h, m].join(''),
    full: dd + ' de ' + meses[mmindex] + ' del ' + yyyy + ' ' + hora + 'hs'
  };
}

Handlebars.registerHelper('svgRaw', function (inputData) {
  var svg = decodeURIComponent(inputData);
  return new Handlebars.SafeString(svg);
});
Handlebars.registerHelper('link_to', function () {
  return new Handlebars.SafeString(Handlebars.Utils.escapeExpression(SETTINGS.links));
});
Handlebars.registerHelper('sum', function (a, b, c, d) {
  if (typeof a === 'string') {
    a = parseFloat(a);
  }

  if (typeof b === 'string') {
    b = parseFloat(b);
  }

  if (typeof c === 'string') {
    c = parseFloat(c);
  }

  if (typeof d === 'string') {
    d = parseFloat(d);
  }

  return a + b + c + d;
});
Handlebars.registerHelper('times', function (n, block) {
  var accum = '';

  for (var i = 0; i < n; ++i) {
    accum += block.fn(i);
  }

  return accum;
});
Handlebars.registerHelper('for', function (from, to, incr, block) {
  var accum = '';

  for (var i = from; i < to; i += incr) {
    accum += block.fn(i);
  }

  return accum;
});
Handlebars.registerHelper('mes', function (number, short) {
  var meses = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre'
  };

  if (short === 'short') {
    meses = {
      1: 'Ene',
      2: 'Feb',
      3: 'Mar',
      4: 'Abr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Ago',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dic'
    };
  }

  return meses[number];
});
Handlebars.registerHelper('dia', function (number, short) {
  var meses = {
    1: 'Lunes',
    2: 'Martes',
    3: 'Miercoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sabado',
    0: 'Domingo'
  };

  if (short === 'short') {
    meses = {
      1: 'Lun',
      2: 'Mar',
      3: 'Mie',
      4: 'Jue',
      5: 'Vie',
      6: 'Sab',
      0: 'Dom'
    };
  }

  return meses[number];
});
Handlebars.registerHelper('replace', function (str) {
  return str.replace(/\s/g, '_').replace(/\//g, '');
});
Handlebars.registerHelper('to_number', function (number) {
  if (number < 10) {
    number = '0' + number;
  }

  return number;
});
Handlebars.registerHelper('to_hidden_value', function (array) {
  var lSeleccionadasGrupoid = [];
  array.forEach(function (item) {
    lSeleccionadasGrupoid.push(item.idPosicion);
  });
  return '[' + lSeleccionadasGrupoid.toString() + ']';
});
Handlebars.registerHelper('optionMes', function (number) {
  var meses = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre'
  };
  var tmp = '';

  for (var prop in meses) {
    if (meses.hasOwnProperty(prop)) {
      // console.log( parseInt(prop) === parseInt(number) )
      if (parseInt(prop) === parseInt(number)) {
        tmp += '<option value="' + prop + '" selected>' + meses[prop] + '</option>';
      } else {
        tmp += '<option value="' + prop + '">' + meses[prop] + '</option>';
      }
    }
  }

  return new Handlebars.SafeString(tmp);
});
Handlebars.registerHelper('optionDia', function (number) {
  var tmp = '';

  for (var i = 1; i < 32; i++) {
    if (i === parseInt(number)) {
      tmp += '<option value="' + i + '" selected>' + i + '</option>';
    } else {
      tmp += '<option value="' + i + '">' + i + '</option>';
    }
  }

  return new Handlebars.SafeString(tmp);
});
Handlebars.registerHelper('optionAnio', function (number) {
  var tmp = '';

  for (var i = 1920; i < 2019; i++) {
    if (i === parseInt(number)) {
      tmp += '<option value="' + i + '" selected>' + i + '</option>';
    } else {
      tmp += '<option value="' + i + '">' + i + '</option>';
    }
  }

  return new Handlebars.SafeString(tmp);
});
Handlebars.registerHelper('optionProv', function (txt) {
  var propv = ["Buenos Aires", "CABA", "Catamarca", "Chaco", "Chubut", "Cordoba", "Corrientes", "Entre Rios", "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquen", "Rio Negro", "Salta", "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero", "Tierra del Fuego", "Tucuman", "Otra"];
  var tmp = '';

  for (var i = 0; i < propv.length; i++) {
    if (propv[i] === txt) {
      tmp += '<option value="' + propv[i] + '" selected>' + propv[i] + '</option>';
    } else {
      tmp += '<option value="' + propv[i] + '">' + propv[i] + '</option>';
    }
  }

  return new Handlebars.SafeString(tmp);
});
Handlebars.registerHelper('compare', function (lvalue, operator, rvalue, options) {
  var operators, result;

  if (arguments.length < 3) {
    throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
  }

  if (Array.isArray(lvalue)) {
    lvalue = lvalue.length;
  }

  if (options === undefined) {
    options = rvalue;
    rvalue = operator;
    operator = "===";
  }

  operators = {
    '===': function _(l, r) {
      return l === r;
    },
    '!==': function _(l, r) {
      return l !== r;
    },
    '<': function _(l, r) {
      return l < r;
    },
    '>': function _(l, r) {
      return l > r;
    },
    '<=': function _(l, r) {
      return l <= r;
    },
    '>=': function _(l, r) {
      return l >= r;
    },
    'typeof': function _typeof(l, r) {
      return typeof l === r;
    }
  };

  if (!operators[operator]) {
    throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);
  }

  result = operators[operator](lvalue, rvalue);

  if (result) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
Handlebars.registerHelper('select', function (value, options) {
  return options.fn().split('\n').map(function (v) {
    var t = 'value="' + value + '"';
    return RegExp(t).test(v) ? v.replace(t, t + ' selected="selected"') : v;
  }).join('\n');
});