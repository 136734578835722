import { on } from '../../utils/events';
import TemplateManager from '../core/TemplateManager';
import Observable from '../../utils/observable';
import is from 'is';
import isMobile from '../../utils/isMobile';
import page from 'page';
import scrolltop from '../../utils/scrolltop';
import EnviosSeleccion from '../../modules/shared/envios';
import PagosSeleccion from '../../modules/shared/pagos';
var oEntregaSeleccion = null;
var oPagoSeleccion = null;
var doEnvio = false;
var pObject = {};
var objEnvioPago = {};
var blackList = [];
var tplManager = null;
var observable = new Observable();
var observableTokens = new Observable();
var oPrecioObservable = new Observable();
var observableValidation = new Observable();
var oEntregaSeleccionar = new Observable();
var oPagoSeleccionar = new Observable();
var tipoTelefono = {
  1: 'Fijo',
  2: 'Celular'
};
var tipoDom = {
  1: 'Particular',
  2: 'Trabajo'
};
observable.add(function (obj) {
  if (is.empty(obj)) {
    return;
  }

  var self = this;
  var keys = Object.keys(obj);
  keys.forEach(function (value) {
    if (obj[value] !== null && obj[value] !== undefined) {
      Object.assign(self.data, obj);
    } else {
      if (value in self.data) {
        delete self.data[value];
      }
    }
  });

  if (self.data['idEntregaEntidad'] === 0 || self.data['idEntregaEntidad'] === undefined) {
    if (self.data['idClienteDomicilio'] !== undefined) {
      if (self.data['idClienteDomicilio'] === 0) {
        observableValidation.notify({
          Envio: {
            estado: false,
            msg: ''
          }
        });
      } else {}
    } else {
      observableValidation.notify({
        Envio: {
          estado: false,
          msg: ''
        }
      });
    }

    if (self.data['idClienteTelefono'] !== undefined) {
      if (self.data['idClienteTelefono'] === 0) {
        observableValidation.notify({
          Envio: {
            estado: false,
            msg: ''
          }
        });
      } else {}
    } else {
      observableValidation.notify({
        Envio: {
          estado: false,
          msg: ''
        }
      });
    }

    if (self.data['idClienteDomicilio'] !== undefined && self.data['idClienteTelefono'] !== undefined) {
      if (self.data['idClienteDomicilio'] > 0 && self.data['idClienteTelefono'] > 0) {
        observableValidation.notify({
          Envio: {
            estado: true,
            msg: ''
          }
        });
      } else {}
    }
  }

  if (self.data['idEntregaTipo'] === 9 || self.data['idEntregaTipo'] === 7) {
    observableValidation.notify({
      Envio: {
        estado: true,
        msg: ''
      }
    });
  } // console.info(this.data)

});
observableTokens.add(function (obj) {
  if (is.empty(obj)) {
    return;
  }

  var self = this;
  var keys = Object.keys(obj);
  keys.forEach(function (value) {
    if (obj[value] !== null && obj[value] !== undefined) {
      Object.assign(self.data, obj);
    } else {
      if (value in self.data) {
        delete self.data[value];
      }
    }
  }); // console.log( this.data )
});
oPrecioObservable.add(function (obj) {
  // let price = document.querySelector('#entregaenvio')
  var valor = document.querySelector('#valor');
  var tt = document.querySelector('#tt');
  var total = pObject['Proceso'].oPrecioTotal.fPrecioTotalFinal;

  if (obj.fprecio > 0) {// price.style.display = 'block'
    // valor.innerHTML = `$${obj.fprecio}`
    // tt.innerHTML = `$${(total+obj.fprecio)}`
  } else {// price.style.display = 'none'
      // valor.innerHTML = `$0`
      // tt.innerHTML = `$${total}`
    }
});
observableValidation.add(function (obj) {
  if (is.empty(obj)) {
    return;
  }

  var self = this;
  var keys = Object.keys(obj);
  keys.forEach(function (value) {
    if (obj[value] !== null && obj[value] !== undefined) {
      Object.assign(self.data, obj);
    } else {
      if (value in self.data) {
        delete self.data[value];
      }
    }
  }); // let button = document.querySelector('#submit-process')

  var leyendaRequireSucursal = document.querySelector('#leyendaRequireSucursal');

  if (self.data.Envio && self.data.Pago) {// if ( self.data.Envio['estado'] === true && self.data.Pago['estado'] === true ) {
    //   button.disabled = false
    //   button.style.opacity = 1
    //   button.style.cursor = 'default'
    // } else {
    //   button.disabled = true
    //   button.style.opacity = .5
    //   button.style.cursor = 'not-allowed'
    // }
  }

  if (leyendaRequireSucursal) {
    if (self.data.Envio.msg !== '') {
      if (self.data.Envio.estado === false) {
        leyendaRequireSucursal.innerHTML = "<h3>".concat(self.data.Envio.msg, "</h3>");
      } else {
        leyendaRequireSucursal.innerHTML = "";
      }
    }
  } // console.info(this.data)

});
oEntregaSeleccionar.add(function (obj) {
  if (is.empty(obj)) {
    return;
  }

  var loading = document.querySelector('#load-proceso');
  var legend = loading.querySelector('#load-legend');
  legend.innerHTML = "Procesando su selecci&oacute;n, <br> Por favor espere";
  legend.style.textAlign = 'center';
  var checkList = $('.check');

  switch (obj.state) {
    case 'ENDED':
      checkList.removeClass('disabled');

      if (loading) {
        loading.classList.remove('is-visible');
        loading.classList.add('is-hidden');
      }

      this.state = 'ENDED'; // console.info('SELECCIONO ENTREGA')

      break;

    case 'PENDING':
      checkList.addClass('disabled');

      if (loading) {
        if (loading.classList.contains('is-hidden')) {
          loading.classList.remove('is-hidden');
          loading.classList.add('is-visible');
        }
      }

      this.state = 'PENDING'; // console.info('ESPERANDO RESPUESTA')

      break;

    case 'PREPARE':
      checkList.removeClass('disabled');

      if (loading) {
        if (loading.classList.contains('is-visible')) {
          loading.classList.remove('is-visible');
          loading.classList.add('is-hidden');
        }
      }

      this.state = 'PREPARE'; // console.info('NO SELECCIONA AUN')

      break;
  }
});
oPagoSeleccionar.add(function (obj) {
  if (is.empty(obj)) {
    return;
  }

  var loading = document.querySelector('#load-proceso');
  var legend = loading.querySelector('#load-legend');
  legend.innerHTML = "Procesando su selecci&oacute;n, <br> Por favor espere";
  legend.style.textAlign = 'center';
  var checkList = $('.check');

  switch (obj.state) {
    case 'ENDED':
      checkList.removeClass('disabled');

      if (loading) {
        loading.classList.remove('is-visible');
        loading.classList.add('is-hidden');
      }

      this.state = 'ENDED'; // console.info('SELECCIONO FORMA DE PAGO')

      break;

    case 'PENDING':
      checkList.addClass('disabled');

      if (loading) {
        if (loading.classList.contains('is-hidden')) {
          loading.classList.remove('is-hidden');
          loading.classList.add('is-visible');
        }
      }

      this.state = 'PENDING'; // console.info('ESPERANDO RESPUESTA')

      break;

    case 'PREPARE':
      checkList.removeClass('disabled');

      if (loading) {
        if (loading.classList.contains('is-visible')) {
          loading.classList.remove('is-visible');
          loading.classList.add('is-hidden');
        }
      }

      this.state = 'PREPARE'; // console.info('NO SELECCIONA AUN')

      break;
  }
});

function removeClassIsChecked(classname, current) {
  var inputs = [].slice.call(document.querySelectorAll(classname));
  inputs.forEach(function (button) {
    var node = button.querySelector('input[type="radio"]');

    if (node.nodeName === 'INPUT' && node !== current) {
      button.classList.remove('is-checked');
      node.checked = false;
    } else {}
  });
}

function initCheckEntrega(oData, api) {
  var innerSelection = document.querySelector('#entrega');
  var checkEntrega = [].slice.call(document.querySelectorAll('#entrega-tipos .check'));
  var Entidades = {};
  oData.TiposEntrega.Lista.forEach(function (obj) {
    if (!Entidades[obj.idEntregaTipo]) {
      if (obj.listaEntidadLocalidad) {
        obj.listaEntidadLocalidad = obj.listaEntidadLocalidad.sort(function (a, b) {
          return a < b ? -1 : a > b ? 1 : 0;
        });
      }

      if (obj.listaEntidadProvincia) {
        obj.listaEntidadProvincia = obj.listaEntidadProvincia.sort(function (a, b) {
          return a < b ? -1 : a > b ? 1 : 0;
        });
      }

      if (obj.listaEntidades) {
        if (obj.idEntregaTipo === 6) {
          obj.listaEntidades = obj.listaEntidades.sort(function (a, b) {
            return "".concat(a.cLocalidad, ", ").concat(a.cDomicilio) < "".concat(b.cLocalidad, ", ").concat(b.cDomicilio) ? -1 : "".concat(a.cLocalidad, ", ").concat(a.cDomicilio) > "".concat(b.cLocalidad, ", ").concat(b.cDomicilio) ? 1 : 0;
          });
        } else {
          obj.listaEntidades = obj.listaEntidades.sort(function (a, b) {
            return a.cNombre < b.cNombre ? -1 : a.cNombre > b.cNombre ? 1 : 0;
          });
        }
      }

      Entidades[obj.idEntregaTipo] = obj;
    }
  });

  function checkState(button, one) {
    $('#provinciaEntidad').selectpicker('toggle');
    $('#idEntregaEntidad').selectpicker('toggle');
    innerSelection.innerHTML = '';
    var node = button.querySelector('input[type="radio"]');

    if (node.nodeName === 'INPUT') {
      var isChecked = node.checked;
      removeClassIsChecked('#entrega-tipos .check', node);

      if (isChecked) {
        var valor = parseInt(button.getAttribute('rel'));

        if (valor > 0) {
          oPrecioObservable.notify({
            fprecio: valor
          });
        } else {
          oPrecioObservable.notify({
            fprecio: -1
          });
        }

        var bRequiereDomicilio = $(node).attr('data-domicilio-requerido');
        var bRequiereEntidadAsociada = $(node).attr('data-entidad-requerido');
        var cNombreEntregaTipoSeleccionada = $(node).attr('data-nombre-tipo');

        if (observable.data['idEntregaEntidad']) {
          observable.remove('idEntregaEntidad');
        }

        var oobj = {
          idEntregaTipo: parseInt(node.value),
          cNombreEntregaTipoSeleccionada: cNombreEntregaTipoSeleccionada,
          idClienteInformacionImpositiva: 0,
          idClienteTelefono: 0,
          idClienteDomicilio: 0
        };
        observable.notify(oobj);

        if (bRequiereEntidadAsociada === 'true') {
          // console.info(Entidades)
          observableValidation.notify({
            Envio: {
              estado: false,
              msg: 'Debe seleccionar una sucursal'
            }
          });

          if (!tplManager.exist('combos')) {
            tplManager.add('combos', '/templates/entrega/combos.html').then(function (compiler) {
              innerSelection.innerHTML = compiler(Entidades[oobj.idEntregaTipo]);

              if (one) {
                initSelectoresEntidad(Entidades[oobj.idEntregaTipo], true);
              } else {
                initSelectoresEntidad(Entidades[oobj.idEntregaTipo], false);
              }
            }).catch(function (e) {
              console.log(e);
            });
          } else {
            var compiler = tplManager.templates['combos'];
            innerSelection.innerHTML = compiler(Entidades[oobj.idEntregaTipo]);

            if (one) {
              initSelectoresEntidad(Entidades[oobj.idEntregaTipo], true);
            } else {
              initSelectoresEntidad(Entidades[oobj.idEntregaTipo], false);
            }
          }
        } else if (bRequiereDomicilio === 'true') {
          var domi = $('<div/>').attr('id', 'direccion').appendTo(innerSelection);
          var tele = $('<div/>').attr('id', 'telefono').appendTo(innerSelection);
          observableValidation.notify({
            Envio: {
              estado: false,
              msg: 'Debe seleccionar un domicilio'
            }
          });
          api.getClienteInfomacion().then(function (informacion) {
            if (informacion.nCode === 200) {
              if (informacion.oData) {
                var domicilios = informacion.oData.listaClienteDomicilio.length ? informacion.oData.listaClienteDomicilio : [];
                var telefonos = informacion.oData.listaClienteTelefono.length ? informacion.oData.listaClienteTelefono : [];
                var impositiva = informacion.oData.listaClienteInformacionImpositiva.length ? informacion.oData.listaClienteInformacionImpositiva : [];
                telefonos.map(function (tel, index) {
                  tel['cTipoNombre'] = tipoTelefono[tel.idTelefonoTipo] + ' ' + (index + 1);
                  return tel;
                });
                domicilios.map(function (tel, index) {
                  tel['cTipoNombre'] = tipoDom[tel.idDomicilioTipo] + ' ' + (index + 1);
                  return tel;
                });
                Object.assign(Entidades[oobj.idEntregaTipo], {
                  Impositiva: impositiva,
                  Telefonos: telefonos,
                  Domicilios: domicilios
                });

                if (!tplManager.exist('domicilio')) {
                  tplManager.add('domicilio', '/templates/entrega/domicilio.html').then(function (compiler) {
                    domi[0].innerHTML = compiler(Entidades[oobj.idEntregaTipo]);
                    initSelectoresDomicilioSeleccion(Entidades[oobj.idEntregaTipo], api);
                  }).catch(function (e) {
                    console.log(e);
                  });
                } else {
                  var _compiler = tplManager.templates['domicilio'];
                  domi[0].innerHTML = _compiler(Entidades[oobj.idEntregaTipo]);
                  initSelectoresDomicilioSeleccion(Entidades[oobj.idEntregaTipo], api);
                }

                if (!tplManager.exist('telefono')) {
                  tplManager.add('telefono', '/templates/entrega/telefono.html').then(function (compiler) {
                    tele[0].innerHTML = compiler(Entidades[oobj.idEntregaTipo]);
                    initSelectoresTelefonoSeleccion(Entidades[oobj.idEntregaTipo], api);
                  }).catch(function (e) {
                    console.log(e);
                  });
                } else {
                  var _compiler2 = tplManager.templates['telefono'];
                  tele[0].innerHTML = _compiler2(Entidades[oobj.idEntregaTipo]);
                  initSelectoresTelefonoSeleccion(Entidades[oobj.idEntregaTipo], api);
                }
              }
            }
          });
        } else {
          observable.data['EntregaTipoDetalleSeleccion'] = oData.TiposEntrega.Lista.filter(function (item) {
            return item.idEntregaTipo === node.value;
          })[0];
          observable.notify({// idEntregaEntidad: 0
          });
          observableValidation.notify({
            Envio: {
              estado: true,
              msg: ''
            }
          });
          oEntregaSeleccion.set({
            idEntregaTipo: parseInt(node.value),
            idEntregaEntidad: 0,
            idClienteDomicilio: 0,
            idClienteTelefono: 0,
            cObservaciones: ''
          });
        }
      } else {}
    }
  }

  return new Promise(function (resolve) {
    checkEntrega.forEach(function (button, i) {
      if (checkEntrega.length === 1) {
        checkState(button, true);
      } else {
        button.addEventListener('click', function () {
          var checkList = $('.check');

          if (!button.classList.contains('is-checked')) {
            button.classList.add('is-checked');
            button.querySelector('[type="radio"]').checked = true;
            checkList.parent().find('.entrega-loader').removeClass('visible-loader');
            oEntregaSeleccionar.notify({
              state: 'PREPARE'
            });
            doEnvio = false;
            checkState(button);
          } else {}
        });
      }

      if (i === checkEntrega.length - 1) {
        resolve();
      }
    });
  });
}

function fillDom(domicilio, empty, idEntregaTipo) {
  var botonEliminar = $('#submit-domicilio-eliminar');
  var botonCambiar = $('#submit-domicilio-cambiar');

  if (domicilio.length) {
    $('#boxDomSeleccion .dato').each(function () {
      $(this).removeClass('error');
      $(this).parent().removeClass('error');

      if (empty) {
        botonEliminar.css('display', 'none');
        botonCambiar.css('display', 'none');

        if ($(this).is('select')) {
          $(this).find('option').eq(0).prop('selected', true);
          $(this).prop('disabled', false);
          $(this).selectpicker('refresh');
        } else {
          $(this).val('');
          $(this).prop('checked', true); // $(this).prop('readonly', false)
        }

        oEntregaSeleccion.set({
          requireDomicilio: true,
          idEntregaTipo: idEntregaTipo,
          idClienteDomicilio: 0,
          idEntregaEntidad: 0
        });
        objEnvioPago['envio'] = false;
        observable.remove('idClienteDomicilio');
        var buttonCerrar = document.querySelector('#submit-process');
        buttonCerrar.disabled = true;
        buttonCerrar.style.opacity = .5;
        buttonCerrar.style.cursor = 'not-allowed';
        $('#leyendaRequireSucursal').css('display', 'block');
      } else {
        botonEliminar.css('display', 'block');
        botonCambiar.css('display', 'block');
        botonCambiar.addClass('disabled').css('opacity', .25);
        $('#leyendaRequireSucursal').css('display', 'none');
        var name = $(this)[0].name;

        if (name in domicilio[0]) {
          if ($(this).is('select')) {
            var o = $("#boxDomSeleccion option[value=\"".concat(domicilio[0][name], "\"]"));
            var sel = $(this);
            sel.prop('disabled', false);

            if (o.length > 0) {
              o.prop('selected', true); // sel.prop('disabled', true)

              sel.selectpicker('val', domicilio[0][name]);
            } else {
              $(this).find('option').eq(0).prop('selected', true);
              sel.selectpicker('val', '');
            }

            sel.selectpicker('refresh');
          } else {
            if ($(this).is('input')) {
              if ($(this).is(':checkbox')) {
                $(this).prop('checked', domicilio[0][name]);
              } else {
                $(this).val(domicilio[0][name]);
              }
            }
          }
        }
      }
    });

    if (domicilio[0].idClienteDomicilio > 0) {
      var oobj = {
        idClienteDomicilio: domicilio[0].idClienteDomicilio
      };
      botonEliminar.attr('href', domicilio[0].idClienteDomicilio);
      botonCambiar.attr('href', domicilio[0].idClienteDomicilio);
      botonCambiar.addClass('disabled').css('opacity', .25);
      observable.notify(oobj);
      oEntregaSeleccion.set({
        requireDomicilio: true,
        idEntregaTipo: idEntregaTipo,
        idClienteDomicilio: domicilio[0].idClienteDomicilio,
        idEntregaEntidad: 0
      });
    } else {
      oEntregaSeleccion.set({
        requireDomicilio: true,
        idEntregaTipo: idEntregaTipo,
        idClienteDomicilio: 0,
        idEntregaEntidad: 0
      });
      observable.remove('idClienteDomicilio');
    }
  }
}

function fillDomTelefono(domicilio, empty, idEntregaTipo) {
  var botonEliminar = $('#submit-telefono-eliminar');
  var botonCambiar = $('#submit-tel-cambiar');

  if (domicilio.length) {
    $('#boxDomSeleccionTelefono .dato').each(function () {
      $(this).removeClass('error');
      $(this).parent().removeClass('error');

      if (empty) {
        botonEliminar.css('display', 'none');
        botonCambiar.css('display', 'none');
        botonCambiar.addClass('disabled').css('opacity', .25);

        if ($(this).is('select')) {
          $(this).find('option').eq(0).prop('selected', true);
          $(this).selectpicker('refresh');
        } else {
          $(this).val('');
          $(this).prop('checked', true);
        }

        oEntregaSeleccion.set({
          requireDomicilio: true,
          idEntregaTipo: idEntregaTipo,
          idClienteTelefono: 0,
          idEntregaEntidad: 0
        });
        objEnvioPago['envio'] = false;
      } else {
        botonEliminar.css('display', 'block');
        botonCambiar.css('display', 'block');
        botonCambiar.addClass('disabled').css('opacity', .25);
        var name = $(this)[0].name;

        if (name in domicilio[0]) {
          if ($(this).is('select')) {
            var o = $("#boxDomSeleccionTelefono option[value=\"".concat(domicilio[0][name], "\"]"));
            var sel = $(this);
            sel.prop('disabled', false);

            if (o.length > 0) {
              o.prop('selected', true); // sel.prop('disabled', true)

              sel.selectpicker('val', domicilio[0][name]);
            } else {
              $(this).find('option').eq(0).prop('selected', true);
              sel.selectpicker('val', '');
            }

            sel.selectpicker('refresh');
          } else {
            if ($(this).is('input')) {
              if ($(this).is(':checkbox')) {
                $(this).prop('checked', domicilio[0][name]); // $(this).prop('readonly', true)
              } else {
                $(this).val(domicilio[0][name]); // $(this).prop('readonly', true)
              }
            }
          }
        }
      }
    });

    if (domicilio[0].idClienteTelefono > 0) {
      document.querySelector('#leyendaRequireTelefono').innerHTML = '';
      var oobj = {
        idClienteTelefono: domicilio[0].idClienteTelefono
      };
      botonEliminar.attr('href', domicilio[0].idClienteTelefono);
      botonCambiar.attr('href', domicilio[0].idClienteTelefono);
      observable.notify(oobj);
      oEntregaSeleccion.set({
        requireDomicilio: true,
        idEntregaTipo: idEntregaTipo,
        idClienteTelefono: domicilio[0].idClienteTelefono,
        idEntregaEntidad: 0
      });
    } else {
      var buttonCerrar = document.querySelector('#submit-process');
      buttonCerrar.disabled = true;
      buttonCerrar.style.opacity = .5;
      buttonCerrar.style.cursor = 'not-allowed';
      observable.remove('idClienteTelefono');
      document.querySelector('#leyendaRequireTelefono').innerHTML = '<h3>No hay un celular seleccionado</h3>';
    }
  }
}

function initSelectoresDomicilioSeleccion(lista, api) {
  objEnvioPago['envio'] = false;
  observable.remove('idClienteDomicilio');
  var blockdom = $('#boxDomSeleccion');
  blockdom.css('display', 'none');
  var boton = $('#control-envio-registro');
  var domicilio = lista.Domicilios.filter(function (item) {
    if (item.bSeleccionado === true) {
      return item;
    } else {
      if (item.bModificado === true) {
        return item.bModificado === true;
      }
    }

    return item.bDefault === true;
  });

  if (domicilio.length > 0) {
    fillDom(domicilio, false, lista.idEntregaTipo);
    document.querySelector('#leyendaRequireSucursal').innerHTML = '';
    blockdom.css('display', 'block');
  } else {
    document.querySelector('#leyendaRequireSucursal').innerHTML = '<h3>No hay un domicilio seleccionado</h3>';
  }

  $('[name="cDomicilio"]').on('change', function () {
    var value = parseInt($(this).val());
    blockdom.css('display', 'block');

    if (value > 0) {
      boton.css('display', 'none');

      var _domicilio = lista.Domicilios.map(function (item) {
        item.bSeleccionado = item.idClienteDomicilio === value;
        return item;
      }).filter(function (item) {
        return item.idClienteDomicilio === value;
      });

      fillDom(_domicilio, false, lista.idEntregaTipo);
    } else {
      boton.css('display', 'block');
      document.querySelector('#leyendaRequireSucursal').innerHTML = '<h3>No hay un domicilio seleccionado</h3>';
      fillDom([{
        idClienteDomicilio: 0,
        idDomicilioTipo: 0,
        bDefault: false,
        cCalle: '',
        cNumero: '',
        cPiso: '',
        cUnidad: '',
        cLocalidad: '',
        cProvincia: '',
        cPais: '',
        cCodigoPostal: ''
      }], true, lista.idEntregaTipo);
      oEntregaSeleccion.set({
        requireDomicilio: true,
        idEntregaTipo: lista.idEntregaTipo,
        idClienteDomicilio: 0,
        idEntregaEntidad: 0
      });
      objEnvioPago['envio'] = false;
    }
  });

  if (lista.Domicilios.length === 0) {
    boton.css('display', 'none');
  }

  boton.on('click', function () {
    var params = {
      idPais: 10,
      idProvincia: 0,
      idLocalidad: 0
    };
    var mensaje = document.querySelector('#leyendaErrorDomicilioModificacion');
    mensaje.innerHTML = '';
    $('#boxDomSeleccion .dato').each(function () {
      var value = $(this).val();

      if ($(this).is('select')) {
        var name = $(this)[0].name;
        params[name] = value;
        var p = $(this).parent().find('.dropdown-toggle');

        if (value === '') {
          p.addClass('error');
        } else {
          p.removeClass('error');
        }
      }

      if ($(this).is('input')) {
        if ($(this).is(':checkbox')) {
          var _name = $(this)[0].name;
          params[_name] = $(this).is(':checked');
        } else {
          if ($(this)[0].name === 'cCalle' || $(this)[0].name === 'cNumero' || $(this)[0].name === 'cLocalidad' || $(this)[0].name === 'cPais' || $(this)[0].name === 'cCodigoPostal' || $(this)[0].name === 'cReferente') {
            if (value !== '') {
              var _name2 = $(this)[0].name;
              params[_name2] = value;
              $(this).removeClass('error');
            } else {
              $(this).addClass('error');
            }
          } else {
            var _name3 = $(this)[0].name;
            params[_name3] = value;
          }
        }
      }

      if ($(this).is(":hidden")) {
        var _name4 = $(this)[0].name;
        params[_name4] = value;
      }
    });

    if (Object.keys(params).length === 14) {
      api.setClienteDomicilio(params).then(function (respuesta) {
        try {
          if (respuesta.oData.nResultado === 0 && respuesta.oData.idClienteDomicilio > 0) {
            if (lista.Domicilios.length) {
              lista.Domicilios = lista.Domicilios.map(function (item) {
                item.bSeleccionado = false;
                item.bDefault = false;
                return item;
              });
            }

            if (lista.Domicilios.indexOf(respuesta.oData.idClienteDomicilio) === -1) {
              lista.Domicilios.push(Object.assign({}, params, {
                idClienteDomicilio: respuesta.oData.idClienteDomicilio,
                bSeleccionado: true
              }));
            }

            lista.Domicilios = lista.Domicilios.map(function (item, index) {
              if (item.idDomicilioTipo) {
                item['cTipoNombre'] = tipoDom[item.idDomicilioTipo] + ' ' + (index + 1);
              }

              return item;
            });
            var innerSelection = document.querySelector('#direccion');

            if (!tplManager.exist('domicilio')) {
              tplManager.add('domicilio', '/templates/entrega/domicilio.html').then(function (compiler) {
                innerSelection.innerHTML = compiler(lista);
                initSelectoresDomicilioSeleccion(lista, api);
                var select = $('[name="cDomicilio"]');
                select.find('option[value="' + respuesta.oData.idClienteDomicilio + '"]').prop('selected', true); // select.trigger('change')

                select.selectpicker('refresh');
              }).catch(function (e) {
                console.log(e);
              });
            } else {
              var compiler = tplManager.templates['domicilio'];
              innerSelection.innerHTML = compiler(lista);
              initSelectoresDomicilioSeleccion(lista, api);
              var select = $('[name="cDomicilio"]');
              select.find('option[value="' + respuesta.oData.idClienteDomicilio + '"]').prop('selected', true); // select.trigger('change')

              select.selectpicker('refresh');
            }
          } else {
            mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
            mensaje.style.color = "#E14";
          }
        } catch (e) {
          mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
          mensaje.style.color = "#E14";
        }
      });
    }
  });
  $('#submit-domicilio-eliminar').on({
    click: function click(e) {
      e.preventDefault();
      var id = $(this).attr('href');
      var mensaje = document.querySelector('#leyendaErrorDomicilioModificacion');
      mensaje.innerHTML = '';

      if (id) {
        api.setClienteDomicilioEliminar(id).then(function (respuesta) {
          try {
            if (respuesta.oData.nResultado === 0 && respuesta.oData.idClienteDomicilio > 0) {
              var _id = respuesta.oData.idClienteDomicilio;
              lista.Domicilios = lista.Domicilios.filter(function (item) {
                return item.idClienteDomicilio !== _id;
              });
              var innerSelection = document.querySelector('#direccion');

              if (!tplManager.exist('domicilio')) {
                tplManager.add('domicilio', '/templates/entrega/domicilio.html').then(function (compiler) {
                  innerSelection.innerHTML = compiler(lista);
                  initSelectoresDomicilioSeleccion(lista, api);
                }).catch(function (e) {
                  console.log(e);
                });
              } else {
                var compiler = tplManager.templates['domicilio'];
                innerSelection.innerHTML = compiler(lista);
                initSelectoresDomicilioSeleccion(lista, api);
              }
            } else {
              mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
              mensaje.style.color = "#E14";
            }
          } catch (e) {
            mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
            mensaje.style.color = "#E14";
          }
        });
      }
    }
  });
  $('#submit-domicilio-cambiar').addClass('disabled').css('opacity', .25).css('cursor', 'default').on({
    click: function click(e) {
      e.preventDefault();
      var target = $(this);

      if (target.hasClass('disabled')) {
        return;
      }

      var mensaje = document.querySelector('#leyendaErrorDomicilioModificacion');
      mensaje.innerHTML = '';

      if (target.hasClass('disabled')) {
        return false;
      }

      var id = target.attr('href');

      if (id) {
        var params = {
          idPais: 10,
          idProvincia: 0,
          idLocalidad: 0
        };
        $('#boxDomSeleccion .dato').each(function () {
          var value = $(this).val();

          if ($(this).is('select')) {
            var name = $(this)[0].name;
            params[name] = value;
          }

          if ($(this).is('input')) {
            if ($(this).is(':checkbox')) {
              var _name5 = $(this)[0].name;
              params[_name5] = $(this).is(':checked');
            } else {
              var _name6 = $(this)[0].name;
              params[_name6] = value;
            }
          }

          if ($(this).is(":hidden")) {
            var _name7 = $(this)[0].name;
            params[_name7] = value;
          }
        });
        var errors = [];
        Object.keys(params).forEach(function (key) {
          var elem = document.querySelector("[name=\"".concat(key, "\"]"));

          if (elem) {
            if (elem.nodeName.toLowerCase() === 'select') {
              elem = document.querySelector("[data-id=\"".concat(key, "\"]"));

              if (elem) {
                if (!params[key]) {
                  elem.classList.add('error');
                  errors.push(key);
                }
              }
            } else {
              if (elem.type === 'text') {
                if (key !== 'cPiso' && key !== 'cUnidad') {
                  if (params[key].length) {
                    elem.classList.remove('error');
                  } else {
                    elem.classList.add('error');
                    errors.push(key);
                  }
                }
              }
            }
          }
        });

        if (errors.length) {
          return false;
        }

        api.setClienteDomicilioCambio(id, params).then(function (respuesta) {
          try {
            if (respuesta.oData.nResultado === 0 && respuesta.oData.idClienteDomicilio > 0) {
              target.addClass('disabled').css('opacity', .25).css('cursor', 'default');

              if (params.bDefault) {
                lista.Domicilios = lista.Domicilios.map(function (item) {
                  item.bDefault = false;
                  return item;
                });
              }

              lista.Domicilios = lista.Domicilios.map(function (item) {
                if (item.idClienteDomicilio === respuesta.oData.idClienteDomicilio) {
                  item = Object.assign({}, item, params);
                  item.bModificado = true;
                } else {
                  item.bModificado = false;
                }

                return item;
              });
              var innerSelection = document.querySelector('#direccion');

              if (!tplManager.exist('domicilio')) {
                tplManager.add('domicilio', '/templates/entrega/domicilio.html').then(function (compiler) {
                  innerSelection.innerHTML = compiler(lista);
                  initSelectoresDomicilioSeleccion(lista, api);
                }).catch(function (e) {
                  console.log(e);
                });
              } else {
                var compiler = tplManager.templates['domicilio'];
                innerSelection.innerHTML = compiler(lista);
                initSelectoresDomicilioSeleccion(lista, api);
              }
            } else {
              mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
              mensaje.style.color = "#E14";
            }
          } catch (e) {
            mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
            mensaje.style.color = "#E14";
          }
        });
      }
    }
  }); // HANDLER EVENTS

  var listInputsDomicilio = $('#boxDomSeleccion .dato');
  listInputsDomicilio.each(function () {
    if ($(this).is('select')) {
      $(this).on({
        change: function change() {
          if ($(this).val() !== '') {
            var edit = $('#submit-domicilio-cambiar');

            if (edit.hasClass('disabled')) {
              edit.removeClass('disabled').css('opacity', 1).css('cursor', 'pointer');
            }
          }
        }
      });
    } else {
      $(this).on({
        input: function input() {
          if ($(this).val() !== '') {
            var edit = $('#submit-domicilio-cambiar');

            if (edit.hasClass('disabled')) {
              edit.removeClass('disabled').css('opacity', 1).css('cursor', 'pointer');
            }
          }
        },
        change: function change() {
          var edit = $('#submit-domicilio-cambiar');

          if (edit.hasClass('disabled')) {
            edit.removeClass('disabled').css('opacity', 1).css('cursor', 'pointer');
          }
        }
      });
    }
  });
  $('#submit-dom-nuevo').on({
    click: function click(e) {
      e.preventDefault();
      var select = $('[name="cDomicilio"]');
      select.find('option[value="0"]').prop('selected', true);
      select.trigger('change');
      select.selectpicker('refresh');
    }
  });
  $('.selectpicker').selectpicker();
}

function initSelectoresTelefonoSeleccion(lista, api) {
  objEnvioPago['envio'] = false;
  observable.remove('idClienteDomicilio');
  var telefono = lista.Telefonos.filter(function (item) {
    if (item.bSeleccionado === true) {
      return item;
    } else {
      if (item.bModificado === true) {
        return item.bModificado === true;
      }
    }

    return item.bDefault === true;
  });
  var blocktel = $('#boxDomSeleccionTelefono');
  var botonTele = $('#control-tel-registro');
  blocktel.css('display', 'none');

  if (telefono.length > 0) {
    fillDomTelefono(telefono, false, lista.idEntregaTipo);
    blocktel.css('display', 'block');
    document.querySelector('#leyendaRequireTelefono').innerHTML = '';
  } else {
    document.querySelector('#leyendaRequireTelefono').innerHTML = '<h3>No hay un telefono seleccionado</h3>';
  }

  $('[name="cTelofonos"]').on('change', function () {
    var value = parseInt($(this).val());
    var block = $('#boxDomSeleccionTelefono');
    var elim = $('#submit-telefono-eliminar');
    var cam = $('#submit-tel-cambiar');

    if ($(this).find('option:selected').attr('rel') === '0') {
      block.css('display', 'none');
      elim.css('display', 'none');
      cam.css('display', 'none');
      observable.notify({
        idClienteTelefono: 0
      });
    } else {
      block.css('display', 'block');
      elim.css('display', 'block');
      cam.css('display', 'block');

      if (value > 0) {
        botonTele.css('display', 'none');
        var tele = lista.Telefonos.filter(function (item) {
          return item.idClienteTelefono === value;
        });
        fillDomTelefono(tele, false, lista.idEntregaTipo);
      } else {
        botonTele.css('display', 'block');
        fillDomTelefono([{
          idClienteTelefono: 0,
          idTelefonoTipo: 0,
          cTelefonoNumero: 0,
          bDefault: false
        }], true, lista.idEntregaTipo);
        document.querySelector('#leyendaRequireTelefono').innerHTML = '<h3>No hay un telefono seleccionado</h3>';
        objEnvioPago['envio'] = false;
      }
    }
  });

  if (lista.Telefonos.length === 0) {
    botonTele.css('display', 'none');
  }

  botonTele.on('click', function () {
    var params = {};
    $('#boxDomSeleccionTelefono .dato').each(function () {
      var value = $(this).val();
      var mensaje = document.querySelector('#leyendaErrorTelefonoModificacion');
      mensaje.innerHTML = '';

      if ($(this).is('select')) {
        var name = $(this)[0].name;
        params[name] = value;
      }

      if ($(this).is('select')) {
        var _name8 = $(this)[0].name;
        params[_name8] = value;
        var p = $(this).parent().find('.dropdown-toggle');

        if (value === '') {
          p.addClass('error');
        } else {
          p.removeClass('error');
        }
      }

      if ($(this).is('input')) {
        if ($(this).is(':checkbox')) {
          var _name9 = $(this)[0].name;
          params[_name9] = $(this).is(':checked');
        } else {
          if (value !== '') {
            var rg = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;

            if (rg.test(value)) {
              var _name10 = $(this)[0].name;
              params[_name10] = value;
              $(this).removeClass('error');
            } else {
              $(this).addClass('error');
            }
          } else {
            $(this).addClass('error');
          }
        }
      }
    });

    if (Object.keys(params).length === 3) {
      api.setClienteTelefono(params).then(function (respuesta) {
        try {
          if (respuesta.oData.nResultado === 0 && respuesta.oData.idClienteTelefono > 0) {
            var id = respuesta.oData.idClienteTelefono || respuesta.oData.idClienteDomicilio;

            if (lista.Telefonos.length) {
              lista.Telefonos = lista.Telefonos.map(function (item) {
                item.bSeleccionado = false;
                item.bDefault = false;
                return item;
              });
            }

            if (lista.Telefonos.indexOf(id) === -1) {
              lista.Telefonos.push(Object.assign({}, params, {
                idClienteTelefono: id,
                bSeleccionado: true
              }));
            }

            lista.Telefonos = lista.Telefonos.map(function (tel, index) {
              tel['cTipoNombre'] = tipoTelefono[tel.idTelefonoTipo] + ' ' + (index + 1);
              return tel;
            });
            var innerSelection = document.querySelector('#telefono');

            if (!tplManager.exist('telefono')) {
              tplManager.add('telefono', '/templates/entrega/telefono.html').then(function (compiler) {
                innerSelection.innerHTML = compiler(lista);
                initSelectoresTelefonoSeleccion(lista, api);
                var select = $('[name="cTelofonos"]');
                select.find('option[value="' + id + '"]').prop('selected', true);
                select.selectpicker('refresh');
              }).catch(function (e) {
                console.log(e);
              });
            } else {
              var compiler = tplManager.templates['telefono'];
              innerSelection.innerHTML = compiler(lista);
              initSelectoresTelefonoSeleccion(lista, api);
              var select = $('[name="cTelofonos"]');
              select.find('option[value="' + id + '"]').prop('selected', true);
              select.selectpicker('refresh');
            }
          } else {
            mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
            mensaje.style.color = "#E14";
          }
        } catch (e) {
          mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
          mensaje.style.color = "#E14";
        }
      });
    }
  });
  $('#submit-telefono-eliminar').on({
    click: function click(e) {
      e.preventDefault();
      var id = $(this).attr('href');
      var mensaje = document.querySelector('#leyendaErrorTelefonoModificacion');
      mensaje.innerHTML = '';

      if (id) {
        var params = {};
        $('#boxDomSeleccionTelefono .dato').each(function () {
          var value = $(this).val();

          if ($(this).is('select')) {
            var name = $(this)[0].name;
            params[name] = value;
          }

          if ($(this).is('input')) {
            if ($(this).is(':checkbox')) {
              var _name11 = $(this)[0].name;
              params[_name11] = $(this).is(':checked');
            } else {
              var _name12 = $(this)[0].name;
              params[_name12] = value;
            }
          }
        });
        api.setClienteTelefonoEliminar(id, params).then(function (respuesta) {
          try {
            if (respuesta.oData.nResultado === 0 && respuesta.oData.idClienteTelefono > 0) {
              var _id2 = respuesta.oData.idClienteTelefono;
              lista.Telefonos = lista.Telefonos.filter(function (item) {
                return item.idClienteTelefono !== _id2;
              });
              var innerSelection = document.querySelector('#telefono');

              if (!tplManager.exist('telefono')) {
                tplManager.add('telefono', '/templates/entrega/telefono.html').then(function (compiler) {
                  innerSelection.innerHTML = compiler(lista);
                  initSelectoresTelefonoSeleccion(lista, api);
                }).catch(function (e) {
                  console.log(e);
                });
              } else {
                var compiler = tplManager.templates['telefono'];
                innerSelection.innerHTML = compiler(lista);
                initSelectoresTelefonoSeleccion(lista, api);
              }
            } else {
              mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
              mensaje.style.color = "#E14";
            }
          } catch (e) {
            mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
            mensaje.style.color = "#E14";
          }
        });
      }
    }
  });
  $('#submit-tel-cambiar').addClass('disabled').css('opacity', .25).css('cursor', 'default').on({
    click: function click(e) {
      e.preventDefault();
      var target = $(this);

      if (target.hasClass('disabled')) {
        return;
      }

      var mensaje = document.querySelector('#leyendaErrorTelefonoModificacion');
      mensaje.innerHTML = '';
      var id = $(this).attr('href');

      if (id) {
        var params = {};
        $('#boxDomSeleccionTelefono .dato').each(function () {
          var value = $(this).val();

          if ($(this).is('select')) {
            var name = $(this)[0].name;
            params[name] = value;
          }

          if ($(this).is('input')) {
            if ($(this).is(':checkbox')) {
              var _name13 = $(this)[0].name;
              params[_name13] = $(this).is(':checked');
            } else {
              var _name14 = $(this)[0].name;
              params[_name14] = value;
            }
          }

          if ($(this).is(":hidden")) {
            var _name15 = $(this)[0].name;
            params[_name15] = value;
          }
        });
        var errors = [];
        Object.keys(params).forEach(function (key) {
          var elem = document.querySelector("[name=\"".concat(key, "\"]"));

          if (elem) {
            if (elem.nodeName.toLowerCase() === 'select') {
              elem = document.querySelector("[data-id=\"".concat(key, "\"]"));

              if (elem) {
                if (!params[key]) {
                  elem.classList.add('error');
                  errors.push(key);
                }
              }
            } else {
              if (elem.type === 'text') {
                if (params[key].length) {
                  elem.classList.remove('error');
                } else {
                  elem.classList.add('error');
                  errors.push(key);
                }
              }
            }
          }
        });

        if (errors.length) {
          return false;
        }

        api.setClienteTelefonoCambio(id, params).then(function (respuesta) {
          try {
            if (respuesta.oData.nResultado === 0 && respuesta.oData.idClienteTelefono > 0) {
              target.addClass('disabled').css('opacity', .25).css('cursor', 'default');

              var _id3 = respuesta.oData.idClienteTelefono || respuesta.oData.idClienteDomicilio;

              if (params.bDefault) {
                lista.Telefonos = lista.Telefonos.map(function (item) {
                  item.bDefault = false;
                  return item;
                });
              }

              lista.Telefonos = lista.Telefonos.map(function (item) {
                if (item.idClienteTelefono === _id3) {
                  item = Object.assign({}, item, params);
                  item.bModificado = true;
                } else {
                  item.bModificado = false;
                }

                return item;
              });
              var innerSelection = document.querySelector('#telefono');

              if (!tplManager.exist('telefono')) {
                tplManager.add('telefono', '/templates/entrega/telefono.html').then(function (compiler) {
                  innerSelection.innerHTML = compiler(lista);
                  initSelectoresTelefonoSeleccion(lista, api);
                  var select = $('[name="cTelofonos"]');
                  select.find('option[value="' + _id3 + '"]').prop('selected', true); // select.trigger('change')

                  select.selectpicker('refresh');
                }).catch(function (e) {
                  console.log(e);
                });
              } else {
                var compiler = tplManager.templates['telefono'];
                innerSelection.innerHTML = compiler(lista);
                initSelectoresTelefonoSeleccion(lista, api);
                var select = $('[name="cTelofonos"]');
                select.find('option[value="' + _id3 + '"]').prop('selected', true); // select.trigger('change')

                select.selectpicker('refresh');
              }
            } else {
              mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
              mensaje.style.color = "#E14";
            }
          } catch (e) {
            mensaje.innerHTML = "<h3>".concat(respuesta.oData.cMensaje, "</h3>");
            mensaje.style.color = "#E14";
          }
        });
      }
    }
  }); // HANDLER EVENTS

  var listInputsTelefono = $('#boxDomSeleccionTelefono .dato');
  listInputsTelefono.each(function () {
    if ($(this).is('select')) {
      $(this).on({
        change: function change() {
          if ($(this).val() !== '') {
            var edit = $('#submit-tel-cambiar');

            if (edit.hasClass('disabled')) {
              edit.removeClass('disabled').css('opacity', 1).css('cursor', 'pointer');
            }
          }
        }
      });
    } else {
      $(this).on({
        input: function input() {
          if ($(this).val() !== '') {
            var edit = $('#submit-tel-cambiar');

            if (edit.hasClass('disabled')) {
              edit.removeClass('disabled').css('opacity', 1).css('cursor', 'pointer');
            }
          }
        },
        change: function change() {
          var edit = $('#submit-tel-cambiar');

          if (edit.hasClass('disabled')) {
            edit.removeClass('disabled').css('opacity', 1).css('cursor', 'pointer');
          }
        }
      });

      if ($(this).attr('name') === 'cTelefonoNumero') {
        $(this).on({
          input: function input() {
            var val = $(this).val().replace(/\D/g, '');

            if ($(this).hasClass('error')) {
              $(this).removeClass('error');
            }

            $(this).val(val);
          }
        });
      }
    }
  });
  $('#submit-tel-nuevo').on({
    click: function click(e) {
      e.preventDefault();
      var select = $('[name="cTelofonos"]');
      select.find('option[value="0"]').prop('selected', true);
      select.trigger('change');
      select.selectpicker('refresh');
    }
  });
  $('.selectpicker').selectpicker();
}

function initSelectoresEntidad(oData, one) {
  // console.info(one)
  var provinciaEntidad = $('#provinciaEntidad');
  var idEntregaEntidad = $('#idEntregaEntidad');
  idEntregaEntidad[0].disabled = true;
  idEntregaEntidad.selectpicker('refresh');
  provinciaEntidad.on('change', function () {
    var value = $(this).val();
    $('.descripcion-entidad').css('display', 'none');
    var oobj = {};
    oobj['idEntregaEntidad'] = 0;
    observable.notify(oobj);
    observableValidation.notify({
      Envio: {
        estado: false,
        msg: 'Debe seleccionar una sucursal'
      }
    });

    if (value !== '') {
      idEntregaEntidad.find('option').each(function (i) {
        if (i > 0) {
          var attr = $(this).attr('data-provicia-seleccion');

          if (attr === value) {
            $(this).css('display', 'block').addClass('visibled');
          } else {
            $(this).css('display', 'none').removeClass('visibled');
          }
        } else {
          $(this)[0].selected = true;
        }
      });
      var length = oData.listaEntidades.length;
      idEntregaEntidad[0].disabled = !length;
      idEntregaEntidad.selectpicker('refresh');
      observableValidation.notify({
        Envio: {
          estado: false,
          msg: 'Debe seleccionar una sucursal'
        }
      });
    }
  });
  idEntregaEntidad.on('change', function () {
    var oobj = {};
    var value = $(this).val() || '';

    if (value !== '') {
      var precio = $(this).find('option:selected').attr('data-precio-seleccion');
      precio = parseInt(precio);
      $('.descripcion-entidad').css('display', 'none');
      $('[data-descricion-seleccion="' + value + '"]').css('display', 'block');
      var o = oData.listaEntidades.filter(function (item) {
        return item.idEntregaEntidad === parseInt(value);
      });

      if (value !== '') {
        oobj['idEntregaEntidad'] = parseInt(value);
        oobj['EntregaTipoDetalleSeleccion'] = o[0];
        observable.notify(oobj);
        oEntregaSeleccion.set({
          idEntregaTipo: oData['idEntregaTipo'],
          idEntregaEntidad: parseInt(value),
          idClienteDomicilio: 0,
          idClienteTelefono: 0,
          cObservaciones: ''
        });
        document.querySelector('#leyendaRequireSucursal').innerHTML = '';
      } else {
        if (observable.data['idEntregaEntidad']) {
          observable.remove('idEntregaEntidad');
          document.querySelector('#leyendaRequireSucursal').innerHTML = '<h3>Debe seleccionar una sucursal</h3>';
        }
      }

      observableValidation.notify({
        Envio: {
          estado: true,
          msg: ''
        }
      });
      oPrecioObservable.notify({
        fprecio: precio
      });
    } else {
      observableValidation.notify({
        Envio: {
          estado: false,
          msg: 'Debe seleccionar una sucursal'
        }
      });
    }
  });
  $('.entrega .selectpicker').selectpicker({
    showSubtext: true,
    mobile: !!isMobile.any(),
    container: 'body'
  });

  if (one) {
    idEntregaEntidad.trigger('change');
    idEntregaEntidad[0].disabled = false;
    idEntregaEntidad.selectpicker('refresh');
  } else {
    if (oData.listaEntidadProvincia.length === 1) {
      idEntregaEntidad[0].disabled = false;
      idEntregaEntidad.selectpicker('refresh');
    }
  }

  if (oData.listaEntidades.length === 1) {
    idEntregaEntidad.trigger('change');
    idEntregaEntidad[0].disabled = true;
    idEntregaEntidad.selectpicker('refresh');
  } else {// idEntregaEntidad[0].disabled = false
    // idEntregaEntidad.selectpicker('refresh')
  }
}

function initCheckMediosPago(oData, api) {
  var innerSelection = document.querySelector('#tarjeta');
  var checkTarjetas = [].slice.call(document.querySelectorAll('#medio-pago .checktipopago'));

  function checkMediosPagoState(button, one) {
    innerSelection.innerHTML = '';
    var node = button.querySelector('input[type="radio"]');

    if (node.nodeName === 'INPUT') {
      var isChecked = node.checked;
      removeClassIsChecked("#medio-pago .checktipopago", node);

      if (isChecked) {
        var val = parseInt(node.value);
        var odata = null;
        oPagoSeleccion.set({
          idFormaDePago: val
        });

        switch (val) {
          case 4:
            api.paymentChannel = 4;
            odata = oData.FormasPago.Lista.filter(function (item) {
              return item.idFormaDePago === val;
            });

            if (!tplManager.exist('tarjeta')) {
              tplManager.add('tarjeta', '/templates/pago/tarjeta.html').then(function (compiler) {
                var obj = Object.assign({}, odata[0], api.cliente);
                innerSelection.innerHTML = compiler(obj);
                initFormularioTarjeta(odata, api);
                observable.notify({
                  idFormaDePago: 4
                });
              }).catch(function (e) {
                console.log(e);
              });
            } else {
              var compiler = tplManager.templates['tarjeta'];
              var obj = Object.assign({}, odata, api.cliente);
              innerSelection.innerHTML = compiler(obj);
              initFormularioTarjeta(odata, api);
              observable.notify({
                idFormaDePago: 4
              });
            }

            break;

          case 7:
            api.paymentChannel = 7;
            odata = oData.FormasPago.Lista.filter(function (item) {
              return item.idFormaDePago === val;
            });

            if (!tplManager.exist('mp')) {
              tplManager.add('mp', '/templates/pago/mp.html').then(function (compiler) {
                innerSelection.innerHTML = compiler(api.cliente);
                initFormularioTarjetaMP(odata, api);
                observable.notify({
                  idFormaDePago: 7
                });
              });
            } else {
              var _compiler3 = tplManager.templates['mp'];
              innerSelection.innerHTML = _compiler3(api.cliente);
              initFormularioTarjetaMP(odata, api);
            }

            break;

          default:
            api.paymentChannel = null;
        }
      }
    }
  }

  return new Promise(function (resolve) {
    checkTarjetas.forEach(function (button, i) {
      if (checkTarjetas.length === 1) {
        checkMediosPagoState(button, true);
      } else {
        button.addEventListener('click', function () {
          var checkList = $('.check');

          if (!button.classList.contains('is-checked')) {
            button.classList.add('is-checked');
            button.querySelector('[type="radio"]').checked = true;
            checkList.parent().find('.entrega-loader').removeClass('visible-loader');
            oPagoSeleccionar.notify({
              state: 'PREPARE'
            });
            doEnvio = false;
            checkMediosPagoState(button);
          } else {}
        });
      }

      if (i === checkTarjetas.length - 1) {
        resolve();
      }
    });
  });
}

function initFormularioTarjeta(oData, api) {
  var ccc = document.querySelector('#leyendaTarjetaCuotas');
  var paymentObj = {};
  var buttonConfirmar = document.querySelector('#submit-process');
  var inputmp = [].slice.call(document.querySelectorAll('.input-dcr'));
  var docSelect = document.querySelector('#docType');
  var docTypes = [{
    "id": "DNI",
    "name": "DNI"
  }, {
    "id": "CUIL",
    "name": "CUIL"
  }, {
    "id": "OTRO",
    "name": "OTRO"
  }];
  docSelect.innerHTML = '';
  docTypes.forEach(function (item) {
    var option = document.createElement('option');
    option.value = item.id;
    option.innerHTML = item.name;

    if (item.id === 'DNI') {
      option.selected = true;
    }

    docSelect.appendChild(option);
  });
  new Card({
    form: document.querySelector('#payform'),
    container: '.card-wrapper',
    formSelectors: {
      numberInput: 'input[id="cTarjetaNumero"]',
      expiryInput: 'input[id="cExpirationCard"]',
      cvcInput: 'input[id="cCodigoSeguridad"]',
      nameInput: 'input[id="cNombreEnTarjeta"]'
    },
    messages: {
      validDate: 'expire\ndate',
      monthYear: 'mm/yy'
    }
  });
  var installments = document.querySelector('#cuotasCantidad');
  var sel = $(installments);
  sel.on('change', function () {
    var value = $(this).val();
    var rel = $(this).find('option:selected').attr('rel');
    oPagoSeleccion.set({
      idCuota: parseInt(value) > 0 ? parseInt(value) : 0,
      idFormaDePagoConfiguracionTarjeta: parseInt(rel) ? parseInt(rel) : 0
    });

    if (value) {
      if (isNotValid(inputmp)) {
        return;
      }

      paymentObj['idCuota'] = parseInt(value);
      observable.notify(paymentObj);
      observableValidation.notify({
        Pago: {
          estado: true,
          msg: ""
        }
      });
    } else {
      observableValidation.notify({
        Pago: {
          estado: false,
          msg: "Verifique los datos para el cobro"
        }
      });
    }
  });
  var cacheBin = '';
  var binLeyenda = document.querySelector('#leyendaTarjeta');
  var leyendaTarjetaCuotas = document.querySelector('#leyendaTarjetaCuotas');
  binLeyenda.innerHTML = '';
  binLeyenda.style.display = 'none';
  leyendaTarjetaCuotas.innerHTML = '';
  leyendaTarjetaCuotas.style.display = 'none';
  on(document.querySelector('#cExpirationCard'), ['input'], function () {
    if (this.value.length === 7) {
      var arr = this.value.split(' / ');
      document.querySelector('[data-checkout="cardExpirationMonth"]').value = arr[0];
      document.querySelector('[data-checkout="cardExpirationYear"]').value = arr[1];

      if (this.classList.contains('error')) {
        this.classList.remove('error');
      }
    }
  });
  on(document.querySelector('#cNombreEnTarjeta'), ['input'], function () {
    var value = this.value;
    this.value = value.replace(/[^a-zA-Z ]/g, '');

    if (this.classList.contains('error')) {
      this.classList.remove('error');
    }
  });
  on(document.querySelector('#docNumber'), ['input'], function () {
    if (this.classList.contains('jp-card-invalid')) {
      this.classList.remove('jp-card-invalid');
    }
  });
  on(document.querySelector('#docType'), ['change'], function () {
    var d = document.querySelector("[data-id=\"".concat(this.id, "\"]"));

    if (d.classList.contains('error')) {
      d.classList.remove('error');
    }
  });
  on(document.querySelector('#cTarjetaNumero'), ['input', 'change'], function (e) {
    var bin = getBin(this);

    if (cacheBin === bin) {
      return false;
    }

    if (this.value.length === 7) {
      if (bin.length === 6) {
        cacheBin = bin;
        api.getProcessTarjetasFormasDePago({
          type: oData[0].idFormaDePago,
          bin: bin
        }).then(function (response) {
          installments.innerHTML = '';

          if (response.oData['lFormaDePagoConfiguracionTarjetaSeleccionada'] !== undefined) {
            if (response.oData.lFormaDePagoConfiguracionTarjetaSeleccionada.length > 0) {
              var TS = response.oData.lFormaDePagoConfiguracionTarjetaSeleccionada[0];
              paymentObj['idFormaDePago'] = TS.idFormaDePago;
              paymentObj['idFormaDePagoConfiguracionTarjeta'] = TS.idFormaDePagoConfiguracionTarjeta;
              observable.remove('idCuota');
              TS['lCuotas'].forEach(function (cuota) {
                var op = document.createElement('option');
                op.value = cuota.idCuota;
                op.innerText = cuota.cLeyenda;
                op.setAttribute('rel', TS.idFormaDePagoConfiguracionTarjeta);
                installments.appendChild(op);
              });
              var credenciales = {
                cClavePublicaGateway: TS.cClavePublicaGateway
              };
              observableTokens.notify(credenciales);
              leyendaTarjetaCuotas.innerHTML = '';
              leyendaTarjetaCuotas.style.display = 'none';
            } else {
              leyendaTarjetaCuotas.innerHTML = "<h3>Tarjeta no disponible</h3>";
              leyendaTarjetaCuotas.style.display = 'block';
            }
          } else {
            if (!Array.isArray(response.oData)) {
              var odata = response.oData.lCuotas[0];
              observable.remove('idCuota');
              var _credenciales = {
                cClavePublicaGatewaySch: response.oData.cClavePublicaGatewaySch,
                cClavePublicaGatewayTicket: response.oData.cClavePublicaGatewayTicket
              };
              observableTokens.notify(_credenciales);
              odata.payer_costs.forEach(function (payer) {
                var op = document.createElement('option');
                op.value = payer.installments;
                op.innerText = payer.recommended_message;
                op.setAttribute('rel', 7);
                installments.appendChild(op);
              });
            } else {
              leyendaTarjetaCuotas.innerHTML = "<h3>Tarjeta no disponible</h3>";
              leyendaTarjetaCuotas.style.display = 'block';
            }
          }
        }).then(function () {
          sel.selectpicker('refresh');
        }).catch(function (err) {
          console.log(err);
        });
      }
    } else {
      checkFill();
    }
  });
  on(document.querySelector('#cTarjetaNumero'), ['paste'], function (e) {
    var _this = this;

    setTimeout(function () {
      var bin = getBin(_this);

      if (cacheBin === bin) {
        return false;
      }

      if (blackList.indexOf(bin) > -1) {
        binLeyenda.innerHTML = "<h3>Tarjeta de debito no disponible</h3>";
        binLeyenda.style.display = 'block';
        installments.innerHTML = '';
        sel.selectpicker('refresh');
        return false;
      } else {}

      if (bin.length === 6) {
        cacheBin = bin;
        var obj = {
          type: oData[0].idFormaDePago,
          bin: bin
        };
        api.getProcessTarjetasFormasDePago(obj).then(function (response) {
          installments.innerHTML = '';

          if (response.oData['lFormaDePagoConfiguracionTarjetaSeleccionada'] !== undefined) {
            if (response.oData.lFormaDePagoConfiguracionTarjetaSeleccionada.length > 0) {
              var TS = response.oData.lFormaDePagoConfiguracionTarjetaSeleccionada[0];
              paymentObj['idFormaDePago'] = TS.idFormaDePago;
              paymentObj['idFormaDePagoConfiguracionTarjeta'] = TS.idFormaDePagoConfiguracionTarjeta;
              observable.remove('idCuota');
              TS['lCuotas'].forEach(function (cuota) {
                var op = document.createElement('option');
                op.value = cuota.idCuota;
                op.innerText = cuota.cLeyenda;
                op.setAttribute('rel', TS.idFormaDePagoConfiguracionTarjeta);
                installments.appendChild(op);
              });
              var credenciales = {
                cClavePublicaGateway: TS.cClavePublicaGateway
              };
              observableTokens.notify(credenciales);
              leyendaTarjetaCuotas.innerHTML = '';
              leyendaTarjetaCuotas.style.display = 'none';
            } else {
              leyendaTarjetaCuotas.innerHTML = "<h3>Tarjeta no disponible</h3>";
              leyendaTarjetaCuotas.style.display = 'block';
            }
          } else {
            if (!Array.isArray(response.oData)) {
              var odata = response.oData.lCuotas[0];
              observable.remove('idCuota');
              var _credenciales2 = {
                cClavePublicaGatewaySch: response.oData.cClavePublicaGatewaySch,
                cClavePublicaGatewayTicket: response.oData.cClavePublicaGatewayTicket
              };
              observableTokens.notify(_credenciales2);
              odata.payer_costs.forEach(function (payer) {
                var op = document.createElement('option');
                op.value = payer.installments;
                op.innerText = payer.recommended_message;
                installments.appendChild(op);
              });
            } else {
              leyendaTarjetaCuotas.innerHTML = "<h3>Tarjeta no disponible</h3>";
              leyendaTarjetaCuotas.style.display = 'block';
            }
          }
        }).then(function () {
          sel.selectpicker('refresh');
        }).catch(function (err) {
          console.log(err);
        });
      }
    }, 100);
  });
  on(buttonConfirmar, ['click'], function () {
    if (this.disabled === true) {
      return false;
    }

    if (isNotValid(inputmp)) {
      return;
    }

    ccc.innerHTML = '';
    ccc.style.display = 'none';
    var tycLeyenda = document.querySelector('#tycLeyenda');

    if (observableValidation.data['TYC']) {
      if (observableValidation.data['TYC'].estado === false) {
        tycLeyenda.innerHTML = '<h3>Debe aceptar los terminos y condiciones</h3>';
        return false;
      } else {
        tycLeyenda.innerHTML = '';
      }
    } else {
      tycLeyenda.innerHTML = '<h3>Debe aceptar los terminos y condiciones</h3>';
      return false;
    }

    var tc = document.querySelector('#cTarjetaNumero');
    var ec = document.querySelector('#cExpirationCard');
    var cs = document.querySelector('#cCodigoSeguridad');
    var decidirInputMes = document.querySelector('[data-decidir="card_expiration_month"]');
    var decidirInputAnio = document.querySelector('[data-decidir="card_expiration_year"]');
    var month = null;
    var year = null;

    if (decidirInputAnio && decidirInputMes) {
      month = decidirInputMes.value;
      year = decidirInputAnio.value;
    } else {
      month = document.querySelector('[data-checkout="cardExpirationMonth"]').value;
      year = document.querySelector('[data-checkout="cardExpirationYear"]').value;
    }

    if (month.length > 2 || year.length > 2) {
      ec.classList.add('error');
      return false;
    } else {
      if (ec.classList.contains('error')) {
        ec.classList.remove('error');
      }
    }

    if (tc.value === '' || ec.value === '' || cs.value === '') {
      return false;
    }

    if (tc.classList.contains('jp-card-invalid')) {
      ccc.innerHTML = '<h3>La tarjeta no es válida</h3>';
      ccc.style.display = 'block';
      return false;
    }

    if (ec.classList.contains('jp-card-invalid')) {
      ccc.innerHTML = '<h3>La fecha de vencimiento no es válida</h3>';
      ccc.style.display = 'block';
      return false;
    }

    if (cs.classList.contains('jp-card-invalid')) {
      ccc.innerHTML = '<h3>El código de seguridad no es válido</h3>';
      ccc.style.display = 'block';
      return false;
    }

    buttonConfirmar.disabled = true;
    buttonConfirmar.classList.add('load'); // api.setProcessCerrar(observable.data)

    api.getProcess(api.$curProcessId).then(function (response) {
      if (response.nCode === 200) {
        modalAprobado(observableTokens.data, {
          oProceso: response
        }, api);
      } else {
        if (response.oFormaDePago.nCode === 500) {
          $('#autorizado').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
          modalRechazoIntentoCobro(api, response.oFormaDePago, true);
        }
      }

      buttonConfirmar.disabled = false;
      buttonConfirmar.classList.remove('load');
    }).catch(function (err) {
      if (err) {
        console.log(err);
      }
    });
  });
  $('.tarjeta .selectpicker').selectpicker({
    showSubtext: true,
    mobile: !!isMobile.any(),
    container: 'body'
  });

  function checkFill() {
    var listFilled = document.querySelectorAll('input:-webkit-autofill');
    setTimeout(function () {
      if (listFilled.length > 0) {
        listFilled.forEach(function (item) {
          var v = $(item).val('');
        });
        $('#cTarjetaNumero').trigger('change');
      }
    }, 100);
  }
}

function initFormularioTarjetaMP(oData, api) {
  var ccc = document.querySelector('#leyendaTarjetaCuotas');
  var buttonConfirmar = document.querySelector('#submit-process');
  var binLeyenda = document.querySelector('#leyendaTarjeta');
  var leyendaTarjetaCuotas = document.querySelector('#leyendaTarjetaCuotas');
  var docSelect = document.querySelector('#docType');
  var inputmp = [].slice.call(document.querySelectorAll('.input-mp'));
  var paymentObj = {};
  var docTypes = [{
    "id": "DNI",
    "name": "DNI",
    "type": "number",
    "min_length": 7,
    "max_length": 8
  }, {
    "id": "CI",
    "name": "Cédula",
    "type": "number",
    "min_length": 1,
    "max_length": 9
  }, {
    "id": "LC",
    "name": "L.C.",
    "type": "number",
    "min_length": 6,
    "max_length": 7
  }, {
    "id": "LE",
    "name": "L.E.",
    "type": "number",
    "min_length": 6,
    "max_length": 7
  }, {
    "id": "Otro",
    "name": "Otro",
    "type": "number",
    "min_length": 5,
    "max_length": 20
  }];
  docSelect.innerHTML = '';
  docTypes.forEach(function (item) {
    var option = document.createElement('option');
    option.value = item.id;
    option.innerHTML = item.name;

    if (item.id === 'DNI') {
      option.selected = true;
    }

    docSelect.appendChild(option);
  });
  new Card({
    form: document.querySelector('#payform'),
    container: '.card-wrapper',
    formSelectors: {
      numberInput: 'input[id="cTarjetaNumero"]',
      expiryInput: 'input[id="cExpirationCard"]',
      cvcInput: 'input[id="cCodigoSeguridad"]',
      nameInput: 'input[id="cNombreEnTarjeta"]'
    },
    messages: {
      validDate: 'expire\ndate',
      monthYear: 'mm/yy'
    }
  });
  var installments = document.querySelector('#cuotasCantidad');
  var sel = $(installments);
  sel.on('change', function () {
    var value = $(this).val();
    var rel = $(this).find('option:selected').attr('rel');
    oPagoSeleccion.set({
      idCuota: parseInt(value) > 0 ? parseInt(value) : 0,
      idFormaDePagoConfiguracionTarjeta: parseInt(rel) ? parseInt(rel) : 0
    });

    if (value) {
      if (isNotValid(inputmp)) {
        return;
      }

      paymentObj['idCuota'] = parseInt(value);
      observable.notify(paymentObj);
      observableValidation.notify({
        Pago: {
          estado: true,
          msg: ""
        }
      });
    } else {
      observableValidation.notify({
        Pago: {
          estado: false,
          msg: "Verifique los datos para el cobro"
        }
      });
    }
  });
  var cacheBin = '';
  binLeyenda.innerHTML = '';
  binLeyenda.style.display = 'none';
  leyendaTarjetaCuotas.innerHTML = '';
  leyendaTarjetaCuotas.style.display = 'none';
  on(document.querySelector('#cExpirationCard'), ['input'], function () {
    if (this.value.length === 7) {
      var arr = this.value.split(' / ');
      document.querySelector('[data-checkout="cardExpirationMonth"]').value = arr[0];
      document.querySelector('[data-checkout="cardExpirationYear"]').value = arr[1];

      if (this.classList.contains('error')) {
        this.classList.remove('error');
      }
    }
  });
  on(document.querySelector('#cNombreEnTarjeta'), ['input'], function () {
    var value = this.value;
    this.value = value.replace(/[^a-zA-Z ]/g, '');

    if (this.classList.contains('error')) {
      this.classList.remove('error');
    }
  });
  on(document.querySelector('#docNumber'), ['input'], function () {
    if (this.classList.contains('jp-card-invalid')) {
      this.classList.remove('jp-card-invalid');
    }
  });
  on(document.querySelector('#docType'), ['change'], function () {
    var d = document.querySelector("[data-id=\"".concat(this.id, "\"]"));

    if (d.classList.contains('error')) {
      d.classList.remove('error');
    }
  });
  on(document.querySelector('#cTarjetaNumero'), ['input', 'change'], function (e) {
    var bin = getBin(this);

    if (cacheBin === bin) {
      return false;
    }

    if (this.value.length === 7) {
      if (bin.length === 6) {
        cacheBin = bin;
        api.getProcessTarjetasFormasDePago({
          type: oData[0].idFormaDePago,
          bin: bin
        }).then(function (response) {
          installments.innerHTML = '';

          if (response.oData['lFormaDePagoConfiguracionTarjetaSeleccionada'] !== undefined) {
            if (response.oData.lFormaDePagoConfiguracionTarjetaSeleccionada.length > 0) {
              var TS = response.oData.lFormaDePagoConfiguracionTarjetaSeleccionada[0];
              paymentObj['idFormaDePago'] = TS.idFormaDePago;
              paymentObj['idFormaDePagoConfiguracionTarjeta'] = TS.idFormaDePagoConfiguracionTarjeta;
              observable.remove('idCuota');
              TS['lCuotas'].forEach(function (cuota) {
                var op = document.createElement('option');
                op.value = cuota.idCuota;
                op.innerText = cuota.cLeyenda;
                op.setAttribute('rel', TS.idFormaDePagoConfiguracionTarjeta);
                installments.appendChild(op);
              });
              var credenciales = {
                cClavePublicaGateway: TS.cClavePublicaGateway
              };
              observableTokens.notify(credenciales);
              leyendaTarjetaCuotas.innerHTML = '';
              leyendaTarjetaCuotas.style.display = 'none';
            } else {
              leyendaTarjetaCuotas.innerHTML = "<h3>Tarjeta no disponible</h3>";
              leyendaTarjetaCuotas.style.display = 'block';
            }
          } else {
            if (!Array.isArray(response.oData)) {
              var odata = response.oData.lCuotas[0];
              observable.remove('idCuota');
              var _credenciales3 = {
                cClavePublicaGatewaySch: response.oData.cClavePublicaGatewaySch,
                cClavePublicaGatewayTicket: response.oData.cClavePublicaGatewayTicket
              };
              observableTokens.notify(_credenciales3);
              odata.payer_costs.forEach(function (payer) {
                var op = document.createElement('option');
                op.value = payer.installments;
                op.innerText = payer.recommended_message;
                op.setAttribute('rel', 7);
                installments.appendChild(op);
              });
            } else {
              leyendaTarjetaCuotas.innerHTML = "<h3>Tarjeta no disponible</h3>";
              leyendaTarjetaCuotas.style.display = 'block';
            }
          }
        }).then(function () {
          sel.selectpicker('refresh');
        }).catch(function (err) {
          console.log(err);
        });
      }
    } else {
      checkFill();
    }
  });
  on(document.querySelector('#cTarjetaNumero'), ['paste'], function (e) {
    var _this2 = this;

    setTimeout(function () {
      var bin = getBin(_this2);

      if (cacheBin === bin) {
        return false;
      }

      if (blackList.indexOf(bin) > -1) {
        binLeyenda.innerHTML = "<h3>Tarjeta de debito no disponible</h3>";
        binLeyenda.style.display = 'block';
        installments.innerHTML = '';
        sel.selectpicker('refresh');
        return false;
      } else {}

      if (bin.length === 6) {
        cacheBin = bin;
        var obj = {
          type: oData[0].idFormaDePago,
          bin: bin
        };
        api.getProcessTarjetasFormasDePago(obj).then(function (response) {
          installments.innerHTML = '';

          if (response.oData['lFormaDePagoConfiguracionTarjetaSeleccionada'] !== undefined) {
            if (response.oData.lFormaDePagoConfiguracionTarjetaSeleccionada.length > 0) {
              var TS = response.oData.lFormaDePagoConfiguracionTarjetaSeleccionada[0];
              paymentObj['idFormaDePago'] = TS.idFormaDePago;
              paymentObj['idFormaDePagoConfiguracionTarjeta'] = TS.idFormaDePagoConfiguracionTarjeta;
              observable.remove('idCuota');
              TS['lCuotas'].forEach(function (cuota) {
                var op = document.createElement('option');
                op.value = cuota.idCuota;
                op.innerText = cuota.cLeyenda;
                op.setAttribute('rel', TS.idFormaDePagoConfiguracionTarjeta);
                installments.appendChild(op);
              });
              var credenciales = {
                cClavePublicaGateway: TS.cClavePublicaGateway
              };
              observableTokens.notify(credenciales);
              leyendaTarjetaCuotas.innerHTML = '';
              leyendaTarjetaCuotas.style.display = 'none';
            } else {
              leyendaTarjetaCuotas.innerHTML = "<h3>Tarjeta no disponible</h3>";
              leyendaTarjetaCuotas.style.display = 'block';
            }
          } else {
            if (!Array.isArray(response.oData)) {
              var odata = response.oData.lCuotas[0];
              observable.remove('idCuota');
              var _credenciales4 = {
                cClavePublicaGatewaySch: response.oData.cClavePublicaGatewaySch,
                cClavePublicaGatewayTicket: response.oData.cClavePublicaGatewayTicket
              };
              observableTokens.notify(_credenciales4);
              odata.payer_costs.forEach(function (payer) {
                var op = document.createElement('option');
                op.value = payer.installments;
                op.innerText = payer.recommended_message;
                installments.appendChild(op);
              });
            } else {
              leyendaTarjetaCuotas.innerHTML = "<h3>Tarjeta no disponible</h3>";
              leyendaTarjetaCuotas.style.display = 'block';
            }
          }
        }).then(function () {
          sel.selectpicker('refresh');
        }).catch(function (err) {
          console.log(err);
        });
      }
    }, 100);
  });
  on(buttonConfirmar, ['click'], function () {
    if (this.disabled === true) {
      return false;
    }

    if (isNotValid(inputmp)) {
      return;
    }

    ccc.innerHTML = '';
    ccc.style.display = 'none';
    var tycLeyenda = document.querySelector('#tycLeyenda');

    if (observableValidation.data['TYC']) {
      if (observableValidation.data['TYC'].estado === false) {
        tycLeyenda.innerHTML = '<h3>Debe aceptar los terminos y condiciones</h3>';
        return false;
      } else {
        tycLeyenda.innerHTML = '';
      }
    } else {
      tycLeyenda.innerHTML = '<h3>Debe aceptar los terminos y condiciones</h3>';
      return false;
    }

    var tc = document.querySelector('#cTarjetaNumero');
    var ec = document.querySelector('#cExpirationCard');
    var cs = document.querySelector('#cCodigoSeguridad');
    var decidirInputMes = document.querySelector('[data-decidir="card_expiration_month"]');
    var decidirInputAnio = document.querySelector('[data-decidir="card_expiration_year"]');
    var month = null;
    var year = null;

    if (decidirInputAnio && decidirInputMes) {
      month = decidirInputMes.value;
      year = decidirInputAnio.value;
    } else {
      month = document.querySelector('[data-checkout="cardExpirationMonth"]').value;
      year = document.querySelector('[data-checkout="cardExpirationYear"]').value;
    }

    if (month.length > 2 || year.length > 2) {
      ec.classList.add('error');
      return false;
    } else {
      if (ec.classList.contains('error')) {
        ec.classList.remove('error');
      }
    }

    if (tc.value === '' || ec.value === '' || cs.value === '') {
      return false;
    }

    if (tc.classList.contains('jp-card-invalid')) {
      ccc.innerHTML = '<h3>La tarjeta no es válida</h3>';
      ccc.style.display = 'block';
      return false;
    }

    if (ec.classList.contains('jp-card-invalid')) {
      ccc.innerHTML = '<h3>La fecha de vencimiento no es válida</h3>';
      ccc.style.display = 'block';
      return false;
    }

    if (cs.classList.contains('jp-card-invalid')) {
      ccc.innerHTML = '<h3>El código de seguridad no es válido</h3>';
      ccc.style.display = 'block';
      return false;
    }

    buttonConfirmar.disabled = true;
    buttonConfirmar.classList.add('load'); // api.setProcessCerrar(observable.data)

    api.getProcess(api.$curProcessId).then(function (response) {
      if (response.nCode === 200) {
        modalAprobado(observableTokens.data, {
          oProceso: response
        }, api);
      } else {
        if (response.oFormaDePago.nCode === 500) {
          $('#autorizado').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
          modalRechazoIntentoCobro(api, response.oFormaDePago, true);
        }
      }

      buttonConfirmar.disabled = false;
      buttonConfirmar.classList.remove('load');
    }).catch(function (err) {
      if (err) {
        console.log(err);
      }
    });
  });
  $('.tarjeta .selectpicker').selectpicker({
    showSubtext: true,
    mobile: !!isMobile.any(),
    container: 'body'
  });

  function checkFill() {
    var listFilled = document.querySelectorAll('input:-webkit-autofill');
    setTimeout(function () {
      if (listFilled.length > 0) {
        listFilled.forEach(function (item) {
          var v = $(item).val('');
        });
        $('#cTarjetaNumero').trigger('change');
      }
    }, 100);
  }
}

function isNotValid(inputmp) {
  var error = [];
  inputmp.forEach(function (dato) {
    if (dato.nodeName.toLowerCase() === 'input') {
      if (!dato.value.length) {
        dato.classList.add('jp-card-invalid');
        error.push(dato.id);
      }
    } else {
      if (dato.nodeName.toLowerCase() === 'select') {
        var value = getSelectedOption(dato).value;

        if (!value) {
          document.querySelector("[data-id=\"".concat(dato.id, "\"]")).classList.add('error');
          error.push(dato.id);
        }
      }
    }
  });
  return !!error.length;
}

function getSelectedOption(sel) {
  var opt;

  for (var i = 0, len = sel.options.length; i < len; i++) {
    opt = sel.options[i];

    if (opt.selected === true) {
      break;
    }
  }

  return opt;
}

function modalAprobado(credentials, odata, api) {
  var innerSelection = document.querySelector('#resumen-parcial-pago');

  if (!tplManager.exist('aprobado')) {
    tplManager.add('aprobado', '/templates/pago/aprobado.html').then(function (compiler) {
      innerSelection.innerHTML = compiler(odata);
      $('#autorizado').modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      });
      $('#confirmar-pago').on({
        click: function click() {
          $(this).addClass('load');
          $(this)[0].disabled = true;

          if (api.paymentChannel === 4) {
            init_dcr(credentials.cClavePublicaGateway, api).then(function (response) {
              if (response.oCobro.nCode === 200) {
                $('#autorizado').modal('hide');
                page("/confirmacion/".concat(api.$curProcessId));
              } else {
                modalRechazoIntentoCobro(api, response.oCobro.oData);
              }
            }).catch(function (err) {
              modalRechazoIntentoCobro(api, err, null, '403');
            });
          } else {}

          if (api.paymentChannel === 7) {
            init_mp(credentials.cClavePublicaGateway, api).then(function (response) {
              if (response.oCobro.nCode === 200) {
                $('#autorizado').modal('hide');
                page("/confirmacion/".concat(api.$curProcessId));
              } else {
                modalRechazoIntentoCobro(api, response.oCobro.oData);
              }
            }).catch(function (err) {
              modalRechazoIntentoCobro(api, err, null, '403');
            });
          } else {}
        }
      });
    }).catch(function (e) {
      console.log(e);
    });
  } else {
    var compiler = tplManager.templates['aprobado'];
    innerSelection.innerHTML = compiler(odata);
    $('#autorizado').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    $('#confirmar-pago').on({
      click: function click() {
        $(this).addClass('load');
        $(this)[0].disabled = true;

        if (api.paymentChannel === 4) {
          init_dcr(credentials.cClavePublicaGateway, api).then(function (response) {
            if (response.oCobro.nCode === 200) {
              $('#autorizado').modal('hide');
              page("/confirmacion/".concat(api.$curProcessId));
            } else {
              modalRechazoIntentoCobro(api, response.oCobro.oData);
            }
          }).catch(function (err) {
            modalRechazoIntentoCobro(api, err, null, '403');
          });
        }

        if (api.paymentChannel === 7) {
          init_mp(credentials, api).then(function (response) {
            if (response.oCobro.nCode === 200) {
              $('#autorizado').modal('hide');
              page("/confirmacion/".concat(api.$curProcessId));
            } else {
              $('#autorizado').modal('hide');
              modalRechazoIntentoCobroMP(api, response.oCobro.oData);
            }
          }).catch(function (err) {
            modalRechazoIntentoCobroMP(api, err, null, '403');
          });
        }
      }
    });
  }
}

function modalRechazoIntentoCobro(api, obj, duplicate, code) {
  var odata = {
    title: "No se pudo procesar la solicitud de cobro.",
    text: "Por favor!, disculpe las molestias ocacionadas e intente nuevamente."
  };

  if (obj) {
    odata = {
      title: 'Su operación no pudo ser procesada',
      text: 'Por favor consulte con la entidad bancaria emisora de su tarjeta ó intente más tarde o con otra tarjeta de crédito.'
    };
  } else {}

  if (duplicate) {
    odata = {
      title: 'El proceso de cobro no puede realizarse',
      text: obj.oData
    };
  } else {}

  if (code === '403') {
    odata = obj;
  } else {}

  var innerSelection = document.querySelector('#resumen-parcial-pago');

  if (!tplManager.exist('rechazo')) {
    tplManager.add('rechazo', '/templates/pago/rechazo.html').then(function (compiler) {
      innerSelection.innerHTML = compiler(odata);
      var click = api.$curFID > 0 ? $('#confirmar-pago').attr('href', "/seleccion/".concat(api.$curFID)) : $('#confirmar-pago').attr('href', "/seleccion/".concat(api.apiRedirectTo));

      if (obj) {
        click.attr('href', "".concat(api.apiRedirectTo));
        click.html('inicio');
      }

      click.on({
        click: function click() {
          $('#autorizado').modal('hide');

          if (obj) {
            window.location = "".concat(api.apiRedirectTo);
          }
        }
      });
    });
  } else {
    var compiler = tplManager.templates['rechazo'];
    innerSelection.innerHTML = compiler(odata);
    var click = $('#confirmar-pago').attr('href', "/seleccion/".concat(api.$curEID));

    if (obj) {
      click.attr('href', "".concat(api.apiRedirectTo));
      click.html('inicio');
    }

    click.on({
      click: function click() {
        $('#autorizado').modal('hide');

        if (obj) {
          window.location = "".concat(api.apiRedirectTo);
        }
      }
    });
  }

  $('.icono_cerrar_modal').remove();
  $('.leyenda-respuesta').remove();
}

function modalRechazoIntentoCobroMP(api, obj, duplicate, code) {
  var odata = {
    title: "No se pudo procesar la solicitud de cobro.",
    text: "Por favor!, disculpe las molestias ocacionadas e intente nuevamente."
  };

  if (obj) {
    odata = {
      title: 'Su operación no pudo ser procesada',
      text: 'Por favor consulte con la entidad bancaria emisora de su tarjeta ó intente más tarde o con otra tarjeta de crédito.'
    };
  } else {}

  if (duplicate) {
    odata = {
      title: 'El proceso de cobro no puede realizarse',
      text: obj.oData
    };
  } else {}

  if (code === '403') {
    odata = obj;
  } else {}

  var innerSelection = document.querySelector('#resumen-parcial-pago');

  if (!tplManager.exist('rechazoMP')) {
    tplManager.add('rechazoMP', '/templates/pago/rechazo-mp.html').then(function (compiler) {
      innerSelection.innerHTML = compiler(odata);
      var click = api.$curFID > 0 ? $('#confirmar-pago').attr('href', "/seleccion/".concat(api.$curFID)) : $('#confirmar-pago').attr('href', "/seleccion/".concat(api.apiRedirectTo));

      if (obj) {
        click.attr('href', "".concat(api.apiRedirectTo));
        click.html('inicio');
      }

      click.on({
        click: function click() {
          $('#autorizado').modal('hide');

          if (obj) {
            window.location = "".concat(api.apiRedirectTo);
          }
        }
      });
    });
  } else {
    var compiler = tplManager.templates['rechazoMP'];
    innerSelection.innerHTML = compiler(odata);
    var click = $('#confirmar-pago').attr('href', "/seleccion/".concat(api.$curEID));

    if (obj) {
      click.attr('href', "".concat(api.apiRedirectTo));
      click.html('inicio');
    }

    click.on({
      click: function click() {
        $('#autorizado').modal('hide');

        if (obj) {
          window.location = "".concat(api.apiRedirectTo);
        }
      }
    });
  }

  $('.icono_cerrar_modal').remove();
  $('.leyenda-respuesta').remove();
}

function init_dcr(credentials, api) {
  var publicApiKey = credentials; // console.info(publicApiKey)

  var urlSandbox = 'https://developers.decidir.com/api/v2';
  var urlProduccion = 'https://live.decidir.com/api/v2';
  var inhabilitarCS = true;
  var DECIDIRURL = urlSandbox;

  if (api.$boolEnvProd) {
    // console.log('prod',api.$boolEnvProd)
    DECIDIRURL = urlProduccion;
  }

  var decidir = new Decidir(DECIDIRURL, inhabilitarCS);
  decidir.setPublishableKey(publicApiKey);
  decidir.setTimeout(0);
  var consultandoPago = false;
  var Form = document.querySelector('#payform'); // var a = decidir.collectFormData(Form)
  // var r = decidir.decidirValidator.validateAll(a)

  return new Promise(function (resolve, reject) {
    consultandoPago = true;
    decidir.createToken(Form, function (status, response) {
      if (status === 403) {
        if (response['message']) {
          reject({
            title: 'No se pudo procesar la solicitud de cobro.',
            text: 'Por favor!, disculpe las molestias ocacionadas\ne intente nuevamente.',
            message: response.message
          });
        } else {
          reject({
            title: 'No se pudo procesar la solicitud de cobro.',
            text: 'Por favor!, disculpe las molestias ocacionadas\ne intente nuevamente.',
            message: ''
          });
        }
      } else {
        if (status === 200 || status === 201 && response.id && response.status === 'active') {
          var params = {
            cEmail: Form.querySelector('#cEmail').value,
            cDocumento: Form.querySelector('#docNumber').value,
            cNombreTarjeta: document.querySelector('#cTarjetaNumero').getAttribute('data-card-name'),
            PaymentResponse: JSON.stringify(response)
          }; // console.log(JSON.stringify(params))

          api.setProcessCobrar(params).then(function (cobro) {
            window.localStorage.removeItem('curr_process');
            resolve(cobro);
          }).catch(function (err) {
            reject(err);
          });
        } else {
          var error = null;

          if (response.hasOwnProperty('error_type')) {
            error = {
              title: 'No se pudo procesar la solicitud de cobro.',
              text: 'Por favor!, disculpe las molestias ocacionadas\ne intente nuevamente.',
              message2: response['validation_errors'] || ''
            };
          } else {
            error = {
              title: 'No se pudo procesar la solicitud de cobro.',
              text: 'Por favor!, disculpe las molestias ocacionadas\ne intente nuevamente.',
              message: ''
            };
          }

          reject(error);
        }
      }
    });
  });
}

function init_mp(credentials, api) {
  Mercadopago.clearSession();
  Mercadopago.setPublishableKey(credentials);
  var bin = getBin(document.querySelector('#cTarjetaNumero'));
  var consultandoPago = false;
  var Form = document.querySelector('#payform');
  return new Promise(function (resolve, reject) {
    consultandoPago = true;
    Mercadopago.getPaymentMethod({
      bin: bin
    }, function (status, response) {
      if (status === 200) {
        var {
          id,
          payment_type_id
        } = response[0];
        Mercadopago.createToken(Form, function (status, response) {
          if (status !== 200 && status !== 201) {
            if (api.$boolEnvProd) {
              console.log('tokenHandler ' + status);
              console.log('status ' + status);
              console.log(response);
            }

            reject({
              title: 'No se pudo procesar la solicitud de cobro.',
              text: 'Por favor!, disculpe las molestias ocacionadas\ne intente nuevamente.'
            });
          } else {
            // Payment Response
            var PaymentResponse = document.querySelector('[name="PaymentResponse"]');
            PaymentResponse.value = JSON.stringify(response);
            var params = {
              cEmail: Form.querySelector('#ceMail').value,
              cDocumento: Form.querySelector('#docNumber').value,
              PaymentResponse: JSON.stringify(response),
              payment_type_id: payment_type_id,
              payment_method_id: id
            };
            api.setProcessCobrar(params).then(function (cobro) {
              resolve(cobro);
            }).catch(function (err) {
              reject(err);
            });
          }
        });
      }
    });
  });
}

function getBin(input) {
  var ccNumber = input.value;
  return ccNumber.replace(/[ .-]/g, '').slice(0, 6);
}

function startTimer(duration, display, callback) {
  var start = Date.now(),
      diff,
      minutes,
      seconds;
  var timerInterval;

  function timer() {
    diff = duration - ((Date.now() - start) / 1000 | 0);
    minutes = diff / 60 | 0;
    seconds = diff % 60 | 0;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    display.innerText = minutes + ":" + seconds;

    if (diff <= 0) {
      start = Date.now() + 1000;
    }

    if (diff === 0) {
      clearInterval(timerInterval);

      if (callback && typeof callback === 'function') {
        return callback();
      }
    }
  }

  timer();
  timerInterval = setInterval(timer, 1000);
}

export default {
  name: 'proceso',
  path: '/proceso/:id',
  template: '/views/proceso.html',
  props: function props(ctx, api) {
    var loading = document.querySelector('.showbox');
    loading.classList.add('is-visible');
    loading.classList.remove('is-hidden');
    return new Promise(function (resolve, reject) {
      if (!window.localStorage.getItem('user')) {
        window.location = api.apiRedirectTo;
      }

      var id = ctx.params.id;
      api.$curProcessId = id;
      pObject = {
        id
      };
      api.getProcess(id).then(function (proceso) {
        pObject['Proceso'] = proceso.oData;
        api.getClienteInfomacion().then(function (infomacion) {
          pObject['ClienteInformacion'] = infomacion.oData;
          api.getProcessTiposDeEntrega().then(function (tipoentrega) {
            pObject['TiposEntrega'] = tipoentrega.oData;
            api.getProcessFormasDePago().then(function (formaspago) {
              pObject['FormasPago'] = formaspago.FormasDePago.oData;
              pObject['ENV'] = formaspago.bEntornoProductivo;
              api.$boolEnvProd = formaspago.bEntornoProductivo; // console.info(pObject);

              resolve(pObject);
            }).catch(function (err) {
              reject(err);
            });
          }).catch(function (err) {
            reject(err);
          });
        });
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  enter: function enter(ctx, next, mounted, api) {
    scrolltop();
    oEntregaSeleccion = new EnviosSeleccion();
    oPagoSeleccion = new PagosSeleccion();
    objEnvioPago = {};
    var container = document.querySelector('#app');
    container.innerHTML = mounted.compiled; // ESCUCHA EL CAMBIO EN LAS FORMAS DE ENVIO

    var buttonCerrar = document.querySelector('#submit-process');
    oEntregaSeleccion.on(function (data) {
      if (oEntregaSeleccionar.state === 'PENDING') {
        return;
      }

      var loader = $('.check.is-checked').parent().find('.entrega-loader');
      loader.addClass('visible-loader');

      if (data.idEntregaTipo <= 0) {
        return;
      }

      $('[name="idEntregaTipo"]').addClass('disabled');

      if (data.idEntregaTipo === 3) {
        if (data.idClienteDomicilio === 0 || data.idClienteTelefono === 0) {
          objEnvioPago['envio'] = false;
          return false;
        }
      }

      objEnvioPago['envio'] = false;

      if (!doEnvio) {
        doEnvio = true;
        oEntregaSeleccionar.notify({
          state: 'PENDING'
        });
        api.setEntregaTipoSeleccionar(api.$curProcessId, data).then(function (oEnvioResponse) {
          if (oEnvioResponse.oEnvio.nCode === 200) {
            try {
              api.getProcess(oEnvioResponse.oEnvio.oData.idProcesoDeCompra).then(function (oProcesoResponse) {
                doEnvio = false;
                objEnvioPago['envio'] = true;
                $('#nombre-entrega').html(oProcesoResponse.oData.oEntregaTipo.cEntregaTipoNombre);
                $('#valor').html('$' + oProcesoResponse.oData.oPrecioTotal.fPrecioTotalEntrega);
                $('#tt').html('$' + oProcesoResponse.oData.oPrecioTotal.fPrecioTotalFinal);

                if (objEnvioPago['envio'] === true && objEnvioPago['pago'] === true) {
                  buttonCerrar.disabled = false;
                  buttonCerrar.style.opacity = 1;
                  buttonCerrar.style.cursor = 'default';
                } else {
                  buttonCerrar.disabled = true;
                  buttonCerrar.style.opacity = .5;
                  buttonCerrar.style.cursor = 'not-allowed';
                }

                loader.removeClass('visible-loader');
                oEntregaSeleccionar.notify({
                  state: 'ENDED'
                });
              });
            } catch (e) {
              oEntregaSeleccionar.notify({
                state: 'ENDED'
              });
            }
          } else {
            oEntregaSeleccionar.notify({
              state: 'ENDED'
            });
            doEnvio = false;
            loader.removeClass('visible-loader');
            $('#autorizado').modal({
              backdrop: 'static',
              keyboard: false,
              show: true
            });
            modalRechazoIntentoCobro(api, oEnvioResponse.oEnvio, true);
          }
        });
      } else {
        oEntregaSeleccionar.notify({
          state: 'ENDED'
        });
        doEnvio = false;
        objEnvioPago['envio'] = false;
      }
    }); // ESCUCHA EL CAMBIO EN LAS FORMAS DE PAGO

    oPagoSeleccion.on(function (data) {
      objEnvioPago['pago'] = false;
      oPagoSeleccionar.notify({
        state: 'PENDING'
      });
      api.setFormaDePagoTipoSeleccionar(api.$curProcessId, data).then(function (oPagoResponse) {
        if (oPagoResponse.oFormaDePago.nCode === 200) {
          api.getProcess(oPagoResponse.oFormaDePago.oData.idProcesoDeCompra).then(function (oProcesoResponse) {
            if (oProcesoResponse) {
              objEnvioPago['pago'] = true;

              if (objEnvioPago['envio'] === true && objEnvioPago['pago'] === true) {
                buttonCerrar.disabled = false;
                buttonCerrar.style.opacity = 1;
                buttonCerrar.style.cursor = 'default';
              } else {
                buttonCerrar.disabled = true;
                buttonCerrar.style.opacity = .5;
                buttonCerrar.style.cursor = 'not-allowed';
              }
            }

            oPagoSeleccionar.notify({
              state: 'ENDED'
            });
          });
        } else {
          oPagoSeleccionar.notify({
            state: 'ENDED'
          });
          $('#autorizado').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
          modalRechazoIntentoCobro(api, oPagoResponse.oFormaDePago, true);
        }
      });
    });
    $('.btn-cancel').css({
      cursor: 'pointer'
    }).on({
      click: function click(e) {
        e.preventDefault();
        api.setProcessInvalidar().then(function (response) {
          if (response) {
            page("/seleccion/".concat(api.prevViewSeleccion ? api.prevViewSeleccion : api.apiRedirectTo));
          }
        }).catch(function (err) {
          console.info(err);
        });
      }
    });

    if (!tplManager) {
      tplManager = new TemplateManager(api.$root);
    }

    var modules = [initCheckEntrega(pObject, api), initCheckMediosPago(pObject, api)];
    var button = document.querySelector('#submit-process');
    button.disabled = true;
    button.style.opacity = .5;
    button.style.cursor = 'not-allowed';
    var oobj = {
      cObservaciones: ''
    };
    observable.notify(oobj);
    $('[name="cObservaciones"]').on('input selectionchange propertychange', function () {
      var value = $(this).val(); // console.info(value)

      if (value.length > 0) {
        var _oobj = {
          cObservaciones: value
        };
        observable.notify(_oobj);
      }
    });
    $('.check-terminos').on('change', function () {
      var value = $(this).is(':checked');
      var tycLeyenda = document.querySelector('#tycLeyenda');

      if (objEnvioPago['envio'] === true && objEnvioPago['pago'] === true) {
        buttonCerrar.disabled = false;
        buttonCerrar.style.opacity = 1;
        buttonCerrar.style.cursor = 'default';
      } else {
        buttonCerrar.disabled = true;
        buttonCerrar.style.opacity = .5;
        buttonCerrar.style.cursor = 'not-allowed';
      }

      if (tycLeyenda) {
        tycLeyenda.innerHTML = '';
      }

      if (value) {
        observableValidation.notify({
          TYC: {
            estado: true,
            msg: ''
          }
        });
      } else {
        observableValidation.notify({
          TYC: {
            estado: false,
            msg: 'Debe aceptar los Términos y Condiciones'
          }
        });
      }
    });
    startTimer(60 * 6, document.getElementById('display_timer'), function () {
      $('#modalAlertTiempoExpirado').modal({
        backdrop: 'static',
        keyboard: false
      }).on('hidden.bs.modal', function (e) {
        e.preventDefault();
      });
      setTimeout(function () {
        api.setProcessInvalidar().then(function (response) {
          if (response) {
            window.location = api.apiRedirectTo;
          }
        }).catch(function (err) {
          console.info(err);
        });
      }, 3000);
    });
    Promise.all(modules).then(function () {
      var loading = [].slice.call(document.querySelectorAll('.showbox'));
      loading.forEach(function (item) {
        if (item.classList.contains('is-visible')) {
          item.classList.remove('is-visible');
          item.classList.add('is-hidden');
        }
      });
    });
  }
};