import is from 'is';
import ripple from '../../utils/ripple';
import isMobile from '../../utils/isMobile';
import TemplateManager from '../../app/core/TemplateManager';
import page from 'page';

var userNavigation = function userNavigation(element) {
  if (!is.element(element)) {
    throw new Error("element no esta definido o no es de tipo ELEMENT");
  }

  this.el = element;
  this.initEvents();
};

userNavigation.prototype = {
  initEvents: function initEvents() {
    var _this = this;

    var self = this;

    var initSearchFn = function initSearchFn(ev) {
      ev.stopPropagation();
      ripple(_this.el, {
        type: 'center',
        bgc: 'rgba(255,255,255,.5)'
      });

      if (!_this.el.parentNode.classList.contains('sb-nav-open')) {
        ev.preventDefault();

        _this.el.focus();

        self.open();
      } else if (_this.el.parentNode.classList.contains('sb-nav-open')) {
        // close it
        ev.preventDefault();

        _this.el.blur();

        self.close();
      }
    };

    this.el.addEventListener('click', initSearchFn);
    this.el.addEventListener('touchstart', initSearchFn);
  },
  open: function open() {
    var self = this;
    this.el.parentNode.classList.add('sb-nav-open');

    var bodyFn = function bodyFn() {
      self.close();
      this.removeEventListener('click', bodyFn);
      this.removeEventListener('touchstart', bodyFn);
    };

    document.addEventListener('click', bodyFn);
    document.addEventListener('touchstart', bodyFn);
  },
  close: function close() {
    this.el.parentNode.classList.remove('sb-nav-open');
    this.el.blur();
  }
};

var Search = function Search(element) {
  if (!is.element(element)) {
    throw new Error("element no esta definido o no es de tipo ELEMENT");
  }

  this.el = element;
  this.inputEl = element.querySelector('form > input.sb-search-input');
  this.inputSubmitEl = document.querySelector('button.sb-icon-search');
  this.formEl = this.inputEl.form;
  this.initEvents();
};

Search.prototype = {
  initEvents: function initEvents() {
    var _this2 = this;

    var self = this;
    self.inputEl.style.display = 'none';
    self.formEl.addEventListener('submit', function (e) {
      return e.preventDefault();
    });

    var initSearchFn = function initSearchFn(ev) {
      ev.stopPropagation(); // trim its value

      self.inputEl.value = self.inputEl.value.trim();
      ripple(_this2.el, {
        type: 'center',
        bgc: 'rgba(255,255,255,.5)'
      });

      if (!_this2.el.classList.contains('sb-search-open')) {
        // open it
        ev.preventDefault();

        _this2.el.focus();

        self.open();
      } else if (_this2.el.classList.contains('sb-search-open') && /^\s*$/.test(self.inputEl.value)) {
        // close it
        ev.preventDefault();

        _this2.el.blur();

        self.close();
      }
    };

    this.el.addEventListener('click', initSearchFn);
    this.el.addEventListener('touchstart', initSearchFn);

    var initSubmit = function initSubmit(e) {
      switch (e.type) {
        case 'click':
          if (self.inputEl.value.length >= 3) {
            page("/resultado?busqueda=".concat(self.inputEl.value));
            self.inputEl.value = '';
          }

          break;

        case 'keyup':
          if (this.focus && e.keyCode === 13) {
            if (this.value.length >= 3) {
              page("/resultado?busqueda=".concat(this.value));
              this.value = '';
            }
          }

          break;

        default:
          break;
      }
    };

    this.inputEl.addEventListener('keyup', initSubmit);
    this.inputSubmitEl.addEventListener('click', initSubmit);
    this.inputEl.addEventListener('click', function (ev) {
      return ev.stopPropagation();
    });
    this.inputEl.addEventListener('touchstart', function (ev) {
      return ev.stopPropagation();
    });
  },
  open: function open() {
    var self = this;
    this.el.classList.add('sb-search-open');
    this.inputEl.style.display = 'block'; // focus the input

    if (!isMobile.any()) {
      this.inputEl.focus();
    } else {
      var posX = document.querySelector('.user-action').offsetLeft;
      this.formEl.style.left = -(posX + 16) + 'px';
      this.inputEl.focus();
    } // close the search input if body is clicked


    var bodyFn = function bodyFn() {
      self.close();
      this.removeEventListener('click', bodyFn);
      this.removeEventListener('touchstart', bodyFn);
    };

    document.addEventListener('click', bodyFn);
    document.addEventListener('touchstart', bodyFn);
  },
  close: function close() {
    this.inputEl.blur();
    this.inputEl.style.display = 'none';
    this.el.classList.remove('sb-search-open');
  }
};

$.validator.methods.email = function (value, element) {
  return this.optional(element) || /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/.test(value);
};

export default (function (api) {
  var templateManager = new TemplateManager(api.$root);
  return new Promise(function (resolve, reject) {
    var userAction = document.querySelector('#sb-nav-button');
    var searchAction = document.querySelector('#sb-search');

    if (userAction) {
      var n = new userNavigation(userAction);
      var s = null;

      if (searchAction) {
        s = new Search(searchAction);
      }

      resolve({
        navigation: n,
        search: s
      });
    } else {
      reject();
    }
  });
});