import { one } from '../../utils/events';
import { transitionEnd } from 'cssevents';
import TemplateManager from '../core/TemplateManager';
import scrolltop from '../../utils/scrolltop';
var tplManager = null;
export default {
  name: 'confirmacion',
  path: '/confirmacion/:id',
  template: '/views/confirmacion.html',
  props: function props(ctx, api) {
    var loading = document.querySelector('.showbox');
    loading.classList.add('is-visible');
    loading.classList.remove('is-hidden');
    return new Promise(function (resolve, reject) {
      var id = ctx.params.id;
      api.$curProcessId = id;
      api.setProcessConfirmacion(id).then(function (proceso) {
        resolve(proceso.oData);
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  enter: function enter(ctx, next, mounted, api) {
    scrolltop();
    var loading = document.querySelector('.showbox');
    var container = document.querySelector('#app');
    container.innerHTML = mounted.compiled;

    if (!tplManager) {
      tplManager = new TemplateManager(api.$root);
    }

    if (loading.classList.contains('is-visible')) {
      loading.classList.remove('is-visible');
      one(loading, transitionEnd, function () {
        loading.classList.add('is-hidden');
      });
    }
  }
};