import { one } from '../../utils/events';
import { transitionEnd } from 'cssevents';
import is from 'is';
import scrolltop from '../../utils/scrolltop';
import Observable from '../../utils/observable';
import TemplateManager from '../core/TemplateManager';
var tplManager = null;
var MES = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre'
};

var getFormObjectParams = function getFormObjectParams(form) {
  var formElements = form.elements;
  var obj = {};

  for (var i = 0; i < formElements.length; i++) {
    if (formElements[i].name !== '') {
      obj[formElements[i].name] = formElements[i].value;
    }
  }

  return obj;
};

var oCliente = null;
var observable = new Observable();
observable.add(function (obj) {
  if (is.empty(obj)) {
    return;
  }

  var self = this;
  var keys = Object.keys(obj);
  keys.forEach(function (value) {
    if (obj[value] !== null && obj[value] !== undefined) {
      Object.assign(self.data, obj);
    } else {
      if (value in self.data) {
        delete self.data[value];
      }
    }
  });
});
/**DOMICILIO*/

function fillDom(domicilio, empty) {
  var botonEliminar = $('#submit-domicilio-eliminar');
  var botonCambiar = $('#submit-domicilio-cambiar');

  if (domicilio.length) {
    $('#boxDomSeleccion .dato').each(function () {
      $(this).removeClass('error');
      $(this).parent().removeClass('error');

      if (empty) {
        botonEliminar.css('display', 'none');
        botonCambiar.css('display', 'none');

        if ($(this).is('select')) {
          $(this).find('option').eq(0).prop('selected', true);
          $(this).prop('disabled', false);
          $(this).selectpicker('refresh');
        } else {
          $(this).val('');
        }
      } else {
        botonEliminar.css('display', 'block');
        botonCambiar.css('display', 'block');
        botonCambiar.addClass('disabled').css('opacity', .25);
        var name = $(this)[0].name;

        if (name in domicilio[0]) {
          if ($(this).is('select')) {
            var o = $("#boxDomSeleccion option[value=\"".concat(domicilio[0][name], "\"]"));
            var sel = $(this);
            sel.prop('disabled', false);

            if (o.length > 0) {
              o.prop('selected', true);
              sel.selectpicker('val', domicilio[0][name]);
            } else {
              $(this).find('option').eq(0).prop('selected', true);
              sel.selectpicker('val', '');
            }

            sel.selectpicker('refresh');
          } else {
            if ($(this).is('input')) {
              if ($(this).is(':checkbox')) {
                $(this).prop('checked', domicilio[0][name]);
              } else {
                $(this).val(domicilio[0][name]);
              }
            }
          }
        }
      }
    });

    if (domicilio[0].idClienteDomicilio > 0) {
      botonEliminar.attr('href', domicilio[0].idClienteDomicilio);
      botonCambiar.attr('href', domicilio[0].idClienteDomicilio);
      botonCambiar.addClass('disabled').css('opacity', .25);
    }
  }
}
/**TELEFONO*/


function fillDomTelefono(domicilio, empty) {
  var botonEliminar = $('#submit-telefono-eliminar');
  var botonCambiar = $('#submit-tel-cambiar');

  if (domicilio.length) {
    $('#boxDomSeleccionTelefono .dato').each(function () {
      $(this).removeClass('error');
      $(this).parent().removeClass('error');

      if (empty) {
        botonEliminar.css('display', 'none');
        botonCambiar.css('display', 'none');
        botonCambiar.addClass('disabled').css('opacity', .25);

        if ($(this).is('select')) {
          $(this).find('option').eq(0).prop('selected', true); // $(this).prop('disabled', false)

          $(this).selectpicker('refresh');
        } else {
          $(this).val(''); // $(this).prop('readonly', false)
        }
      } else {
        botonEliminar.css('display', 'block');
        botonCambiar.css('display', 'block');
        botonCambiar.addClass('disabled').css('opacity', .25);
        var name = $(this)[0].name;

        if (name in domicilio[0]) {
          if ($(this).is('select')) {
            var o = $("#boxDomSeleccionTelefono option[value=\"".concat(domicilio[0][name], "\"]"));
            var sel = $(this);
            sel.prop('disabled', false);

            if (o.length > 0) {
              o.prop('selected', true); // sel.prop('disabled', true)

              sel.selectpicker('val', domicilio[0][name]);
            } else {
              $(this).find('option').eq(0).prop('selected', true);
              sel.selectpicker('val', '');
            }

            sel.selectpicker('refresh');
          } else {
            if ($(this).is('input')) {
              if ($(this).is(':checkbox')) {
                $(this).prop('checked', domicilio[0][name]); // $(this).prop('readonly', true)
              } else {
                $(this).val(domicilio[0][name]); // $(this).prop('readonly', true)
              }
            }
          }
        }
      }
    });

    if (domicilio[0].idClienteTelefono > 0) {
      botonEliminar.attr('href', domicilio[0].idClienteTelefono);
      botonCambiar.attr('href', domicilio[0].idClienteTelefono);
    }
  }
}

function initSelectoresDomicilioSeleccion(lista, api) {
  var boton = $('#control-envio-registro');
  var domicilio = lista.Domicilios.filter(function (item) {
    return item.bDefault === true;
  });

  if (domicilio.length) {
    fillDom(domicilio, false);
  }

  $('[name="cDomicilio"]').on('change', function () {
    var value = parseInt($(this).val());

    if (value > 0) {
      boton.css('display', 'none');

      var _domicilio = lista.Domicilios.filter(function (item) {
        return item.idClienteDomicilio === value;
      });

      fillDom(_domicilio, false);
    } else {
      boton.css('display', 'block');
      fillDom([{
        idClienteDomicilio: 0,
        idDomicilioTipo: 0,
        bDefault: false,
        cCalle: '',
        cNumero: '',
        cPiso: '',
        cUnidad: '',
        cLocalidad: '',
        cProvincia: '',
        cPais: '',
        cCodigoPostal: ''
      }], true);
    }
  });

  if (lista.Domicilios.length === 0) {
    boton.css('display', 'block');
  }

  boton.on('click', function () {
    var params = {
      idPais: 10,
      idProvincia: 0,
      idLocalidad: 0
    };
    $('#boxDomSeleccion .dato').each(function () {
      var value = $(this).val();

      if ($(this).is('select')) {
        var name = $(this)[0].name;
        params[name] = value;
        var p = $(this).parent().find('.dropdown-toggle');

        if (value === '') {
          p.addClass('error');
        } else {
          p.removeClass('error');
        }
      }

      if ($(this).is('input')) {
        if ($(this).is(':checkbox')) {
          var _name = $(this)[0].name;
          params[_name] = $(this).is(':checked');
        } else {
          if ($(this)[0].name === 'cCalle' || $(this)[0].name === 'cNumero' || $(this)[0].name === 'cLocalidad' || $(this)[0].name === 'cPais' || $(this)[0].name === 'cCodigoPostal' || $(this)[0].name === 'cReferente') {
            if (value !== '') {
              var _name2 = $(this)[0].name;
              params[_name2] = value;
              $(this).removeClass('error');
            } else {
              $(this).addClass('error');
            }
          } else {
            var _name3 = $(this)[0].name;
            params[_name3] = value;
          }
        }
      }

      if ($(this).is(":hidden")) {
        var _name4 = $(this)[0].name;
        params[_name4] = value;
      }
    });

    if (Object.keys(params).length === 14) {
      api.setClienteDomicilio(params).then(function (respuesta) {
        if (respuesta.oData['idClienteDomicilio']) {
          listar(lista, api);
        }
      });
    }
  });
  $('#submit-domicilio-eliminar').on({
    click: function click(e) {
      e.preventDefault();
      var id = $(this).attr('href');

      if (id) {
        api.setClienteDomicilioEliminar(id).then(function (respuesta) {
          if (respuesta.nCode === 200) {
            listar(lista, api);
          }
        });
      }
    }
  });
  $('#submit-domicilio-cambiar').on({
    click: function click(e) {
      e.preventDefault();

      if ($(this).hasClass('disabled')) {
        return false;
      }

      var id = $(this).attr('href');

      if (id) {
        var params = {
          idPais: 10,
          idProvincia: 0,
          idLocalidad: 0
        };
        $('#boxDomSeleccion .dato').each(function () {
          var value = $(this).val();

          if ($(this).is('select')) {
            var name = $(this)[0].name;
            params[name] = value;
          }

          if ($(this).is('input')) {
            if ($(this).is(':checkbox')) {
              var _name5 = $(this)[0].name;
              params[_name5] = $(this).is(':checked');
            } else {
              var _name6 = $(this)[0].name;
              params[_name6] = value;
            }
          }

          if ($(this).is(":hidden")) {
            var _name7 = $(this)[0].name;
            params[_name7] = value;
          }
        });
        api.setClienteDomicilioCambio(id, params).then(function (respuesta) {
          if (respuesta.nCode === 200) {
            listar(lista, api);
          }
        });
      }
    }
  }).addClass('disabled').css('opacity', .25);
  $('.selectpicker').selectpicker();
  $('#boxDomSeleccion .dato').each(function () {
    $(this).on({
      change: function change() {
        var edit = $('#submit-domicilio-cambiar');

        if (edit.hasClass('disabled')) {
          edit.removeClass('disabled').css('opacity', 1);
        }
      }
    });
  });
}

function initSelectoresDomicilioSeleccion2(lista, api) {
  var botonTele = $('#control-tel-registro');
  var telefono = lista.Telefonos.filter(function (item) {
    return item.bDefault === true;
  });

  if (telefono.length) {
    fillDomTelefono(telefono, false);
  }

  $('[name="cTelofonos"]').on('change', function () {
    var value = parseInt($(this).val());

    if (value > 0) {
      botonTele.css('display', 'none');
      var tele = lista.Telefonos.filter(function (item) {
        return item.idClienteTelefono === value;
      });
      fillDomTelefono(tele, false);
    } else {
      botonTele.css('display', 'block');
      fillDomTelefono([{
        idClienteTelefono: 0,
        idTelefonoTipo: 0,
        cTelefonoNumero: 0,
        bDefault: false
      }], true);
    }
  });

  if (lista.Telefonos.length === 0) {
    botonTele.css('display', 'block');
  }

  botonTele.on('click', function () {
    var params = {};
    $('#boxDomSeleccionTelefono .dato').each(function () {
      var value = $(this).val();

      if ($(this).is('select')) {
        var name = $(this)[0].name;
        params[name] = value;
      }

      if ($(this).is('select')) {
        var _name8 = $(this)[0].name;
        params[_name8] = value;
        var p = $(this).parent().find('.dropdown-toggle');

        if (value === '') {
          p.addClass('error');
        } else {
          p.removeClass('error');
        }
      }

      if ($(this).is('input')) {
        if ($(this).is(':checkbox')) {
          var _name9 = $(this)[0].name;
          params[_name9] = $(this).is(':checked');
        } else {
          if (value !== '') {
            var rg = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;

            if (rg.test(value)) {
              var _name10 = $(this)[0].name;
              params[_name10] = value;
            } else {
              $(this).addClass('error');
            }

            $(this).removeClass('error');
          } else {
            $(this).addClass('error');
          }
        }
      }
    });

    if (Object.keys(params).length === 3) {
      api.setClienteTelefono(params).then(function (respuesta) {
        if (respuesta.oData['idClienteDomicilio']) {
          listar2(lista, api);
        }
      });
    }
  });
  $('#submit-telefono-eliminar').on({
    click: function click(e) {
      e.preventDefault();
      var id = $(this).attr('href');

      if (id) {
        var params = {};
        $('#boxDomSeleccionTelefono .dato').each(function () {
          var value = $(this).val();

          if ($(this).is('select')) {
            var name = $(this)[0].name;
            params[name] = value;
          }

          if ($(this).is('input')) {
            if ($(this).is(':checkbox')) {
              var _name11 = $(this)[0].name;
              params[_name11] = $(this).is(':checked');
            } else {
              var _name12 = $(this)[0].name;
              params[_name12] = value;
            }
          }
        });
        api.setClienteTelefonoEliminar(id, params).then(function (respuesta) {
          if (respuesta.oData['idClienteDomicilio']) {
            listar2(lista, api);
          }
        });
      }
    }
  });
  $('#submit-tel-cambiar').on({
    click: function click(e) {
      e.preventDefault();
      var id = $(this).attr('href');

      if (id) {
        var params = {};
        $('#boxDomSeleccionTelefono .dato').each(function () {
          var value = $(this).val();

          if ($(this).is('select')) {
            var name = $(this)[0].name;
            params[name] = value;
          }

          if ($(this).is('input')) {
            if ($(this).is(':checkbox')) {
              var _name13 = $(this)[0].name;
              params[_name13] = $(this).is(':checked');
            } else {
              var _name14 = $(this)[0].name;
              params[_name14] = value;
            }
          }

          if ($(this).is(":hidden")) {
            var _name15 = $(this)[0].name;
            params[_name15] = value;
          }
        });
        api.setClienteTelefonoCambio(id, params).then(function (respuesta) {
          if (respuesta.nCode === 200) {
            listar2(lista, api);
          }
        });
      }
    }
  });
  $('.selectpicker').selectpicker();
  $('#boxDomSeleccionTelefono .dato').each(function () {
    $(this).on({
      change: function change() {
        var edit = $('#submit-tel-cambiar');

        if (edit.hasClass('disabled')) {
          edit.removeClass('disabled').css('opacity', 1);
        }
      }
    });
  });
}

function listar(lista, api) {
  api.getClienteInfomacion().then(function (informacion) {
    if (informacion.nCode === 200) {
      if (informacion.oData) {
        var domicilios = informacion.oData.listaClienteDomicilio.length ? informacion.oData.listaClienteDomicilio : [];
        var telefonos = informacion.oData.listaClienteTelefono.length ? informacion.oData.listaClienteTelefono : [];
        var impositiva = informacion.oData.listaClienteInformacionImpositiva.length ? informacion.oData.listaClienteInformacionImpositiva : [];
        var tipoTelefono = {
          1: 'Fijo',
          2: 'Celular'
        };
        var tipoDom = {
          1: 'Particular',
          2: 'Trabajo'
        };
        telefonos.map(function (tel, index) {
          if (tel.idTelefonoTipo) {
            tel['cTipoNombre'] = tipoTelefono[tel.idTelefonoTipo] + ' ' + (index + 1);
          }

          return tel;
        });
        domicilios.map(function (tel, index) {
          if (tel.idDomicilioTipo) {
            tel['cTipoNombre'] = tipoDom[tel.idDomicilioTipo] + ' ' + (index + 1);
          }

          return tel;
        });
        Object.assign(lista, {
          Impositiva: impositiva,
          Telefonos: telefonos,
          Domicilios: domicilios
        });
        var innerSelection = document.querySelector('#entrega');

        if (!tplManager.exist('entregadom')) {
          tplManager.add('entregadom', '/templates/entrega/entregadom.html').then(function (compiler) {
            innerSelection.innerHTML = compiler(lista);
            initSelectoresDomicilioSeleccion(lista, api);
          }).catch(function (e) {
            console.log(e);
          });
        } else {
          var compiler = tplManager.templates['entregadom'];
          innerSelection.innerHTML = compiler(lista);
          initSelectoresDomicilioSeleccion(lista, api);
        }
      }
    }
  });
}

function listar2(lista, api) {
  api.getClienteInfomacion().then(function (informacion) {
    if (informacion.nCode === 200) {
      if (informacion.oData) {
        var domicilios = informacion.oData.listaClienteDomicilio.length ? informacion.oData.listaClienteDomicilio : [];
        var telefonos = informacion.oData.listaClienteTelefono.length ? informacion.oData.listaClienteTelefono : [];
        var impositiva = informacion.oData.listaClienteInformacionImpositiva.length ? informacion.oData.listaClienteInformacionImpositiva : [];
        var tipoTelefono = {
          1: 'Fijo',
          2: 'Celular'
        };
        var tipoDom = {
          1: 'Particular',
          2: 'Trabajo'
        };
        telefonos.map(function (tel, index) {
          if (tel.idTelefonoTipo) {
            tel['cTipoNombre'] = tipoTelefono[tel.idTelefonoTipo] + ' ' + (index + 1);
          }

          return tel;
        });
        domicilios.map(function (tel, index) {
          if (tel.idDomicilioTipo) {
            tel['cTipoNombre'] = tipoDom[tel.idDomicilioTipo] + ' ' + (index + 1);
          }

          return tel;
        });
        Object.assign(lista, {
          Impositiva: impositiva,
          Telefonos: telefonos,
          Domicilios: domicilios
        });
        var innerSelection2 = document.querySelector('#llamado');

        if (!tplManager.exist('llamado')) {
          tplManager.add('llamado', '/templates/entrega/llamado.html').then(function (compiler) {
            innerSelection2.innerHTML = compiler(lista);
            initSelectoresDomicilioSeleccion2(lista, api);
          }).catch(function (e) {
            console.log(e);
          });
        } else {
          var compiler = tplManager.templates['llamado'];
          innerSelection2.innerHTML = compiler(lista);
          initSelectoresDomicilioSeleccion2(lista, api);
        }
      }
    }
  });
}

export default {
  name: 'usuario',
  path: '/usuario/',
  template: '/views/usuario.html',
  props: function props(ctx, api) {
    return new Promise(function (resolve, reject) {
      api.loginStatus().then(function (response) {
        if (response.oCliente !== null) {
          api.getHistory().then(function (response) {
            if (response.oHistorial.nCode === 200) {
              api.getClienteInfomacion().then(function (cliente) {
                if (!is.empty(cliente)) {
                  if (cliente.oData.dFechaNacimiento) {
                    var fecha = cliente.oData.dFechaNacimiento.split('T');
                    fecha = fecha[0].split('-');
                    cliente.oData.MesCliente = fecha[1];
                    cliente.oData.diaCliente = fecha[2];
                    cliente.oData.anioCliente = fecha[0];
                  }

                  cliente['oHistorial'] = response.oHistorial;
                  var procesos = Object.keys(response.oHistorial.oData.Procesos);
                  procesos.forEach(function (key) {
                    var obj = response.oHistorial.oData.Procesos[key];

                    if (!obj.hasOwnProperty('DescripcionGeneral')) {
                      obj['DescripcionGeneral'] = {};
                    } // Tickets


                    if (obj.Tickets.length) {
                      var {
                        cNombre,
                        cNombre1,
                        dFuncion,
                        cJsonImagenes,
                        cJsonListaImagen,
                        cEtiqueta,
                        idEspectaculoTipo
                      } = obj.Tickets[0];
                      obj.DescripcionGeneral.cNombre = cNombre;
                      obj.DescripcionGeneral.cNombreEstablecimiento = cNombre1;
                      obj.DescripcionGeneral.cJsonImagenes = JSON.parse(cJsonImagenes);
                      obj.DescripcionGeneral.nCantitadTotalTickets = obj.Tickets.length;
                      obj.DescripcionGeneral.idEspectaculoTipo = idEspectaculoTipo;
                      obj.DescripcionGeneral.cJsonListaImagen = cJsonListaImagen;
                      obj.DescripcionGeneral.cFuncionEtiqueta = cEtiqueta;

                      var _splited = dFuncion.split('T');

                      var [_anio, _mes, _dia] = _splited[0].split('-');

                      var [_hora, _min] = _splited[1].split(':');

                      obj.DescripcionGeneral.dFuncion = "".concat(_dia, " de ").concat(MES[parseInt(_mes)], " del ").concat(_anio, " ").concat(_hora, ":").concat(_min, "hs");
                    } // Entrega


                    var {
                      idOperacion,
                      dAlta,
                      bPagoConfirmado,
                      bDevuelta,
                      cCodigo
                    } = obj.Entrega[0];
                    var splited = dAlta.split('T');
                    var [anio, mes, dia] = splited[0].split('-');
                    var [hora, min] = splited[1].split(':');
                    obj.DescripcionGeneral.dCompra = "".concat(dia, " de ").concat(MES[parseInt(mes)], " del ").concat(anio, " ").concat(hora, ":").concat(min, "hs");
                    obj.DescripcionGeneral.cEstadoOperacion = bPagoConfirmado ? "Pagada ".concat(bDevuelta ? "- devuelta" : "") : 'Sin Confirmar';
                    obj.DescripcionGeneral.codOperacion = idOperacion;
                    obj.DescripcionGeneral.codOperacionFactura = cCodigo;
                  });
                  cliente['Informacion'] = {};
                  ctx['Informacion'] = {};
                  oCliente = cliente.oData;
                  var domicilios = cliente.oData.listaClienteDomicilio.length ? cliente.oData.listaClienteDomicilio : [];
                  var telefonos = cliente.oData.listaClienteTelefono.length ? cliente.oData.listaClienteTelefono : [];
                  var impositiva = cliente.oData.listaClienteInformacionImpositiva.length ? cliente.oData.listaClienteInformacionImpositiva : [];
                  var tipoTelefono = {
                    1: 'Fijo',
                    2: 'Celular'
                  };
                  var tipoDom = {
                    1: 'Particular',
                    2: 'Trabajo'
                  };
                  telefonos.map(function (tel, index) {
                    if (tel.idTelefonoTipo) {
                      tel['cTipoNombre'] = tipoTelefono[tel.idTelefonoTipo] + ' ' + (index + 1);
                    }

                    return tel;
                  });
                  domicilios.map(function (tel, index) {
                    if (tel.idDomicilioTipo) {
                      tel['cTipoNombre'] = tipoDom[tel.idDomicilioTipo] + ' ' + (index + 1);
                    }

                    return tel;
                  });
                  Object.assign(cliente['Informacion'], {
                    Impositiva: impositiva,
                    Telefonos: telefonos,
                    Domicilios: domicilios
                  });
                  Object.assign(ctx['Informacion'], {
                    Impositiva: impositiva,
                    Telefonos: telefonos,
                    Domicilios: domicilios
                  });
                  console.info(cliente);
                  resolve(cliente);
                } else {
                  resolve({});
                }
              });
            }
          }).catch(function (err) {
            reject(err);
          });
        } else {
          resolve({});
        }
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  enter: function enter(ctx, next, mounted, api) {
    scrolltop();

    if (!tplManager) {
      tplManager = new TemplateManager(api.$root);
    }

    if (!api.token) {
      window.location = api.$root;
    }

    var container = document.querySelector('#app');
    container.innerHTML = mounted.compiled;
    var cambioForm = $('#cambio-datos');
    cambioForm.validate({
      rules: {
        cNombre: 'required',
        cApellido: 'required',
        cGenero: 'required',
        cDocumento: 'required',
        idDocumentoTipo: 'required',
        idClienteGenero: 'required',
        cEmail: {
          required: true,
          email: true
        },
        nNacimientoDia: 'required',
        nNacimientoMes: 'required',
        nNacimientoAnio: 'required'
      },
      messages: {
        cNombre: '',
        cApellido: '',
        cGenero: '',
        cDocumento: '',
        idDocumentoTipo: '',
        idClienteGenero: '',
        cEmail: {
          required: '',
          email: ''
        },
        nNacimientoDia: '',
        nNacimientoMes: '',
        nNacimientoAnio: ''
      }
    });
    var SelcVencimientoDia1 = cambioForm.find('#nNacimientoDia'),
        SelcVencimientoMes1 = cambioForm.find('#nNacimientoMes'),
        SelcVencimientoAnio1 = cambioForm.find('#nNacimientoAnio');
    var SelcGenero = $('[name="idClienteGenero"]');
    var SelcidDocumentoTipo = $('[name="idDocumentoTipo"]');
    SelcVencimientoDia1.on({
      change: function change() {
        var bool = $(this).val();
        var ss = $('[data-id="diau"]');

        if (bool) {
          ss.removeClass('error');
        } else {
          ss.addClass('error');
        }
      }
    });
    SelcVencimientoMes1.on({
      change: function change() {
        var bool = $(this).val();
        var ss = $('[data-id="mesu"]');

        if (bool) {
          ss.removeClass('error');
        } else {
          ss.addClass('error');
        }
      }
    });
    SelcVencimientoAnio1.on({
      change: function change() {
        var bool = $(this).val();
        var ss = $('[data-id="aniou"]');

        if (bool) {
          ss.removeClass('error');
        } else {
          ss.addClass('error');
        }
      }
    });
    SelcGenero.on({
      change: function change() {
        var bool = $(this).val();
        var ss = $('[data-id="idClienteGenero"]');

        if (bool) {
          ss.removeClass('error');
        } else {
          ss.addClass('error');
        }
      }
    });
    SelcidDocumentoTipo.on({
      change: function change() {
        var bool = $(this).val();
        var ss = $('[data-id="idDocumentoTipo"]');

        if (bool) {
          ss.removeClass('error');
        } else {
          ss.addClass('error');
        }
      }
    });
    $('#vista-usuario .selectpicker').selectpicker();
    var submitCambios = $('#cambiDatosBoton');
    submitCambios.on({
      click: function click(e) {
        if ($('#cambio-datos').valid()) {
          submitCambios[0].classList.add('load');
          submitCambios[0].disabled = true;
          $('#leyendaCambio').html('');
          var objRegistro = getFormObjectParams(cambioForm[0]); // let newOb = $.extend({}, oCliente, objRegistro)

          api.setCliente(objRegistro).then(function (res) {
            if (res.nCode === 200) {
              $('#leyendaCambio').addClass('exito').html("".concat(res.oData));
            } else {
              $('#leyendaCambio').removeClass('exito').html("".concat(res.oData));
            }
          }).finally(function () {
            submitCambios[0].classList.remove('load');
            submitCambios[0].disabled = false;
          });
        } else {
          if (!SelcGenero.valid()) {
            var ss0 = $('[data-id="idClienteGenero"]');
            ss0.addClass('error');
          }

          if (!SelcidDocumentoTipo.valid()) {
            var ss10 = $('[data-id="idDocumentoTipo"]');
            ss10.addClass('error');
          }

          if (!SelcVencimientoDia1.valid()) {
            var ss = $('[data-id="nNacimientoDia"]');
            ss.addClass('error');
          }

          if (!SelcVencimientoMes1.valid()) {
            var ss1 = $('[data-id="nNacimientoMes"]');
            ss1.addClass('error');
          }

          if (!SelcVencimientoAnio1.valid()) {
            var ss2 = $('[data-id="nNacimientoAnio"]');
            ss2.addClass('error');
          }
        }
      }
    });
    $('#cambio-datos-clave').validate({
      rules: {
        cClaveAnterior: {
          required: true,
          minlength: 6,
          maxlength: 20
        },
        cClaveNueva: {
          required: true,
          minlength: 6,
          maxlength: 20
        }
      },
      messages: {
        cClaveAnterior: {
          required: '',
          minlength: '',
          maxlength: ''
        },
        cClaveNueva: {
          required: '',
          minlength: '',
          maxlength: ''
        }
      }
    });
    $('#cambiDatosBotonClave').on({
      click: function click(e) {
        if ($('#cambio-datos-clave').valid()) {
          $('#leyendaCambioClave').html('');
          var obj = {
            cClaveAnterior: $('[name="cClaveAnterior"]').val(),
            cClaveNueva: $('[name="cClaveNueva"]').val()
          };
          api.setClaveCliente(obj).then(function (res) {
            if (res.nCode === 200) {
              $('#leyendaCambioClave').addClass('exito').html("".concat(res.oData));
            } else {
              $('#leyendaCambioClave').removeClass('exito').html("".concat(res.oData));
            }
          });
        }
      }
    });
    var loading = document.querySelector('.showbox');
    initSelectoresDomicilioSeleccion(ctx['Informacion'], api);
    initSelectoresDomicilioSeleccion2(ctx['Informacion'], api);

    if (loading.classList.contains('is-visible')) {
      loading.classList.remove('is-visible');
      one(loading, transitionEnd, function () {
        loading.classList.add('is-hidden');
      });
    }
  }
};