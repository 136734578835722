import scrolltop from '../../utils/scrolltop';
import { one } from '../../utils/events';
import isMobile from '../../utils/isMobile';
import { transitionEnd } from 'cssevents';
var TIPOS = true;
export default {
  name: 'espectaculo',
  path: '/espectaculo/:id',
  template: '/views/espectaculo.html',
  props: function props(ctx, api) {
    var id = ctx.params.id;
    ctx.gmaps = [];
    var query = '';

    if (ctx.querystring) {
      query = "?".concat(ctx.querystring);
    }

    return new Promise(function (resolve, reject) {
      api.getEspectaculoCartel(id, query).then(function (response) {
        if (response.nCode === 200) {
          api.prevView = "/espectaculo/".concat(id).concat(query);
          response.oData['Imagenes'] = {};
          response.oData.oEspectaculoCartel.listaImagenes.forEach(function (item) {
            item.listaEtiquetas.forEach(function (etiqueta) {
              response.oData['Imagenes'][etiqueta] = item.cUri;
            });
          });
          response.oData['Length'] = response.oData.oEspectaculoCartel.listaEstablecimiento.length;

          if (response.oData.oEspectaculoCartel.cNombre.toLowerCase().indexOf('museo') > -1 || response.oData.oEspectaculoCartel.cNombre.toLowerCase().indexOf('teleférico') > -1) {
            response.oData['EtiquetaTipoEntrada'] = "Entradas";
          } else {
            response.oData['EtiquetaTipoEntrada'] = "Funciones";
          }

          if (TIPOS) {
            api.getEspectaculoCartelTipos().then(function (res) {
              if (res.nCode === 200) {
                response.oData['CartelTipos'] = res.oData.Lista;
                ctx['CartelTipos'] = res.oData.Lista;
                resolve(response.oData);
              }
            }).catch(function (err) {
              reject(err);
            });
          } else {
            resolve(response.oData);
          }
        } else {
          resolve({});
        }
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  enter: function enter(ctx, next, mounted) {
    scrolltop();
    var container = document.querySelector('#app');
    container.innerHTML = mounted.compiled;
    var date = $('.selectpicker');

    if (api.redirectPreV) {
      $('.btn-cancel').attr('href', "".concat(api.redirectPreV ? api.redirectPreV : api.apiRedirectTo));
    }

    if ($('[name="idFuncion"]').length > 0) {
      var confirm = document.querySelector('#boton-seleccion-fecha');
      confirm.disabled = true;
      date.selectpicker({
        width: '100%',
        mobile: !!isMobile.any()
      });
      date.on({
        change: function change() {
          var value = parseInt($(this).val());
          var rel = $(this).find('option:selected').attr('rel');

          if (value > 0) {
            confirm.setAttribute('href', "/seleccion/".concat(rel));
            confirm.disabled = false;
          } else {
            confirm.disabled = true;
          }
        }
      });
      var items = document.querySelectorAll('.item-list-group');

      if (!isMobile.any()) {
        items.forEach(function (item) {
          item.addEventListener('mouseenter', function () {
            var src = this.getAttribute('data-href');

            if (src) {
              document.querySelector('iframe').src = src;
            }
          });
        });
      } else {
        items.forEach(function (item) {
          item.addEventListener('touchstart', function () {
            this.querySelector('a').click();
          });
        });
      }

      $(".button-to").click(function () {
        $('html, body').animate({
          scrollTop: $("footer").offset().top
        }, 800);
      });
    }

    var loading = document.querySelector('.showbox');
    loading.classList.remove('is-visible');
    loading.classList.add('is-hidden');
  }
};