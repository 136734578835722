import 'es5-shim';
import 'bootstrap';
import 'bootstrap-select';
import 'jquery-validation';
import PageManager from './app/core/RouterManager';
import API from './app/core/ApiManager';
import header from './modules/shared/header';
import footer from './modules/shared/footer';
import login from './modules/shared/login';
import registro from './modules/shared/registro';
import confirmacion from './app/routes/confirmacion';
import proceso from './app/routes/proceso';
import seleccion from './app/routes/seleccion';
import home from './app/routes/home';
import venue from './app/routes/venue';
import espectaculo from './app/routes/espectaculo';
import resultado from './app/routes/resultado';
import usuario from './app/routes/usuario';
import logout from './app/routes/logout';
import notFound from './app/routes/notfound';
import './utils/helpers';
export default (function () {
  jQuery.isClickEventRequestingNewTab = function (clickEvent) {
    return clickEvent.metaKey || clickEvent.ctrlKey || clickEvent.which === 2;
  };

  $(document).on('mousedown', 'a', function (e) {
    if (this.host !== window.location.host) {
      return true;
    } else {
      if (e.which === 2) {
        window.open("/#!".concat($(this).attr('href')));
        return false;
      } else {}

      if (e.which === 2 && e.ctrlKey) {
        window.open("/#!".concat($(this).attr('href')));
        return false;
      } else {}
    }

    return true;
  });
  $(document).on('contextmenu', 'a', function (e) {
    e.preventDefault();
  });
  return {
    init: function init(eujsconfig) {
      var api = new API(eujsconfig);
      var router = new PageManager(eujsconfig, api);
      var _wasPageCleanedUp = false;

      function pageCleanup() {
        if (!_wasPageCleanedUp) {
          var ppid = window.localStorage.getItem('p_pid');

          if (!ppid) {
            window.localStorage.setItem('p_pid', '[]');
          } else {
            api.ppid = JSON.parse(ppid);

            if (api.ppid.length) {
              api.ppid.forEach(function (p) {
                navigator.sendBeacon("".concat(api.$apiUrl, "/Proceso/").concat(p, "/Invalidar"));
              });
            }

            api.ppid = [];
            window.localStorage.setItem('p_pid', '[]');
            _wasPageCleanedUp = true;
          }
        }
      }

      $(window).on('beforeunload', function () {
        // this will work only for Chrome
        pageCleanup();
      });
      $(window).on("unload", function () {
        //this will work for other browsers
        pageCleanup();
      });
      var modules = [header(api), footer(api), login(api), registro(api)];
      Promise.all(modules).then(function (values) {
        router.route(logout);
        router.route(usuario);
        router.route(resultado);
        router.route(seleccion);
        router.route(proceso);
        router.route(confirmacion);
        router.route(home);
        router.route(venue);
        router.route(espectaculo);
        router.route(notFound);
        router.init();
        window['api'] = api;
      });
    }
  };
})();