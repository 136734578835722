import Observable from '../../utils/observable';
import EventEmitter from '../../app/core/EventEmitter';
export default function () {
  var evt = new EventEmitter();
  var store = new Observable();
  store.data = {
    idFormaDePago: 0,
    idFormaDePagoConfiguracionTarjeta: 0,
    idCuota: 0
  };
  store.complete = false;
  store.add(function (obj) {
    Object.assign(this.data, obj);

    if (store.data.idFormaDePago > 0 && store.data.idFormaDePagoConfiguracionTarjeta > 0 && store.data.idCuota > 0) {
      evt.fire('oPagoChangedSelection', store.data);
    } else {}
  });
  return {
    on: function on(fn) {
      evt.on('oPagoChangedSelection', fn);
    },
    set: function set(obj) {
      store.notify(obj);
    }
  };
}