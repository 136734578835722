import Observable from '../../utils/observable';
import EventEmitter from '../../app/core/EventEmitter';
export default function () {
  var evt = new EventEmitter();
  var store = new Observable();
  store.data = {
    idEntregaTipo: 0,
    idEntregaEntidad: 0,
    idClienteDomicilio: 0,
    idClienteTelefono: 0,
    cObservaciones: 0
  };
  store.complete = false;
  store.add(function (obj) {
    this.data = Object.assign({}, this.data, obj);

    if (store.data['requireDomicilio']) {
      delete store.data['requireDomicilio'];
      evt.fire('oEnvioChangedSelection', store.data);
    }

    evt.fire('oEnvioChangedSelection', store.data);
  });
  return {
    on: function on(fn) {
      evt.on('oEnvioChangedSelection', fn);
    },
    set: function set(obj) {
      store.notify(obj);
    }
  };
}