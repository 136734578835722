var submit = document.querySelector('#registro-boton');
var form = document.querySelector('[name="registro"]');

var getFormObjectParams = function getFormObjectParams(form) {
  var formElements = form.elements;
  var obj = {};

  for (var i = 0; i < formElements.length; i++) {
    if (formElements[i].name !== '') {
      obj[formElements[i].name] = formElements[i].value;
    }
  }

  delete obj['cEmailConfirm'];
  delete obj['cClaveConfirm'];
  return obj;
};

export default (function (api) {
  $('#registro-form').on('submit', function (e) {
    return e.preventDefault();
  });
  $('#registro-form').validate({
    rules: {
      cNombre: 'required',
      cApellido: 'required',
      idClienteGenero: 'required',
      idDocumentoTipo: 'required',
      idTelefonoTipo: 'required',
      cDocumento: 'required',
      cTelefonoNumero: 'required',
      cEmail: {
        required: true,
        email: true,
        minlength: 6
      },
      cEmailConfirm: {
        required: true,
        email: true,
        equalTo: '#cEmail'
      },
      cClave: {
        required: true,
        minlength: 6,
        maxlength: 20
      },
      cClaveConfirm: {
        required: true,
        equalTo: '#cClave'
      },
      nNacimientoDia: 'required',
      nNacimientoMes: 'required',
      nNacimientoAnio: 'required'
    },
    messages: {
      cNombre: '',
      cApellido: '',
      idClienteGenero: '',
      idDocumentoTipo: '',
      idTelefonoTipo: '',
      cTelefonoNumero: '',
      cDocumento: '',
      cEmail: {
        required: '',
        email: ''
      },
      cClave: {
        required: '',
        minlength: 'debe contener un minimo de 6 caracteres',
        maxlength: 'debe contener un maximo de 20 caracteres'
      },
      cClaveConfirm: {
        required: '',
        equalTo: ''
      },
      cEmailConfirm: {
        required: '',
        email: '',
        equalTo: ''
      },
      nNacimientoDia: '',
      nNacimientoMes: '',
      nNacimientoAnio: ''
    }
  });
  var SelcGenero = $('[name="idClienteGenero"]');
  var SelcidDocumentoTipo = $('[name="idDocumentoTipo"]');
  var SelTelefonoTipo = $('[name="idTelefonoTipo"]');
  var SelcVencimientoDia = $('[name="nNacimientoDia"]'),
      SelcVencimientoMes = $('[name="nNacimientoMes"]'),
      SelcVencimientoAnio = $('[name="nNacimientoAnio"]');

  for (var i = 2018; i >= 1900; i--) {
    $('<option>').val(i).text(i).appendTo(SelcVencimientoAnio);
  }

  for (var _i = 1; _i <= 31; _i++) {
    $('<option>').val(_i).text(_i).appendTo(SelcVencimientoDia);
  }

  SelcGenero.on({
    change: function change() {
      var bool = $(this).val();
      var ss = $('[data-id="idClienteGenero"]');

      if (bool) {
        ss.removeClass('error');
      } else {
        ss.addClass('error');
      }
    }
  });
  SelcidDocumentoTipo.on({
    change: function change() {
      var bool = $(this).val();
      var ss = $('[data-id="idDocumentoTipo"]');

      if (bool) {
        ss.removeClass('error');
      } else {
        ss.addClass('error');
      }
    }
  });
  SelTelefonoTipo.on({
    change: function change() {
      var bool = $(this).val();
      var ss = $('[data-id="idTelefonoTipo"]');

      if (bool) {
        ss.removeClass('error');
      } else {
        ss.addClass('error');
      }
    }
  });
  SelcVencimientoDia.on({
    change: function change() {
      var bool = $(this).val();
      var ss = $('[data-id="nNacimientoDia"]');

      if (bool) {
        ss.removeClass('error');
      } else {
        ss.addClass('error');
      }
    }
  });
  SelcVencimientoMes.on({
    change: function change() {
      var bool = $(this).val();
      var ss = $('[data-id="nNacimientoMes"]');

      if (bool) {
        ss.removeClass('error');
      } else {
        ss.addClass('error');
      }
    }
  });
  SelcVencimientoAnio.on({
    change: function change() {
      var bool = $(this).val();
      var ss = $('[data-id="nNacimientoAnio"]');

      if (bool) {
        ss.removeClass('error');
      } else {
        ss.addClass('error');
      }
    }
  });
  $('.selectpicker').selectpicker('refresh');
  $('.check-news').on({
    change: function change() {
      if ($(this).is(':checked')) {
        $(this).val(false);
      } else {
        $(this).val(true);
      }
    }
  });
  return new Promise(function (resolve) {
    submit.addEventListener('click', function (e) {
      e.preventDefault();

      if ($('#registro-form').valid()) {
        submit.classList.add('load');
        submit.disabled = true;
        var objRegistro = getFormObjectParams(form);
        api.registerNewUser(objRegistro).then(function (response) {
          $('#respuesta-registro').css('display', 'none');

          if (response.nCode === 200) {
            $('#respuesta-registro').css({
              color: "#3881ee"
            }).html(response.oData).css('display', 'block');
            setTimeout(function () {
              $('#registro').modal('hide');
              $('#ingreso').modal('show');
            }, 5000);
          } else {
            submit.classList.remove('load');
            submit.disabled = false;
            $('#respuesta-registro').css({
              color: "#E14"
            }).html(response.oData).css('display', 'block');
          }
        }).catch(function (err) {
          if (err) console.log(err);
        }).finally(function () {
          submit.classList.remove('load');
          submit.disabled = false;
        });
      } else {
        if (!SelcGenero.valid()) {
          var ss0 = $('[data-id="idClienteGenero"]');
          ss0.addClass('error');
        }

        if (!SelTelefonoTipo.valid()) {
          var ss20 = $('[data-id="idTelefonoTipo"]');
          ss20.addClass('error');
        }

        if (!SelcidDocumentoTipo.valid()) {
          var ss10 = $('[data-id="idDocumentoTipo"]');
          ss10.addClass('error');
        }

        if (!SelcVencimientoDia.valid()) {
          var ss = $('[data-id="nNacimientoDia"]');
          ss.addClass('error');
        }

        if (!SelcVencimientoMes.valid()) {
          var ss1 = $('[data-id="nNacimientoMes"]');
          ss1.addClass('error');
        }

        if (!SelcVencimientoAnio.valid()) {
          var ss2 = $('[data-id="nNacimientoAnio"]');
          ss2.addClass('error');
        }
      }
    });
    resolve();
  });
});