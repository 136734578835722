export default (function (api) {
  return new Promise(function (resolve) {
    $('#newLetterForm').validate({
      rules: {
        cMailNewsLetter: {
          required: true,
          email: true
        },
        cNombreNewsLetter: {
          required: true
        }
      },
      messages: {
        cMailNewsLetter: {
          required: '',
          email: ''
        },
        cNombreNewsLetter: {
          required: ''
        }
      }
    });
    $('#newLetterFormButton').on({
      click: function click(e) {
        e.preventDefault();
        $('#newLetterFormButton').addClass('load');
        var rec = $('#newLetterForm');
        var mail = rec.find('[name="cMailNewsLetter"]'),
            nom = rec.find('[name="cNombreNewsLetter"]');

        if (rec.valid()) {
          $('#newLetterFormButton').prop('disabled', true);
          var obj = {
            "cMail": mail.val(),
            "cNombre": nom.val()
          };
          api.setNewsletter(obj).then(function (res) {
            if (res.oData) {
              $('#leyendaNews').html(res.oData.oMail.oData);
              setTimeout(function () {
                $('#leyendaNews').html('');
                $('#newLetterForm').css('display', 'none');
              }, 3000);
              mail.val('');
              nom.val('');
            } else {
              $('#newLetterFormButton').prop('disabled', false);
              $('#leyendaNews').html('Algo paso, no se puedo realizar la suscripcion');
            }

            $('#newLetterFormButton').removeClass('load');
          });
        } else {
          $('#leyendaNews').html('');
          $('#newLetterFormButton').removeClass('load');
        }
      }
    });
    $('#contacto-form').validate({
      rules: {
        cEmail: {
          required: true,
          email: true
        },
        cNombre: {
          required: true
        },
        cComentario: {
          required: true
        }
      },
      messages: {
        cEmail: {
          required: '',
          email: ''
        },
        cNombre: {
          required: ''
        },
        cComentario: {
          required: ''
        }
      }
    });
    $('#contact-btn').on({
      click: function click(e) {
        e.preventDefault();
        var con = $('#contacto-form');
        var cEmail = con.find('[name="cEmail"]'),
            cNombre = con.find('[name="cNombre"]'),
            cComentario = con.find('[name="cComentario"]'),
            cTelefono = con.find('[name="cTelefono"]');

        if (con.valid()) {
          var Mensaje = cComentario.val();

          if (cTelefono.val().length > 0) {
            Mensaje += ' Nro Telefono:' + cTelefono.val();
          }

          api.setContactoMensaje({
            "cMail": cEmail.val(),
            "cNombre": cNombre.val(),
            "cMensaje": Mensaje,
            "cTipo": 'Web'
          }).then(function (res) {
            if (res.nCode === 200) {
              con[0].reset();
              $('#contactoNews').html('Gracias! Hemos recibido sus datos').addClass('exito');
            } else {
              $('#contactoNews').html('Error! El envio no se realizo con correctamente').removeClass('exito');
            }
          });
        }
      }
    });
    resolve({});
  });
});