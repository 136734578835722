import scrolltop from '../../utils/scrolltop';
export default {
  name: 'salir',
  path: '/salir',
  template: '/views/logout.html',
  props: function props(ctx, api) {
    return new Promise(function (resolve, reject) {
      api.logout().then(function () {
        window.localStorage.removeItem('client');
        window.location = api.apiRedirectTo;
      }).catch(function (err) {
        reject(err);
      });
      resolve();
    });
  },
  enter: function enter(ctx, next, mounted, api) {
    scrolltop();
    var container = document.querySelector('#app');
    container.innerHTML = mounted.compiled;
  }
};