import ripple from '../../utils/ripple';
import debounce from '../../utils/debounce';
import normalize from '../../utils/normalize';
import Swiper from 'swiper';
import { one } from '../../utils/events';
import { transitionEnd } from 'cssevents';
import scrolltop from '../../utils/scrolltop';
import isMobile from '../../utils/isMobile';
var TIPOS = true;
export default {
  name: 'venue',
  path: '/venue',
  template: '/views/venue.html',
  props: function props(ctx, api) {
    api.redirectPreV = '';
    return new Promise(function (resolve, reject) {
      api.getEspectaculosCartel().then(function (response) {
        if (response.nCode === 200) {
          var odata = {};
          odata['WebDestacado'] = [];
          odata['WebCarrousel'] = [];
          odata['WebList'] = [];
          response.oData.listaEspectaculoCartel.filter(function (e) {
            return e.idEspectaculoTipo !== 5 && e.idEspectaculoCartel !== 11106 && e.idEspectaculoCartel !== 11241;
          }).forEach(function (cartel) {
            cartel['Imagenes'] = {};
            cartel.listaImagenes.forEach(function (item) {
              item.listaEtiquetas.forEach(function (etiqueta) {
                cartel['Imagenes'][etiqueta] = item.cUri;
              });
            });
            cartel.listaEtiquetas.forEach(function (etiqueta) {
              if (etiqueta === 'WEB_DESTACADO') {
                // Aprobada la web sacar el if
                if (cartel.idEspectaculoCartel === 84 || cartel.idEspectaculoCartel === 85 || cartel.idEspectaculoCartel === 87 || cartel.idEspectaculoCartel === 88 || cartel.idEspectaculoCartel === 153) {
                  odata['WebDestacado'].push(cartel);
                } // odata['WebDestacado'].push(cartel)
                // console.info(cartel.cNombre, cartel.idEspectaculoCartel, etiqueta === 'WEB_DESTACADO')

              }

              if (etiqueta === 'WEB_CARRUSEL') {
                odata['WebCarrousel'].push(cartel); // console.info(cartel.cNombre, cartel.idEspectaculoCartel, etiqueta === 'WEB_CARRUSEL')
              }
            });
            odata['WebList'].push(cartel);

            if (cartel === response.oData.listaEspectaculoCartel[response.oData.listaEspectaculoCartel.length - 1]) {
              // if (isMobile.any()) {
              //   delete odata['WebDestacado']
              // }
              if (TIPOS) {
                api.getEspectaculoCartelTipos().then(function (res) {
                  if (res.nCode === 200) {
                    function filterForNames(elemento) {
                      return elemento.cNombre !== 'DEPORTES' && elemento.cNombre !== 'AUTOCINE';
                    }

                    var filterTipos = res.oData.Lista.filter(filterForNames);
                    filterTipos = filterTipos.map(function (item, index) {
                      if (item.cNombre === 'ATRACCIONES ') {
                        item.cNombre = 'MUSEOS';
                      }

                      return item;
                    });
                    odata['CartelTipos'] = filterTipos;
                    ctx['CartelTipos'] = filterTipos;
                  }

                  if (response.nCode === 200) {
                    resolve(odata);
                  } else {
                    resolve({});
                  }
                }).catch(function (err) {
                  reject(err);
                });
              } else {
                resolve(odata);
              }
            }
          });
        }
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  enter: function enter(ctx, next, mounted) {
    scrolltop();
    var container = document.querySelector('#app');
    container.innerHTML = mounted.compiled;
    normalize.heights('.caption');
    window.addEventListener('resize', debounce(function () {
      normalize.heights('.caption');
    }, 250));

    if (isMobile.any()) {
      $('#slider').addClass('mobile');
      $('body').addClass('mobile');
    }

    new Swiper('#slider-main-home', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 3000
      },
      speed: 800,
      breakpoints: {
        768: {
          slidesPerView: 1
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      on: {
        init: function init() {
          container.querySelectorAll('.rp').forEach(function (slide) {
            slide.addEventListener('click', function (e) {
              // e.preventDefault()
              ripple(slide, {
                type: 'center',
                bgc: 'rgba(255, 255, 255, .5)'
              });
            });
          });
        }
      }
    });
    var c = {
      slidesPerView: 5,
      spaceBetween: 5,
      navigation: {
        nextEl: '.swiper-button-next-1',
        prevEl: '.swiper-button-prev-1'
      },
      breakpoints: {
        768: {
          slidesPerView: 3
        }
      }
    };

    if (isMobile.any()) {// c = Object.assign(c, { slidesPerColumn: 2 })
    }

    new Swiper('#slider-destacado', c);
    var loading = document.querySelector('.showbox');

    if (loading.classList.contains('is-visible')) {
      loading.classList.remove('is-visible');
      one(loading, transitionEnd, function () {
        loading.classList.add('is-hidden');
      });
    }
  }
};