import ripple from '../../utils/ripple';
import debounce from '../../utils/debounce';
import normalize from '../../utils/normalize';
import Swiper from 'swiper';
import { one } from '../../utils/events';
import { transitionEnd } from 'cssevents';
import scrolltop from '../../utils/scrolltop';

function gup(name, url) {
  name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  if (results === null) return '';else return results[1];
}

export default {
  name: 'resultado',
  path: '/resultado',
  template: '/views/resultado.html',
  props: function props(ctx, api) {
    return new Promise(function (resolve, reject) {
      var params = {};
      var qNombre = gup('busqueda', ctx.path);
      var qCategoria = gup('categoria', ctx.path);

      if (qNombre.length > 0) {
        params['Nombre'] = qNombre;
      }

      if (qCategoria.length > 0) {
        params['idEspectaculoTipo'] = qCategoria;
      }

      api.redirectPreV = ctx.path;
      api.getEspectaculosCartel(params).then(function (response) {
        response.oData['Query'] = qNombre;
        var Destacados = response.oData.Lista.filter(function (item) {
          // if (item.Marquesina.bCanalWebDestacado === true) {
          return item; // }
        });
        var Slider = response.oData.Lista.filter(function (item) {
          if (item.Marquesina.bCanalWebCarrousel === true) {
            return item;
          }
        });
        api.getEspectaculoCartelTipos().then(function (res) {
          if (res.nCode === 200) {
            function filterForNames(elemento) {
              return elemento.cNombre !== 'DEPORTES' && elemento.cNombre !== 'AUTOCINE';
            }

            var filterTipos = res.oData.Lista.filter(filterForNames);
            filterTipos = filterTipos.map(function (item, index) {
              if (item.cNombre === 'ATRACCIONES ') {
                item.cNombre = 'MUSEOS';
              }

              return item;
            });
            response.oData['CartelTipos'] = filterTipos;
            ctx['CartelTipos'] = filterTipos;
            var i = 0;

            for (; i < res.oData.Lista.length; i++) {
              var item = res.oData.Lista[i];

              if (item.idEspectaculoTipo === parseInt(qCategoria)) {
                response.oData['Query'] = item.cNombre;
                break;
              }
            }
          }

          if (response.nCode === 200) {
            response.oData['WebDestacado'] = Destacados;
            response.oData['WebCarrousel'] = Slider;
            response.oData['Length'] = Destacados.length;
            resolve(response.oData); // console.log(response.oData)
          } else {
            resolve({});
          }
        }).catch(function (err) {
          reject(err);
        });
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  enter: function enter(ctx, next, mounted) {
    scrolltop();
    var container = document.querySelector('#app');
    container.innerHTML = mounted.compiled;
    normalize.heights('.caption');
    window.addEventListener('resize', debounce(function () {
      normalize.heights('.caption');
    }, 250));
    var slideeLength = document.querySelectorAll('.slidee').length;
    new Swiper('.swiper-container', {
      loop: slideeLength > 1,
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 3000
      },
      speed: 800,
      breakpoints: {
        768: {
          slidesPerView: 1
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      on: {
        init: function init() {
          container.querySelectorAll('.rp').forEach(function (slide) {
            slide.addEventListener('click', function (e) {
              // e.preventDefault()
              ripple(slide, {
                type: 'center',
                bgc: 'rgba(255, 255, 255, .5)'
              });
            });
          });
        }
      }
    });
    var loading = document.querySelector('.showbox');

    if (loading.classList.contains('is-visible')) {
      loading.classList.remove('is-visible');
      one(loading, transitionEnd, function () {
        loading.classList.add('is-hidden');
      });
    }
  }
};