import { transitionEnd } from 'cssevents';
import { one } from './events';
import is from 'is';
export default (function (e, argOpts, cb) {
  var opts = Object.assign({
    ele: e.target || e,
    type: 'hit',
    bgc: 'rgba(0, 0, 0, 0.15)'
  }, argOpts);
  var target = opts.ele;

  if (target) {
    var rect = target.getBoundingClientRect();
    var ripple = target.querySelector('.e-ripple');

    if (!ripple) {
      ripple = document.createElement('span');
      ripple.className = 'e-ripple';
      ripple.style.height = ripple.style.width = Math.max(rect.width, rect.height) + 'px';
      target.appendChild(ripple);
    } else {
      ripple.className = 'e-ripple';
    }

    switch (opts.type) {
      case 'center':
        ripple.style.top = rect.height / 2 - ripple.offsetHeight / 2 + 'px';
        ripple.style.left = rect.width / 2 - ripple.offsetWidth / 2 + 'px';
        break;

      default:
        ripple.style.top = (e.pageY || e.targetTouches[0].pageY) - rect.top - ripple.offsetHeight / 2 - document.body.scrollTop + 'px';
        ripple.style.left = (e.pageX || e.targetTouches[0].pageX) - rect.left - ripple.offsetWidth / 2 - document.body.scrollLeft + 'px';
    }

    ripple.style.backgroundColor = opts.bgc;
    ripple.className = 'e-ripple z-active';

    if (cb && is.function(cb)) {
      one(ripple, transitionEnd, function () {
        cb(target);
      });
    }

    return false;
  }
});