function Observable() {
  var _this = this;

  var self = this;
  this.data = {};
  this.observers = [];

  this.add = function (observer) {
    _this.observers.push(observer);
  };

  this.notify = function (obj) {
    _this.observers.forEach(function (observer) {
      observer.call(self, obj);
    });
  };

  this.remove = function (key) {
    if (self.data.hasOwnProperty(key)) {
      delete self.data[key];
    }
  };
}

export { Observable as default };