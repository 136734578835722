import is from 'is';
import isMobile from '../../utils/isMobile';
import { transitionEnd } from 'cssevents';
import { one } from '../../utils/events';
import TemplateManager from '../core/TemplateManager';
import Observable from '../../utils/observable';
import SVG from 'svg.js';
import scrolltop from '../../utils/scrolltop';
import Tooltip from 'tooltip.js';
var MOBILE = !!isMobile.any();
var tplManager = null;
var observable = new Observable();
var observableObjectCambio = new Observable();
var observablePermisso = new Observable();
var TipoValidacion = [{
  "idpromociontipovalidacion": 0,
  "cnombre": "Sin Validacion"
}, {
  "idpromociontipovalidacion": 1,
  "cnombre": "Ingres&aacute; tu beneficio Cablevision"
}, {
  "idpromociontipovalidacion": 2,
  "cnombre": "Ingres&aacute; tu credencial Club La Nación"
}, {
  "idpromociontipovalidacion": 3,
  "cnombre": "Ingres&aacute; tu c&oacute;digo"
}, {
  "idpromociontipovalidacion": 4,
  "cnombre": "Ingres&aacute; tu c&oacute;digo Fans"
}, {
  "idpromociontipovalidacion": 5,
  "cnombre": "Ingres&aacute; los seis primeros n&uacute;meros de tu tarjeta Macro"
}, {
  "idpromociontipovalidacion": 6,
  "cnombre": "Ingres&aacute; los seis primeros n&uacute;meros de tu tarjeta Santander"
}, {
  "idpromociontipovalidacion": 7,
  "cnombre": "Ingres&aacute; los n&uacute;meros de tu tarjeta Clar&iacute;n 365"
}];
observable.add(function (obj) {
  if (is.empty(obj)) {
    return;
  }

  var self = this;
  var keys = Object.keys(obj);
  keys.forEach(function (value) {
    if (value !== 'undefined') {
      if (obj[value] !== null && obj[value] !== undefined) {
        Object.assign(self.data, obj);
      } else {
        if (value in self.data) {
          delete self.data[value];
        }
      }
    }
  }); // console.log( this.data )
});
observable.add(function () {
  var len = !is.empty(this.data) ? Object.keys(this.data).length : 0;
  var confirm = document.querySelector('#confirmar-seleccion');

  if (confirm) {
    if (this.data['cPromocionDatoAValidar']) {
      confirm.disabled = len !== 8;
    } else {
      confirm.disabled = len !== 7;
    }
  }
});
observableObjectCambio.add(function (obj) {
  if (is.empty(obj)) {
    return;
  }

  var self = this;
  var keys = Object.keys(obj);
  keys.forEach(function (value) {
    if (obj[value] !== null && obj[value] !== undefined) {
      Object.assign(self.data, obj);
    } else {
      if (value in self.data) {
        delete self.data[value];
      }
    }
  }); // console.log( this.data );
});
observablePermisso.add(function (len) {
  this.data = len;
  var button = document.querySelector('#submit-process');

  if (this.data > 0) {
    var legendaSiguiente = document.querySelector('#legendaSiguiente');
    legendaSiguiente.innerHTML = '';
    legendaSiguiente.style.display = 'none';

    if (button.classList.contains('disabled')) {
      button.classList.remove('disabled');
      button.style.opacity = 1;
      button.style.cursor = 'pointer';
    }
  } else {
    button.classList.add('disabled');
    button.style.opacity = .5;
    button.style.cursor = 'not-allowed';
  }
});

function trim(str) {
  while (str.substring(0, 1) === ' ') {
    str = str.substring(1, str.length);
  }

  while (str.substring(str.length - 1, str.length) === ' ') {
    str = str.substring(0, str.length - 1);
  }

  return str;
}

function getCant(cant, isProduct) {
  var dataResults = [];

  for (var i = 1; i <= cant; i++) {
    var cLaS = '';

    if (i > 1) {
      cLaS = 's';
    }

    dataResults.push({
      id: i,
      text: isProduct ? i.toString() : i.toString() + ' Entrada' + cLaS,
      idPromocionTipoValidacion: i
    });
  }

  return dataResults;
}

function updateCantidad(limit, where, isProduct) {
  return new Promise(function (resolve) {
    var res = parseInt(limit);
    var dim = getCant(res, isProduct);
    $('<option disabled selected>Cantidad</option>').appendTo(where);
    dim.forEach(function (item, i) {
      $('<option />').text(item.text).val(item.idPromocionTipoValidacion).appendTo(where);

      if (i === dim.length - 1) {
        resolve();
      }
    });
    resolve();
  });
}

function updatePromocion(promos, where) {
  // console.info(promos)
  return new Promise(function (resolve) {
    where.empty();
    $('<option disabled selected>Promocion</option>').appendTo(where);
    promos = promos.filter(function (item) {
      // if (item.bPromocionDisponibleParaUtilizar && item.bPromocionTieneCupoParaUtilizar) {
      return item; // }
    });
    promos.forEach(function (item, i) {
      var text = item.cNombre;
      var op = $('<option />'); // if (!item.bPromocionTieneCupoParaUtilizar) {
      //   text = item.cNombre + ' (Sin Cupo)';
      //   op[0].disabled = true
      // } else if (!item.bPromocionDisponibleParaUtilizar) {
      //   text = item.cNombre + ' (No disponible para la Cantidad Seleccionada)';
      //
      // }

      if (item.bPromocionTieneCupoParaUtilizar && item.bPromocionDisponibleParaUtilizar) {
        text = item.cNombre;

        if (item.bPromocionCupoValida) {
          if (item.nPromocionCupo > 0) {
            text = item.cNombre + ' (Disponibles: ' + item.nPromocionCupo + ')';
          }
        }
      } else if (!item.bPromocionTieneCupoParaUtilizar) {
        text = item.cNombre + ' (Sin Cupo)';
        op[0].disabled = true;
      } else if (!item.bPromocionDisponibleParaUtilizar) {
        text = item.cNombre + ' (No disponible para la cantidad o el sector seleccionado)';
        op[0].disabled = true;
      }

      op.text(text).attr('data-validation', item.idPromocionTipoValidacion).val(item.idPromocionAsignacion).appendTo(where); // console.info(op)

      if (i === promos.length - 1) {
        if (promos.length > 1) {
          resolve({
            one: false,
            option: null
          });
        } else {
          resolve({
            one: true,
            option: op
          });
        }
      }
    });
  });
}

function iniciarSelectorFuncion(api) {
  var cache = null;
  var date = $('.selectpicker');
  var confirm = document.querySelector('#boton-seleccion-fecha');
  confirm.disabled = true;
  date.selectpicker({
    width: '100%',
    mobile: MOBILE
  });
  date.on('change', function () {
    if (!$(this).hasClass('select-producto-adicional')) {
      var current = $(this).attr('rel');
      var hash = $(this).find('option:selected').attr('rel');

      if (current === 'idFuncion') {
        var id = parseInt($(this).val());
        var obj = {
          [current]: id,
          hash
        };
        observable.notify(obj);

        if (id > 0) {
          confirm.disabled = false;
        }
      }
    }
  });
  var fecha = $('#selection-title');
  confirm.addEventListener('click', function () {
    if (observable.data['idFuncion']) {
      var id = parseInt(observable.data['idFuncion']);
      var query = "?h=".concat(observable.data.hash);

      if (id > 0 && id !== cache) {
        cache = id;
        observable.data = {};
        observable.data['idFuncion'] = id;
        observable.notify({
          ['idProcesoDeCompra']: ''
        });

        if (!confirm.classList.contains('load')) {
          confirm.classList.add('load');
          one(confirm, transitionEnd, function () {
            iniciarFuncionEntradasSeleccion(id, api, query).then(function () {
              confirm.classList.remove('load');
              document.querySelector('.head-event').classList.add('default');
              var txt = trim($('[name="idFuncion"]').find('option:selected').text().replace(/\s+/g, ' '));

              if (!isMobile.any()) {
                txt = ' - ' + txt;
              }

              fecha.text(txt);
              var confirmSeleccion = document.querySelector('#submit-process');
              var legendaSiguiente = document.querySelector('#legendaSiguiente');

              var handler = function handler(e) {
                if (this.disabled === true) return false;
                legendaSiguiente.innerHTML = '';
                legendaSiguiente.style.display = 'none';

                if (observablePermisso.data === 0) {
                  legendaSiguiente.innerHTML = 'No es posible continuar, no hay selecciones en proceso';
                  legendaSiguiente.style.display = 'block';
                  return false;
                }

                var innerSelection = document.querySelector('#contenido-adicional-producto');
                innerSelection.innerHTML = '';

                if (!confirmSeleccion.classList.contains('setProcess')) {
                  e.preventDefault();
                  var lb = document.querySelector('.border-box .showbox');
                  sholoading(lb, 'Procesando su seleccion', function () {
                    api.getProductoAsignado().then(function (response) {
                      if (response.nCode === 200) {
                        if (response.oData !== null) {
                          if (response.oData.listaItemProductoAsignado.length > 0) {
                            response.oData.listaItemProductoAsignado.map(function (item) {
                              var min = item.nCantidadSeleccionMinima;
                              var max = item.nCantidadSeleccionMaxima;
                              var list = [];

                              for (var i = min; i <= max; i++) {
                                list.push(i);
                              }

                              return item['litaOpciones'] = list;
                            });

                            if (!tplManager.exist('producto')) {
                              tplManager.add('producto', '/templates/producto/lista.html').then(function (compiler) {
                                innerSelection.innerHTML = compiler(response.oData);
                                initProductModal(api);
                              }).catch(function (err) {
                                resolve(err);
                              });
                            } else {
                              var compiler = tplManager.templates['producto'];
                              innerSelection.innerHTML = compiler(response.oData);
                              initProductModal(api);
                            }
                          } else {
                            // si no hay productos
                            confirmSeleccion.classList.add('setProcess');
                            confirmSeleccion.click();
                          }

                          hideloading(lb);
                        } else {
                          // si no hay productos
                          confirmSeleccion.classList.add('setProcess');
                          confirmSeleccion.click();
                        }
                      }
                    });
                  });
                }
              };

              confirmSeleccion.addEventListener('click', handler, false);
            });
          });
        }
      }
    }
  });
  return new Promise(function (resolve) {
    resolve();
  });
}

function iniciarFuncionEntradasSeleccion(idFuncion, api, query) {
  if (!tplManager) {
    tplManager = new TemplateManager(api.$root);
  }

  var innerSelection = document.querySelector('#slc-pnl');
  return new Promise(function (resolve) {
    if (idFuncion > 0) {
      api.getFuncion(idFuncion, query).then(function (response) {
        if (response.nCode === 200) {
          api.$curEID = response.oData.Funcion.idEspectaculo;
          response.oData.Funcion.Sectores.oData.Sectores = response.oData.Funcion.Sectores.oData.Sectores.filter(function (item) {
            return item.CantidadDisponible > 0;
          });
          response.oData.Funcion.Sectores.oData['idEspectaculoTipo'] = response.oData.Funcion.idEspectaculoTipo;

          if (!tplManager.exist('events')) {
            tplManager.add('events', '/templates/events/posiciones.html').then(function (compiler) {
              innerSelection.innerHTML = compiler(response.oData.Funcion);
              var button = document.querySelector('#submit-process');
              button.disabled = true;
              button.style.opacity = .5;
              button.style.cursor = 'not-allowed';
              var lbLegend = document.querySelector('#load-legend');
              lbLegend.innerHTML = 'Cargando informacion';
              iniciarSelectoresToma(api);
              resolve();
            }).catch(function (err) {
              resolve(err);
            });
          } else {
            var compiler = tplManager.templates['events'];
            innerSelection.innerHTML = compiler(response.oData.Funcion);
            var lbLegend = document.querySelector('#load-legend');
            lbLegend.innerHTML = 'Consultando disponibilad';
            iniciarSelectoresToma(api);
            resolve();
          }
        } else {
          resolve({});
        }
      }).catch(function (err) {
        reject(err);
      });
    }
  });
}

function iniciarSelectoresTomaProducto(api) {
  $('.btn-cancel').attr('href', "".concat(api.prevView ? api.prevView : api.apiRedirectTo));
  var lb = document.querySelector('.border-box .showbox');
  var selector = $('.selectpicker');
  var cant = $('[rel="Cantidad"]');
  var promo = $('[rel="idPromocionAsignacion"]');
  var sec = $('[rel="idSector"]');
  var confirm = document.querySelector('#confirmar-seleccion');
  confirm.disabled = true;
  cant.prop('disabled', true);
  promo.prop('disabled', true);
  selector.selectpicker({
    width: '100%',
    mobile: MOBILE
  });

  if (!sec.length) {
    hideloading(lb);
    return;
  }

  var validoPromocion = false;
  var objTomaDummy = {};
  var cantMax = sec.attr('data-cantidad-maxima');
  var data = sec.val();
  data = data.split('|');
  cant.empty();
  cant.prop('disabled', true);
  promo.prop('disabled', true);
  cant.selectpicker('refresh'); //

  if (observable.data['Cantidad']) {
    observable.remove('Cantidad');
  } //


  promo.find('option').eq(0).prop('selected', true);
  promo.selectpicker('refresh'); //

  if (observable.data['idPromocionAsignacion']) {
    observable.remove('idPromocionAsignacion');
  } //


  objTomaDummy['cTipoSector'] = data[1];
  objTomaDummy['fPrecio'] = parseFloat(data[2]);
  objTomaDummy['idSector'] = data[0]; //

  observable.notify(objTomaDummy); //

  updateCantidad(cantMax, cant, true).then(function () {
    cant.prop('disabled', false);
    cant.selectpicker('refresh');
  });
  selector.on('change', function () {
    if ($(this).hasClass('select-producto-adicional')) {
      return false;
    }

    var current = $(this).attr('rel');
    var validation = $(this).find('option:selected').attr('data-validation');
    var value = $(this).val();
    var obj = objTomaDummy;
    var leyendaPromo = document.querySelector('#promcionesMensaje');
    leyendaPromo.innerHTML = '';
    leyendaPromo.style.display = 'none';

    switch (current) {
      case 'Cantidad':
        sholoading(lb, 'Consultando promociones disponibles para su seleccion', function () {
          obj[current] = parseInt(value);
          observable.notify(obj);
          promo.find('option').eq(0).prop('selected', true);
          promo.selectpicker('refresh');

          if (observable.data['idPromocionAsignacion']) {
            observable.remove('idPromocionAsignacion');
          }

          api.getPromocionFuncion(observable.data['idFuncion'], observable.data).then(function (response) {
            if (response.nCode === 200) {
              if (response.oData.Promociones.length > 0) {
                updatePromocion(response.oData.Promociones, promo).then(function (res) {
                  promo.prop('disabled', false);

                  if (res.one) {
                    res.option.prop('selected', true);
                    promo.trigger('change');
                  }

                  promo.selectpicker('refresh');
                });
              } else {
                leyendaPromo.innerHTML = "<span style=\"color: #E14;\">No hay promociones disponibles para la selecci&oacute;n</span>";
                leyendaPromo.style.display = 'block';
              }

              hideloading(lb);
            }
          });
        });
        break;

      case 'idPromocionAsignacion':
        confirm.disabled = false;
        observable.notify({
          [current]: parseInt(value)
        });

        if (parseInt(validation) > 0) {
          validoPromocion = true;
          var o = TipoValidacion.filter(function (e) {
            return e.idpromociontipovalidacion === parseInt(validation);
          })[0]; // console.info(o)

          $('#validacion-lenyenda-tipo').html(o.cnombre);
        } else {
          validoPromocion = false;
          observable.remove('cPromocionDatoAValidar');
          $('#validacion-lenyenda-tipo').html('');
        }

        break;

      default: // console.log(current)

    }
  });
  confirm.addEventListener('click', function () {
    var _this = this;

    api['setLogin'] = false;
    document.querySelector('[name="cCodigo"]').value = '';

    if (api.token) {
      sholoading(lb, 'Procesando su seleccion', function () {
        if (validoPromocion) {
          // modal de validacion de promocion
          showPromocionValidacion(function () {
            var lg = lb.querySelector('#load-legend');
            lg.innerHTML = 'Procesando su seleccion';
            resumen(api, null, 5).then(function () {
              var button = document.querySelector('#submit-process');
              button.disabled = false;
              button.style.opacity = 1;
              button.style.cursor = 'pointer';
              cant.find('option').eq(0).prop('selected', true);
              promo.find('option').eq(0).prop('selected', true);
              cant.prop('disabled', false);
              promo.prop('disabled', true);
              cant.selectpicker('refresh');
              promo.selectpicker('refresh');
              observable.remove('Cantidad');
              observable.remove('cTipoSector');
              observable.remove('fPrecio');
              observable.remove('idPromocionAsignacion');
              observable.remove('idSector');
              _this.disabled = true;
              hideloading(lb);
            }).catch(function () {
              confirm.classList.remove('load');
              cant.find('option').eq(0).prop('selected', true);
              promo.find('option').eq(0).prop('selected', true);
              cant.prop('disabled', false);
              promo.prop('disabled', true);
              cant.selectpicker('refresh');
              promo.selectpicker('refresh');
              observable.remove('Cantidad');
              observable.remove('cTipoSector');
              observable.remove('fPrecio');
              observable.remove('idPromocionAsignacion');
              observable.remove('idSector');
              _this.disabled = true;
              hideloading(lb);
            });
          });
        } else {
          // si no hay validacion de la promocion
          resumen(api, null, 5).then(function () {
            var button = document.querySelector('#submit-process');
            button.disabled = false;
            button.style.opacity = 1;
            button.style.cursor = 'pointer';
            cant.find('option').eq(0).prop('selected', true);
            promo.find('option').eq(0).prop('selected', true);
            cant.prop('disabled', false);
            promo.prop('disabled', true);
            sec.selectpicker('refresh');
            cant.selectpicker('refresh');
            promo.selectpicker('refresh');
            observable.remove('Cantidad');
            observable.remove('cTipoSector');
            observable.remove('fPrecio');
            observable.remove('idPromocionAsignacion');
            observable.remove('idSector');
            _this.disabled = true;
            hideloading(lb);
          }).catch(function () {
            confirm.classList.remove('load');
            cant.find('option').eq(0).prop('selected', true);
            promo.find('option').eq(0).prop('selected', true);
            cant.prop('disabled', false);
            promo.prop('disabled', true);
            cant.selectpicker('refresh');
            promo.selectpicker('refresh');
            observable.remove('Cantidad');
            observable.remove('cTipoSector');
            observable.remove('fPrecio');
            observable.remove('idPromocionAsignacion');
            observable.remove('idSector');
            _this.disabled = true;
            hideloading(lb);
          });
        }
      });
    } else {
      api['setLogin'] = true;
      api.loginStatus().then(function (res) {
        if (res.nCode === 401) {
          hideloading(lb);
          $('#ingreso').modal('show').one('hidden.bs.modal', function () {
            $('#respuesta-login').css('display', 'none');

            if (!$('#login-form').hasClass('no-loguin')) {
              var email = document.querySelector('[name="cMail"]');
              var xxx = document.querySelector('[name="cClave"]');

              if (email.value !== '' && xxx.value !== '') {
                api['setLogin'] = false;
                confirm.click();
              }
            } else {
              document.querySelector('[name="cMail"]').value = '';
              document.querySelector('[name="cClave"]').value = '';
            }
          });
        } else {
          if (!confirm.disabled) {
            api['setLogin'] = false;
            sholoading(lb, 'Procesando su seleccion', function () {
              resumen(api, null, 5).then(function () {
                cant.find('option').eq(0).prop('selected', true);
                promo.find('option').eq(0).prop('selected', true);
                cant.prop('disabled', false);
                promo.prop('disabled', true);
                cant.selectpicker('refresh');
                promo.selectpicker('refresh');
                observable.remove('Cantidad');
                observable.remove('cTipoSector');
                observable.remove('fPrecio');
                observable.remove('idPromocionAsignacion');
                observable.remove('idSector');
                _this.disabled = true;
                hideloading(lb);
              }).catch(function () {
                confirm.classList.remove('load');
                cant.find('option').eq(0).prop('selected', true);
                promo.find('option').eq(0).prop('selected', true);
                cant.prop('disabled', false);
                promo.prop('disabled', true);
                cant.selectpicker('refresh');
                promo.selectpicker('refresh');
                observable.remove('Cantidad');
                observable.remove('cTipoSector');
                observable.remove('fPrecio');
                observable.remove('idPromocionAsignacion');
                observable.remove('idSector');
                _this.disabled = true;
                hideloading(lb);
              });
            });
          }
        }
      }).catch(function () {
        confirm.classList.remove('load');
        hideloading(lb);
      });
    }
  });
  hideloading(lb);
}

function iniciarSelectoresToma(api) {
  var seccion = 'espectaculo';

  if (SELECTOR) {
    seccion = 'seleccion';
  } // $('.btn-cancel').attr('href', `${api.apiRedirectTo}`)


  $('.btn-cancel').attr('href', "".concat(api.prevView ? api.prevView : api.apiRedirectTo));
  var lb = document.querySelector('.border-box .showbox');
  var selector = $('.selectpicker');
  var sec = $('[rel="idSector"]');
  var cant = $('[rel="Cantidad"]');
  var promo = $('[rel="idPromocionAsignacion"]');
  var svgSectores = $('.svg.desktop .svg__mapa__sectores');

  if (isMobile.any()) {
    svgSectores = $('.svg.mobile .svg__mapa__sectores');
  }

  var confirm = document.querySelector('#confirmar-seleccion');
  confirm.disabled = true;
  cant.prop('disabled', true);
  promo.prop('disabled', true);
  selector.selectpicker({
    width: '100%',
    mobile: MOBILE
  });
  var svg = $('.svg svg');

  if (svg.length) {
    svg.attr('id', 'svg');
    var maxX = svg.parent()[0].offsetWidth;
    var maxY = svg.parent()[0].offsetHeight;
    svg.attr('width', maxX);
    svg.attr('height', maxY);
    $(window).resize(function () {
      var maxX = svg.parent()[0].offsetWidth;
      var maxY = svg.parent()[0].offsetHeight;
      svg.attr('width', maxX);
      svg.attr('height', maxY);
    });
    svgSectores.each(function () {
      var id = $(this).attr('id');
      var sector = $('[data-relation-svg="' + id + '"]');
      var price = sector.attr('data-precio-svg-seleccion');
      price = parseInt(price);
      var disp = sector.attr('data-dispo');
      disp = parseInt(disp);
      var conf = {
        container: 'body',
        placement: 'right',
        title: 'Disponible',
        trigger: 'hover',
        html: true
      };
      var instance = null;

      if (sector.length === 0) {
        $(this).css('opacity', .35);
        conf['title'] = 'No Disponible';
        instance = new Tooltip($(this)[0], conf);
      } else {
        $(this).css('cursor', 'pointer');

        if (!isNaN(disp)) {
          $(this).css('opacity', 1);
          conf['title'] = 'Disponible';

          if (!isNaN(price) && price > 0) {
            conf['title'] = "Disponible <br> Valor $".concat(price);
          }
        } else {
          $(this).css('opacity', .35);
          conf['title'] = 'No Disponible';
        }

        instance = new Tooltip($(this)[0], conf);
        $(this).on({
          click: function click() {
            /*
            var id = $(this).attr('id');
            var $bClass = $(this).hasClass('clicked');
            $('.svg__mapa__sectores')
              .removeClass('clicked')
              .css('opacity', .35);
            if(!$bClass) {
              $(this).addClass('clicked');
              $(this).css('opacity', 1);
              $('[data-relation-svg="'+id+'"]')
                .prop('selected', true);
              $('#sector-id')
                .selectpicker('refresh')
                .trigger('change')
            }*/
          },
          mouseenter: function mouseenter() {
            instance.show();
          },
          mouseleave: function mouseleave() {
            instance.hide();
          }
        });
      }
    });
  }

  var validoPromocion = false;
  selector.on('change', function () {
    var _this2 = this;

    if ($(this).hasClass('select-producto-adicional')) {
      return false;
    }

    var current = $(this).attr('rel');
    var validation = $(this).find('option:selected').attr('data-validation');
    var value = $(this).val();
    var obj = {};
    var leyendaPromo = document.querySelector('#promcionesMensaje');
    leyendaPromo.innerHTML = '';
    leyendaPromo.style.display = 'none';

    switch (current) {
      case 'idSector':
        sholoading(lb, 'Consultando disponibilidad', function () {
          if (svgSectores.length > 0) {
            var id = $(_this2).find('option:selected').attr('data-relation-svg');
            var sector = $('.svg.desktop #' + id);

            if (isMobile.any()) {
              sector = $('.svg.mobile #' + id);
            }

            if (sector.length > 0) {
              if (!sector.hasClass('clicked')) {
                sector.click();
              }
            }
          }

          var selected = $(_this2).find('option:selected');
          var cantMax = selected.attr('data-cantidad-maxima');
          var data = selected.attr('data-generic-id');
          data = data.split('|');
          cant.empty();
          cant.prop('disabled', true);
          promo.prop('disabled', true);
          cant.selectpicker('refresh');

          if (observable.data['Cantidad']) {
            observable.remove('Cantidad');
          }

          promo.find('option').eq(0).prop('selected', true);
          promo.selectpicker('refresh');

          if (observable.data['idPromocionAsignacion']) {
            observable.remove('idPromocionAsignacion');
          }

          if (value) {
            obj['cTipoSector'] = data[1];
            obj['fPrecio'] = parseFloat(data[2]);
            obj['idSector'] = value;
            observable.notify(obj);
          }

          updateCantidad(cantMax, cant).then(function () {
            cant.prop('disabled', false);
            cant.selectpicker('refresh');
            setTimeout(function () {
              hideloading(lb);
            }, 500);
          });
        });
        break;

      case 'Cantidad':
        sholoading(lb, 'Consultando promociones disponibles para su seleccion', function () {
          obj[current] = parseInt(value);
          observable.notify(obj);
          promo.find('option').eq(0).prop('selected', true);
          promo.selectpicker('refresh');

          if (observable.data['idPromocionAsignacion']) {
            observable.remove('idPromocionAsignacion');
          }

          api.getPromocionFuncion(observable.data['idFuncion'], observable.data).then(function (response) {
            if (response.nCode === 200) {
              if (response.oData.Promociones.length > 0) {
                updatePromocion(response.oData.Promociones, promo).then(function (res) {
                  promo.prop('disabled', false);

                  if (res.one) {
                    res.option.prop('selected', true);
                    promo.trigger('change');
                  }

                  promo.selectpicker('refresh');
                });
              } else {
                leyendaPromo.innerHTML = "<span style=\"color: #E14;\">No hay promociones disponibles para la selecci&oacute;n</span>";
                leyendaPromo.style.display = 'block';
              }

              hideloading(lb);
            }
          });
        });
        break;

      case 'idPromocionAsignacion':
        confirm.disabled = false;
        observable.notify({
          [current]: parseInt(value)
        });

        if (parseInt(validation) > 0) {
          validoPromocion = true;
          var o = TipoValidacion.filter(function (e) {
            return e.idpromociontipovalidacion === parseInt(validation);
          })[0]; // console.info(o)

          $('#validacion-lenyenda-tipo').html(o.cnombre);
        } else {
          validoPromocion = false;
          observable.remove('cPromocionDatoAValidar');
          $('#validacion-lenyenda-tipo').html('');
        }

        break;

      default: // console.log(current)

    }
  });
  confirm.addEventListener('click', function () {
    var _this3 = this;

    api['setLogin'] = false;
    document.querySelector('[name="cCodigo"]').value = '';

    if (api.token) {
      sholoading(lb, 'Procesando su seleccion', function () {
        if (validoPromocion) {
          // modal de validacion de promocion
          showPromocionValidacion(function () {
            var lg = lb.querySelector('#load-legend');
            lg.innerHTML = 'Procesando su seleccion';
            resumen(api).then(function () {
              var button = document.querySelector('#submit-process');
              button.disabled = false;
              button.style.opacity = 1;
              button.style.cursor = 'pointer';
              sec.find('option').eq(0).prop('selected', true);
              cant.find('option').eq(0).prop('selected', true);
              promo.find('option').eq(0).prop('selected', true);
              cant.prop('disabled', true);
              promo.prop('disabled', true);
              sec.selectpicker('refresh');
              cant.selectpicker('refresh');
              promo.selectpicker('refresh');
              observable.remove('Cantidad');
              observable.remove('cTipoSector');
              observable.remove('fPrecio');
              observable.remove('idPromocionAsignacion');
              observable.remove('idSector');
              _this3.disabled = true;
              hideloading(lb);
            }).catch(function () {
              confirm.classList.remove('load');
              sec.find('option').eq(0).prop('selected', true);
              cant.find('option').eq(0).prop('selected', true);
              promo.find('option').eq(0).prop('selected', true);
              cant.prop('disabled', true);
              promo.prop('disabled', true);
              sec.selectpicker('refresh');
              cant.selectpicker('refresh');
              promo.selectpicker('refresh');
              observable.remove('Cantidad');
              observable.remove('cTipoSector');
              observable.remove('fPrecio');
              observable.remove('idPromocionAsignacion');
              observable.remove('idSector');
              _this3.disabled = true;
              hideloading(lb);
            });
          });
        } else {
          // si no hay validacion de la promocion
          resumen(api).then(function () {
            var button = document.querySelector('#submit-process');
            button.disabled = false;
            button.style.opacity = 1;
            button.style.cursor = 'pointer';
            sec.find('option').eq(0).prop('selected', true);
            cant.find('option').eq(0).prop('selected', true);
            promo.find('option').eq(0).prop('selected', true);
            cant.prop('disabled', true);
            promo.prop('disabled', true);
            sec.selectpicker('refresh');
            cant.selectpicker('refresh');
            promo.selectpicker('refresh');
            observable.remove('Cantidad');
            observable.remove('cTipoSector');
            observable.remove('fPrecio');
            observable.remove('idPromocionAsignacion');
            observable.remove('idSector');
            _this3.disabled = true;
            hideloading(lb);
          }).catch(function () {
            confirm.classList.remove('load');
            sec.find('option').eq(0).prop('selected', true);
            cant.find('option').eq(0).prop('selected', true);
            promo.find('option').eq(0).prop('selected', true);
            cant.prop('disabled', true);
            promo.prop('disabled', true);
            sec.selectpicker('refresh');
            cant.selectpicker('refresh');
            promo.selectpicker('refresh');
            observable.remove('Cantidad');
            observable.remove('cTipoSector');
            observable.remove('fPrecio');
            observable.remove('idPromocionAsignacion');
            observable.remove('idSector');
            _this3.disabled = true;
            hideloading(lb);
          });
        }
      });
    } else {
      api['setLogin'] = true;
      api.loginStatus().then(function (res) {
        if (res.nCode === 401) {
          hideloading(lb);
          $('#ingreso').modal('show').one('hidden.bs.modal', function () {
            $('#respuesta-login').css('display', 'none');

            if (!$('#login-form').hasClass('no-loguin')) {
              var email = document.querySelector('[name="cMail"]');
              var xxx = document.querySelector('[name="cClave"]');

              if (email.value !== '' && xxx.value !== '') {
                api['setLogin'] = false;
                confirm.click();
              }
            } else {
              document.querySelector('[name="cMail"]').value = '';
              document.querySelector('[name="cClave"]').value = '';
            }
          });
        } else {
          if (!confirm.disabled) {
            api['setLogin'] = false;
            sholoading(lb, 'Procesando su seleccion', function () {
              resumen(api).then(function () {
                sec.find('option').eq(0).prop('selected', true);
                cant.find('option').eq(0).prop('selected', true);
                promo.find('option').eq(0).prop('selected', true);
                cant.prop('disabled', true);
                promo.prop('disabled', true);
                sec.selectpicker('refresh');
                cant.selectpicker('refresh');
                promo.selectpicker('refresh');
                observable.remove('Cantidad');
                observable.remove('cTipoSector');
                observable.remove('fPrecio');
                observable.remove('idPromocionAsignacion');
                observable.remove('idSector');
                _this3.disabled = true;
                hideloading(lb);
              }).catch(function () {
                confirm.classList.remove('load');
                sec.find('option').eq(0).prop('selected', true);
                cant.find('option').eq(0).prop('selected', true);
                promo.find('option').eq(0).prop('selected', true);
                cant.prop('disabled', true);
                promo.prop('disabled', true);
                sec.selectpicker('refresh');
                cant.selectpicker('refresh');
                promo.selectpicker('refresh');
                observable.remove('Cantidad');
                observable.remove('cTipoSector');
                observable.remove('fPrecio');
                observable.remove('idPromocionAsignacion');
                observable.remove('idSector');
                _this3.disabled = true;
                hideloading(lb);
              });
            });
          }
        }
      }).catch(function () {
        confirm.classList.remove('load');
        hideloading(lb);
      });
    }
  });
  hideloading(lb);
}

function showPromocionValidacion(cb) {
  var lb = document.querySelector('.border-box .showbox');
  var sectorid = $('#sector-id');
  var cantidadid = $('#cantidad-id');
  var currSel = $('#promocion-id');
  var lg = lb.querySelector('#load-legend');
  lg.innerHTML = 'Esperando la validacion de la promocion';
  $('#promocion').modal('show').one('hidden.bs.modal', function () {
    var cod = document.querySelector('[name="cCodigo"]');

    if (cod.value === '') {
      observable.remove('cPromocionDatoAValidar');
      observable.remove('idPromocionAsignacion');
      sectorid.find('option').eq(0).prop('selected', true);
      sectorid.selectpicker('refresh');
      cantidadid.find('option').eq(0).prop('selected', true).prop('disabled', true);
      cantidadid.selectpicker('refresh');
      currSel.find('option').eq(0).prop('selected', true).prop('disabled', true);
      currSel.selectpicker('refresh');
      hideloading(lb);
    } else {
      cb();
    }
  });
}

function sholoading(lb, legend, cb) {
  var lg = lb.querySelector('#load-legend');
  lg.innerHTML = legend;

  if (lb.classList.contains('is-hidden')) {
    lb.classList.remove('is-hidden');
    setTimeout(function () {
      lb.classList.add('is-visible');

      if (cb) {
        cb();
      }
    }, 150);
  }
}

function hideloading(lb, cb) {
  var lg = lb.querySelector('#load-legend');
  lg.innerHTML = '';

  if (lb.classList.contains('is-visible')) {
    lb.classList.remove('is-visible');
    setTimeout(function () {
      lb.classList.add('is-hidden');

      if (cb) {
        cb();
      }
    }, 150);
  }
}

function tomaCambiarPocisiones(api, objToma, data) {
  var lb = document.querySelector('.border-box .showbox');
  var params = {
    "cTipoSector": objToma.bGranel ? 'G' : 'N',
    "fPrecio": objToma.fPrecioUnitario,
    "idFuncion": api.$curNFID,
    "idSector": objToma.cSector,
    "Cantidad": objToma.nCantidadPosiciones,
    "idProcesoDeCompra": data.idProcesoDeCompra,
    "idPromocionAsignacion": objToma.idPromocionAsignacion,
    "idListaSoltar": objToma.listaInformacionPosicion.map(function (item) {
      return item.idPosicion;
    }),
    "idListaTomar": []
  };
  sholoading(lb, 'Procesando su seleccion', function () {
    resumen(api, params).then(function () {
      hideloading(lb);
    });
  });
}

function tomaCambiarPosicionesPorMapa(api, objToma, data) {
  var confirm = document.querySelector('#button-confirmar-cambio');

  if (confirm) {
    confirm.disabled = true;
  }

  var obj = {
    "cTipoSector": objToma.bGranel ? 'G' : 'N',
    "fPrecio": objToma.fPrecioUnitario,
    "idFuncion": api.$curNFID,
    "idSector": objToma.cSector,
    "Cantidad": objToma.nCantidadPosiciones,
    "idProcesoDeCompra": data.idProcesoDeCompra,
    "idPromocionAsignacion": objToma.idPromocionAsignacion
  }; // console.log(obj)

  observableObjectCambio.notify(obj);
  var listaSoltar = objToma.listaInformacionPosicion.map(function (item) {
    return item.idPosicion;
  });
  var listaTomar = [];
  var controlCantidad = objToma.listaInformacionPosicion.length;
  var mp = $('#contentMapa');
  mp.empty();
  api.getPosicionesMapa(obj).then(function (response) {
    // console.log(response)
    if (response.nCode === 200) {
      if (!is.empty(response.oData)) {
        if (response.oData.Posiciones.length) {
          var maxX = Math.max.apply(Math, response.oData.Posiciones.map(function (o) {
            return o.nX;
          }));
          var maxY = Math.max.apply(Math, response.oData.Posiciones.map(function (o) {
            return o.nY;
          }));
          var svg = SVG('contentMapa').size(maxX + 30, maxY + 30).attr('id', 'svg-container').addClass('svg-container');
          var SVGGroup = svg.group().attr('id', 'grpDots');
          var childsNodes = {};
          var soltar = [];
          response.oData.Posiciones.forEach(function (child) {
            var circle = drawCircle(svg, SVGGroup, child);

            if (!childsNodes.hasOwnProperty(child.idPosicion)) {
              childsNodes[child.idPosicion] = circle;
            }

            var instance = new Tooltip(circle.node, {
              container: 'body',
              placement: 'top',
              title: "".concat(child.cEtiqueta),
              trigger: 'hover',
              html: true
            });
            circle.click(function () {
              instance.hide();
              var e = this.attr('data-estado');
              var idPosicion = this.attr('id'); // Si el estado es 1, la posicion se puede tomar

              if (e === 1) {
                // verificamos si existe dentro del array
                var index = listaTomar.indexOf(idPosicion); // si no existe la agregamos dentro del array

                if (index === -1) {
                  listaTomar.push(idPosicion);
                } // cambiamos el estado de la posicion


                this.attr('fill', '#3c75ef');

                if (soltar.length < listaSoltar.length) {
                  soltar.push(listaSoltar[listaTomar.length - 1]);
                  childsNodes[listaSoltar[listaTomar.length - 1]].fill({
                    color: '#585898',
                    opacity: .50
                  });
                }

                if (listaTomar.length > controlCantidad) {
                  // si la cantidad es mayor a la permitida
                  // se elimina la posicion 0 si la nueva no existe dentro del array
                  if (index === -1) {
                    childsNodes[listaTomar[0]].fill({
                      color: '#ccc'
                    });
                    listaTomar.splice(0, 1);
                  }
                } else {
                  // Si existe la posicion en el array
                  // se elimina y limpia el array soltar
                  // console.log(index);
                  if (index !== -1) {
                    // console.log('click', 'id', idPosicion, 'index array', index);
                    childsNodes[listaTomar[index]].fill({
                      color: '#ccc'
                    });
                    listaTomar.splice(index, 1);
                    childsNodes[soltar[soltar.length - 1]].fill({
                      color: '#3c75ef'
                    }); // console.log(soltar.length-1);

                    soltar.splice(soltar.length - 1, 1);
                  }
                }

                if (listaTomar.length === 0) {
                  soltar = [];
                }
              }

              confirm.disabled = listaTomar.length < 1; // console.log('tomar',listaTomar);
              // console.log('lista',listaSoltar);
              // console.log('soltar',soltar);

              observableObjectCambio.notify({
                "idListaTomar": listaTomar,
                "idListaSoltar": soltar
              });
            });
          });
        }
      }
    }
  });
  var rate = 1000;
  var lastClick = Date.now() - rate;
  confirm.addEventListener('click', function (ev) {
    if (Date.now() - lastClick >= rate) {
      this.disabled = true; // console.log(JSON.stringify(observableObjectCambio.data))

      if (!is.empty(observableObjectCambio.data)) {
        $('#sectorseleccionado').modal('hide');
        var lb = document.querySelector('.border-box .showbox');
        sholoading(lb, 'Procesando su seleccion', function () {
          resumen(api, observableObjectCambio.data).then(function () {
            hideloading(lb);
          });
        });
      }
    }
  });
}

function drawCircle(svg, parent, child) {
  var className = 'free';
  var fill = '#e14';

  if (child.idEstado === 99) {
    fill = '#3c75ef';
  }

  if (child.idEstado === 1) {
    fill = '#CCC';
  }

  if (child.idEstado === 99) {
    className = 'holded';
  } else {
    className = 'not-free';
  }

  return parent.circle(15).attr('id', child.idPosicion).attr('data-estado', child.idEstado).style('cursor', 'pointer').fill(fill).addClass(className).move(child.nX, child.nY);
}

function iniciarTomasControles(api, data, idTipoEspectaculo) {
  var confirmSeleccion = document.querySelector('#submit-process');
  confirmSeleccion.href = "/proceso/".concat(api.$curProcessId);
  document.querySelectorAll('.delete-class').forEach(function (del) {
    var instance = new Tooltip($(del)[0], {
      container: 'body',
      placement: 'top',
      title: 'Cancelar',
      trigger: 'hover',
      html: true
    });
    $(del).on({
      'click': function click() {
        instance.hide();
        var index = $(this).attr('data-delete-id'); // console.info('[name="'+ $(this).attr('rel') + ' ' + index +'"]')

        var hidden = document.querySelector('[name="' + $(this).attr('rel') + ' ' + index + '"]');
        var oobj = {
          idLista: JSON.parse(hidden.value),
          idProcesoDeCompra: observable.data['idProcesoDeCompra']
        }; // console.info(index)
        // console.info(hidden.value)
        // console.info(oobj)

        api.setPosicionesSoltar(oobj).then(function (response) {
          if (response) {
            if (response.nCode === 200) {
              if (response.oData) {
                var _oobj = {};
                _oobj['idProcesoDeCompra'] = response.oData.idProcesoDeCompra;
                $('[data-selection-id="block-' + index + '"]').remove();
                response.oData.oPosicionesSeleccionadasGrupos = response.oData.oPosicionesSeleccionadasGrupos.map(function (item) {
                  if (!item['listaInformacionPedido']) {
                    item['listaInformacionPedido'] = JSON.stringify(item);
                  }

                  return item;
                });
                observablePermisso.notify(response.oData.oPosicionesSeleccionadasGrupos.length);
                response.oData['idTipoEspectaculo'] = idTipoEspectaculo; // console.info(response.oData)

                if (!tplManager.exist('holds')) {
                  tplManager.add('holds', '/templates/events/tomas.html').then(function (compiler) {
                    document.querySelector('#slc-dtl').innerHTML = compiler(response.oData);
                    iniciarTomasControles(api, data, idTipoEspectaculo);
                  }).catch(function (e) {
                    reject(e);
                  });
                } else {
                  var compiler = tplManager.templates['holds'];
                  document.querySelector('#slc-dtl').innerHTML = compiler(response.oData);
                  iniciarTomasControles(api, data, idTipoEspectaculo);
                }
              }
            }
          }
        }).catch(function (err) {
          if (err) console.log(err);
        });
      }
    });
  });
  document.querySelectorAll('.refresh-class').forEach(function (sector) {
    var rate = 1000;
    var lastClick = Date.now() - rate;
    var instance = new Tooltip($(sector)[0], {
      container: 'body',
      placement: 'top',
      title: 'Nuevas Ubicaciones',
      trigger: 'hover',
      html: true
    });
    $(sector).on({
      'click': function click() {
        instance.hide();

        if (Date.now() - lastClick >= rate) {
          var index = $(this).attr('data-informacion-refresh-boton');
          var objToma = document.querySelector('[data-informacion-pedido="' + index + '"]').value;
          objToma = JSON.parse(objToma);
          tomaCambiarPocisiones(api, objToma, data);
        }
      }
    });
  });
  document.querySelectorAll('.sector-class').forEach(function (sector) {
    var instance = new Tooltip($(sector)[0], {
      container: 'body',
      placement: 'top',
      title: 'Ver en el Mapa',
      trigger: 'hover',
      html: true
    });
    $(sector).on({
      'click': function click() {
        instance.hide();
        var index = $(this).attr('data-informacion-pedido-boton');
        var objToma = document.querySelector('[data-informacion-pedido="' + index + '"]').value;
        objToma = JSON.parse(objToma); // console.log(objToma)

        tomaCambiarPosicionesPorMapa(api, objToma, data);
      }
    });
  });
}

function resumen(api, params, idTipoEspectaculo) {
  // console.info(idTipoEspectaculo);
  var innerSelection = document.querySelector('#slc-dtl');
  var obj = observable.data;

  if (params) {
    obj = params;
  }

  return new Promise(function (resolve, reject) {
    api.setPosicionesTomar(obj).then(function (response) {
      if (response && response.nCode === 200) {
        if (response.oData.nResultado === -3) {
          $('#promo-msg').html(response.oData.cMensaje);
          $('#promocion-rechazo').modal('show');
        } else if (response.oData.nResultado === -1) {
          $('#promo-msg').html(response.oData.cMensaje);
          $('#promocion-rechazo').modal('show');
        } else {
          $('#promo-msg').html();
        }

        var pid = response.oData.idProcesoDeCompra;
        response.oData.oPosicionesSeleccionadasGrupos = response.oData.oPosicionesSeleccionadasGrupos.map(function (item) {
          if (!item['listaInformacionPedido']) {
            item['listaInformacionPedido'] = JSON.stringify(item);
          }

          return item;
        });
        observablePermisso.notify(response.oData.oPosicionesSeleccionadasGrupos.length);
        observable.notify({
          ['idProcesoDeCompra']: pid
        });
        response.oData['idEspectaculoTipo'] = idTipoEspectaculo;
        response.oData['FuncionEtiqueta'] = api.funcionStore.cEtiqueta;

        if (!tplManager.exist('holds')) {
          tplManager.add('holds', '/templates/events/tomas.html').then(function (compiler) {
            innerSelection.innerHTML = compiler(response.oData);
            iniciarTomasControles(api, response.oData, idTipoEspectaculo);
            resolve();
          }).catch(function (e) {
            reject(e);
          });
        } else {
          var compiler = tplManager.templates['holds'];
          innerSelection.innerHTML = compiler(response.oData);
          iniciarTomasControles(api, response.oData, idTipoEspectaculo);
          resolve();
        }
      } else {
        reject();
      }
    }).catch(function (err) {
      reject(err);
    });
  });
}

var SELECTOR = false; // funcion que observa los cambios de productos

function ObservableProductos() {
  var self = this;
  this.data = {};
  this.observers = [];

  this.add = function (observer) {
    this.observers.push(observer);
  };

  this.notify = function (obj, index, totalItems) {
    this.observers.forEach(function (observer) {
      observer.call(self, obj, index, totalItems);
    });
  };

  this.remove = function (cb) {
    if (typeof cb === 'function') {
      cb.call(self);
    }
  };
}

var cJsonListaProductosObservable = new ObservableProductos();
cJsonListaProductosObservable.add(function (obj) {
  if ($.isEmptyObject(obj)) {
    return;
  }

  var self = this;
  var keys = Object.keys(obj);
  var dataKeys = 0;
  var cJsonListaProductoInput = $('[name="cJsonListaProductos"]');
  var productoAgregar = $('.button-agregaproductos');
  keys.forEach(function (value, i) {
    if (obj[value] !== null && obj[value] !== undefined) {
      Object.assign(self.data, obj);
    } else {
      if (value in self.data) {
        delete self.data[value];
      }
    }

    if (i === keys.length - 1) {
      dataKeys = Object.keys(self.data);

      if (dataKeys.length > 0) {
        var arr = dataKeys.map(function (key) {
          return self.data[key];
        });
        cJsonListaProductoInput[0].value = JSON.stringify(arr);

        if (arr.length > 0) {
          productoAgregar.removeClass('disabled').css('opacity', 1);
        }
      } else {
        productoAgregar.addClass('disabled').css('opacity', .80);
      }
    }
  });
});

var oProducto = function oProducto(obj, index, totalItems) {
  index = parseInt(index);

  if ($.isEmptyObject(obj)) {
    return;
  }

  var self = this;
  var keys = Object.keys(obj);
  var checkBox = document.querySelector('[rel="check-' + index + '"]'); // console.info(checkBox)

  keys.forEach(function (value) {
    if (obj[value] !== null && obj[value] !== undefined) {
      Object.assign(self.data, obj);
    } else {
      if (value in self.data) {
        delete self.data[value];
      }
    }
  });
  var producto = {};

  if (obj['oIngresoDatoValores'] !== undefined && obj['nCantidadSeleccionada'] > 0) {
    var cc = Object.keys(obj['oIngresoDatoValores']).length;

    if (cc === totalItems) {
      producto[index] = this.data;
      cJsonListaProductosObservable.notify(producto);
      checkBox.checked = true;
    }
  }

  if (totalItems === 0) {
    producto[index] = this.data;
    cJsonListaProductosObservable.notify(producto);
    checkBox.checked = true;
  }
};

function initProductModal(api) {
  var confirmSeleccion = document.querySelector('#submit-process');
  $('.select-producto-adicional').selectpicker();
  $('.producto-row').each(function () {
    var objRow = {};
    var oIngresoDatoValores = {};
    var row = $(this),
        rowAttr = row.attr('rel');
    var totalItems = row.find('select.oIngresoDatoValores').length;
    var totalItemsInputs = row.find('input.listaIngresoDatoSimple').length;

    if (totalItems === 0) {
      totalItems = totalItemsInputs;
    } // console.info(rowAttr, totalItems)


    var idProducto = row.find('[rel="idProducto"]');
    var idProductoAsignacion = row.find('[rel="idProductoAsignacion"]');
    var cJsonProductoObservable = new ObservableProductos();
    cJsonProductoObservable.add(oProducto);
    row.find('select.select-producto-adicional').each(function () {
      $(this).on({
        change: function change() {
          var sel = $(this),
              attr = sel.attr('rel');

          if (!sel.hasClass('oIngresoDatoValores')) {
            objRow['idProducto'] = parseInt(idProducto.val());
            objRow['idProductoAsignacion'] = parseInt(idProductoAsignacion.val());
            objRow[attr] = attr === 'nCantidadSeleccionada' ? parseInt(sel.val()) : sel.val();
          } else {
            oIngresoDatoValores[attr] = sel.val();

            if (objRow['oIngresoDatoValores'] === undefined) {
              objRow['oIngresoDatoValores'] = {};
            }

            objRow['oIngresoDatoValores'] = oIngresoDatoValores;
          }

          cJsonProductoObservable.notify(objRow, rowAttr, totalItems);
        }
      });
    });
    row.find('.form-check').each(function () {
      $(this).on({
        click: function click() {
          var cJsonListaProductoInput = $('[name="cJsonListaProductos"]');
          var input = $(this).find('[type="checkbox"]');

          if (input[0].checked === false) {
            objRow = {};
            row.find('.producto-selector').each(function () {
              $(this).find('option').eq(0).prop('selected', true);
            });
            cJsonProductoObservable.remove(function () {
              var self = this;
              self.data = {};
              cJsonListaProductosObservable.remove(function () {
                var self = this;
                delete self.data[rowAttr];
                var dataKeys = Object.keys(self.data);
                var arr = [];

                if (dataKeys.length > 0) {
                  arr = dataKeys.map(function (key) {
                    return self.data[key];
                  });
                }

                cJsonListaProductoInput[0].value = JSON.stringify(arr);
                row.find('select').each(function () {
                  $(this).find('option').eq(0).prop('selected', true);
                  $(this).selectpicker('refresh');
                });
                row.find('input.listaIngresoDatoSimple').each(function () {
                  $(this).val('');
                });

                if (Object.keys(self.data).length === 0) {
                  var productoAgregar = $('.button-agregaproductos');
                  productoAgregar.addClass('disabled').css('opacity', .80);
                }
              });
            });
          }
        }
      });
    });
    row.find('.listaIngresoDatoSimple').each(function () {
      var sel = $(this);
      var pattern = sel[0].pattern;
      var re = new RegExp("".concat(pattern)); // console.info(re)

      sel[0].addEventListener('input', function () {
        if (re.test(this.value)) {
          sel[0].classList.remove('error');
        } else {
          sel[0].classList.add('error');
        }
      });
      $(this).on({
        change: function change() {
          var sel = $(this),
              attr = sel.attr('rel');

          if (!sel.hasClass('error')) {
            if (sel.hasClass('listaIngresoDatoSimple')) {
              oIngresoDatoValores[attr] = sel.val();

              if (objRow['oIngresoDatoValores'] === undefined) {
                objRow['oIngresoDatoValores'] = {};
              }

              objRow['oIngresoDatoValores'] = oIngresoDatoValores;
              cJsonProductoObservable.notify(objRow, rowAttr, totalItemsInputs);
            }
          } else {
            objRow = {};
            sel[0].value = '';
            var cJsonListaProductoInput = $('[name="cJsonListaProductos"]');
            cJsonProductoObservable.remove(function () {
              var self = this;
              self.data = {};
              cJsonListaProductosObservable.remove(function () {
                var self = this;
                delete self.data[rowAttr];
                var dataKeys = Object.keys(self.data);
                var arr = [];

                if (dataKeys.length > 0) {
                  arr = dataKeys.map(function (key) {
                    return self.data[key];
                  });
                }

                cJsonListaProductoInput[0].value = JSON.stringify(arr);
              });
            });
          }
        },
        input: function input() {
          var sel = $(this),
              attr = sel.attr('rel');

          if (!sel.hasClass('error')) {
            if (sel.hasClass('listaIngresoDatoSimple')) {
              oIngresoDatoValores[attr] = sel.val();

              if (objRow['oIngresoDatoValores'] === undefined) {
                objRow['oIngresoDatoValores'] = {};
              }

              objRow['oIngresoDatoValores'] = oIngresoDatoValores;
              cJsonProductoObservable.notify(objRow, rowAttr, totalItemsInputs);
            }
          } else {
            objRow = {};
            sel[0].value = '';
            var cJsonListaProductoInput = $('[name="cJsonListaProductos"]');
            cJsonProductoObservable.remove(function () {
              var self = this;
              self.data = {};
              cJsonListaProductosObservable.remove(function () {
                var self = this;
                delete self.data[rowAttr];
                var dataKeys = Object.keys(self.data);
                var arr = [];

                if (dataKeys.length > 0) {
                  arr = dataKeys.map(function (key) {
                    return self.data[key];
                  });
                }

                cJsonListaProductoInput[0].value = JSON.stringify(arr);
              });
            });
          }
        }
      });
    });
  });
  var modal = $('#productos-adicionales');
  modal.off();
  modal.on('show.bs.modal', function () {
    var productoAgregar = $('.button-agregaproductos');
    productoAgregar.addClass('disabled').css('opacity', .80);
  });
  modal.modal({
    backdrop: 'static',
    keyboard: false
  }).one('hidden.bs.modal', function () {
    var lb = document.querySelector('.border-box .showbox');
    sholoading(lb, 'Procesando su seleccion', function () {
      var input = document.querySelector('[name="cJsonListaProductos"]');
      var arr = JSON.parse(input.value);

      if (arr.length === 0) {
        confirmSeleccion.click();
      } else {
        api.setProductoAsignado(api.$curProcessId, {
          cJsonProductoAsignadoSeleccionado: input.value
        }).then(function (response) {
          var leyendaProductoError = document.querySelector('#leyendaProductoError');

          if (response.nCode === 200 && response.oData.nResultado === 0) {
            leyendaProductoError.style.display = 'none';
            leyendaProductoError.innerHTML = '';
            confirmSeleccion.classList.add('setProcess');
            confirmSeleccion.click();
          } else {
            hideloading(lb);
            leyendaProductoError.style.display = 'block';
            leyendaProductoError.innerHTML = response.oData.cMensaje;
            document.querySelector('#contenido-adicional-producto-rechazo').innerHTML = response.oData.cMensaje || 'No se pudieron agregar los productos seleccionados';
            $('#producto-rechazo').modal({
              backdrop: 'static',
              keyboard: false
            }).one('hidden.bs.modal', function () {
              confirmSeleccion.click();
            });
            $('.producto-rechazo').on({
              click: function click(e) {
                e.preventDefault();
                $('#producto-rechazo').modal('hide');
              }
            });
          }
        });
      }
    });
  });
  document.querySelector('.button-nogracias').addEventListener('click', function () {
    var input = document.querySelector('[name="cJsonListaProductos"]');
    input.value = '[]';
    confirmSeleccion.classList.add('setProcess');
    modal.modal('hide');
  });
  document.querySelector('.button-agregaproductos').addEventListener('click', function () {
    if (!$(this).hasClass('disabled')) {
      modal.modal('hide');
    }
  });
}

export default {
  name: 'seleccion',
  path: '/seleccion/:id',
  template: SELECTOR ? '/views/selector.html' : '/views/seleccion.html',
  props: function props(ctx, api) {
    $('#autorizado').modal('hide');
    var loading = document.querySelector('.showbox');
    loading.classList.add('is-visible');
    loading.classList.remove('is-hidden');
    return new Promise(function (resolve, reject) {
      var id = ctx.params.id;
      ctx.idfuncion = id; // si selector es verdadero, se inicia la vista
      // con un selector de funciones

      if (SELECTOR) {
        api.getEspectaculo(id).then(function (response) {
          if (response.nCode === 200) {
            api.$curEID = response.oData.idEspectaculo;
            resolve(response.oData.Dato);
          } else {
            resolve({});
          }
        }).catch(function (err) {
          reject(err);
        });
      } else {
        // si selector es falso
        // se inicia la vista con la seleccion de ubicacion
        var query = '';

        if (ctx.querystring) {
          query = "?".concat(ctx.querystring);
        }

        api.getFuncion(id, query).then(function (response) {
          if (response.nCode === 200) {
            api.prevViewSeleccion = "/seleccion/".concat(id).concat(query);
            api.$curEID = response.oData.Funcion.idCartel;
            response.oData.Funcion.Sectores.oData.Sectores = response.oData.Funcion.Sectores.oData.Sectores.filter(function (item) {
              return item.CantidadDisponible > 0;
            });
            response.oData.Funcion.Sectores.oData['idEspectaculoTipo'] = response.oData.Funcion.idEspectaculoTipo; // console.info(response.oData.Funcion);

            api.funcionStore = response.oData.Funcion;
            resolve(response.oData.Funcion);
            ctx.cSVG = response.oData.Funcion.Sectores.oData.cSvg;
          } else {
            resolve({});
          }
        }).catch(function (err) {
          reject(err);
        });
      }
    });
  },
  enter: function enter(ctx, next, mounted, api) {
    scrolltop();
    var loading = document.querySelector('.showbox');
    var container = document.querySelector('#app');
    container.innerHTML = mounted.compiled;

    if (!tplManager) {
      tplManager = new TemplateManager(api.$root);
    }

    if (document.querySelector('[data-component="generico"]')) {
      // MAPA DE SECTORES
      if (window['SvgMapa'] === undefined) {
        window.SvgMapa = '<svg></svg>';
      }

      api.load(ctx.cSVG, false, function () {
        if (typeof SvgMapa !== 'undefined' || SvgMapa !== null) {
          // variable is undefined or null
          var parser = new DOMParser();
          var html = parser.parseFromString(SvgMapa, 'image/svg+xml');
          var node = document.importNode(html.documentElement, true);
          var hh = document.createElement('div');
          hh.appendChild(node);
          var svgContent = document.querySelector('#svg-file');
          svgContent.innerHTML = $(hh).html();
          var svgContent2 = document.querySelector('#svg-file-2');
          svgContent2.innerHTML = $(hh).html();
        }

        if (SELECTOR) {
          iniciarSelectorFuncion(api).then(function () {}).catch(function (e) {// console.info(e)
          });
        } else {
          if (parseInt(ctx.idfuncion) > 0) {
            observable.data = {};
            observable.data['idFuncion'] = parseInt(ctx.idfuncion);
            observable.notify({
              ['idProcesoDeCompra']: ''
            });
            var button = document.querySelector('#submit-process');
            button.disabled = true;
            button.style.opacity = .5;
            button.style.cursor = 'not-allowed';
            var lbLegend = document.querySelector('#load-legend');
            lbLegend.innerHTML = 'Cargando informacion';
            iniciarSelectoresToma(api);
          }
        }

        if (loading.classList.contains('is-visible')) {
          loading.classList.remove('is-visible');
          loading.classList.add('is-hidden');
        }

        document.querySelector('#submit-codigo-envio').addEventListener('click', function () {
          var value = document.querySelector('[name="cCodigo"]').value;
          var serialize = JSON.stringify({
            cCodigo: value
          });
          observable.notify({
            cPromocionDatoAValidar: serialize
          });
          $('#promocion').modal('hide');
        });
        var confirmSeleccion = document.querySelector('#submit-process');
        var legendaSiguiente = document.querySelector('#legendaSiguiente');

        var handler = function handler(e) {
          if (this.disabled === true) return false;
          legendaSiguiente.innerHTML = '';
          legendaSiguiente.style.display = 'none';

          if (observablePermisso.data === 0) {
            legendaSiguiente.innerHTML = 'No es posible continuar, no hay selecciones en proceso';
            legendaSiguiente.style.display = 'block';
            return false;
          }

          var innerSelection = document.querySelector('#contenido-adicional-producto');
          innerSelection.innerHTML = '';

          if (!confirmSeleccion.classList.contains('setProcess')) {
            e.preventDefault();
            var lb = document.querySelector('.border-box .showbox');
            sholoading(lb, 'Procesando su seleccion', function () {
              api.getProductoAsignado().then(function (response) {
                if (response.nCode === 200) {
                  if (response.oData !== null) {
                    if (response.oData.listaItemProductoAsignado.length > 0) {
                      response.oData.listaItemProductoAsignado.map(function (item) {
                        var min = item.nCantidadSeleccionMinima;
                        var max = item.nCantidadSeleccionMaxima;
                        var list = [];

                        for (var i = min; i <= max; i++) {
                          list.push(i);
                        }

                        return item['litaOpciones'] = list;
                      });

                      if (!tplManager.exist('producto')) {
                        tplManager.add('producto', '/templates/producto/lista.html').then(function (compiler) {
                          innerSelection.innerHTML = compiler(response.oData);
                          initProductModal(api);
                        }).catch(function (err) {
                          resolve(err);
                        });
                      } else {
                        var compiler = tplManager.templates['producto'];
                        innerSelection.innerHTML = compiler(response.oData);
                        initProductModal(api);
                      }
                    } else {
                      // si no hay productos
                      confirmSeleccion.classList.add('setProcess');
                      confirmSeleccion.click();
                    }

                    hideloading(lb);
                  } else {
                    // si no hay productos
                    confirmSeleccion.classList.add('setProcess');
                    confirmSeleccion.click();
                  }
                }
              });
            });
          }
        };

        confirmSeleccion.addEventListener('click', handler, false);
      });
    } else {
      if (parseInt(ctx.idfuncion) > 0) {
        observable.data = {};
        observable.data['idFuncion'] = parseInt(ctx.idfuncion);
        observable.notify({
          ['idProcesoDeCompra']: ''
        });
        var button = document.querySelector('#submit-process');
        button.disabled = true;
        button.style.opacity = .5;
        button.style.cursor = 'not-allowed';
        var lbLegend = document.querySelector('#load-legend');
        lbLegend.innerHTML = 'Cargando informacion';
        iniciarSelectoresTomaProducto(api);
      }

      loading.classList.remove('is-visible');
      loading.classList.add('is-hidden');
    }
  }
};