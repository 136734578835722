export default {
  name: 'notfound',
  path: '*',
  template: '/views/notfound.html',
  props: function props(ctx, api) {
    window.location = api.apiRedirectTo;
    return new Promise(function (resolve, reject) {
      resolve();
    });
  },
  enter: function enter(ctx, next, mounted) {
    var container = document.querySelector('#app');
    container.innerHTML = mounted.compiled;
  }
};