import is from 'is';
import TemplateManager from '../../app/core/TemplateManager';

function getFormObjectParams(form) {
  var formElements = form.elements;
  var obj = {};

  for (var i = 0; i < formElements.length; i++) {
    if (formElements[i].name !== '') {
      obj[formElements[i].name] = formElements[i].value;
    }
  }

  return obj;
}

export default (function (api) {
  var logued = new TemplateManager(api.$root);
  var content = document.querySelector('#dropdown-usr');
  var button = document.querySelector('#sb-nav-button span');
  $('#login-form').validate({
    rules: {
      cMail: {
        required: true,
        email: true
      },
      cClave: 'required'
    },
    messages: {
      cMail: {
        required: '',
        email: ''
      },
      cClave: ''
    }
  });
  $('.usr').on({
    click: function click(e) {
      e.preventDefault();
      var href = $(this).attr('href');

      if (href === '#ingreso') {
        if ($('.modal:visible').length && $('body').hasClass('modal-open')) {
          $('#registro-form')[0].reset();
          $('#registro').modal('hide').one('hidden.bs.modal', function () {
            $(href).modal('show');
          });
        } else {
          $(href).modal('show');
        }
      } else if (href === '#registro') {
        if ($('.modal:visible').length && $('body').hasClass('modal-open')) {
          $('#login-form')[0].reset();
          $('#ingreso').modal('hide').one('hidden.bs.modal', function () {
            $(href).modal('show');
          });
        } else {
          $(href).modal('show');
        }
      }
    }
  });
  $('#login-form-recupero').validate({
    rules: {
      cEmail: {
        email: true
      },
      cDocumento: {
        number: true
      }
    },
    messages: {
      cEmail: {
        email: ''
      },
      cDocumento: {
        number: ''
      }
    }
  });
  $('#cambiopasswordboton').on({
    click: function click(e) {
      e.preventDefault();
      $('#ingreso').modal('hide');
      $('#recuperoLeyenda').html(''); // $('#login-form-password')[0].reset();

      $('#recuperoC').modal('show');
    }
  });
  $('#botonRecupero').on({
    click: function click(e) {
      e.preventDefault();
      var rec = $('#login-form-recupero');
      var mail = rec.find('[name="cMail"]'),
          doc = rec.find('[name="cDocumento"]');

      if (mail.val().length === 0 && doc.val().length === 0) {
        return false;
      }

      if (rec.valid()) {
        api.setClaveBlanqueo({
          "cMail": mail.val(),
          "cDocumento": doc.val()
        }).then(function (res) {
          if (res.oDataRecupero.oData) {
            $('#recuperoLeyenda').html(res.oDataRecupero.oData.cMensaje);
          }
        });
      } else {
        $('#recuperoLeyenda').html('');
      }
    }
  });

  function init(response, api) {
    if (is.object(response) && !is.empty(response)) {
      if (!logued.exist('logued')) {
        logued.add('logued', '/templates/shared/logueado.html').then(function (compiler) {
          content.innerHTML = compiler({});
          var client = window.localStorage.getItem('client');
          button.innerHTML = response.oCliente.cNombre || JSON.parse(client).cNombre;
          var open = $('.modal:visible').length;
          /**&& $('body').hasClass('modal-open')*/

          if (open > 0) {
            $('.modal').modal('hide').one('hidden.bs.modal', function () {
              if (api['setLogin'] === true) {
                var email = document.querySelector('[name="cMail"]');
                var xxx = document.querySelector('[name="cClave"]');

                if (email.value !== '' && xxx.value !== '') {
                  api['setLogin'] = false;
                  var confirm = document.querySelector('#confirmar-seleccion');
                  confirm.disabled = false;
                  confirm.click();
                }
              }
            });
            button.classList.remove('load');
            button.disabled = false;
          }
        }).catch(function (e) {
          console.log(e);
        });
      } else {
        var compiler = logued.templates['logued'];
        content.innerHTML = compiler({});
        button.innerHTML = response.oCliente.Nombre;
        var open = $('.modal:visible').length;
        /**&& $('body').hasClass('modal-open')*/

        if (open > 0) {
          $('.modal').modal('hide').one('hidden.bs.modal', function () {
            if (api['setLogin'] === true) {
              var email = document.querySelector('[name="cMail"]');
              var xxx = document.querySelector('[name="cClave"]');

              if (email.value !== '' && xxx.value !== '') {
                api['setLogin'] = false;
                var confirm = document.querySelector('#confirmar-seleccion');
                confirm.disabled = false;
                confirm.click();
              }
            }
          });
          button.classList.remove('load');
          button.disabled = false;
        }
      }
    }
  }

  var usuario_email = window.localStorage.getItem('usuario_email');

  if (usuario_email) {
    document.querySelector('[name="cMail"]').value = usuario_email;
    $('[name="rememberme"]').prop('checked', true);
  }

  return new Promise(function (resolve, reject) {
    if (api.token) {
      api.loginStatus().then(function (res) {
        if (!is.empty(res.oCliente)) {
          init(res, api);
          api.cliente = JSON.parse(window.localStorage.getItem('client'));
        } else {
          api.cliente = {};
        }
      });
    } else {
      var submit = document.querySelector('.submit-class');
      var form = document.querySelector('[name="login"]');
      submit.addEventListener('click', function (e) {
        e.preventDefault();
        var check = $('[name="rememberme"]').is(':checked');

        if ($('#login-form').valid()) {
          var params = getFormObjectParams(form);
          submit.classList.add('load');
          submit.disabled = true;
          api.loginUser(params).then(function (response) {
            if (check) {
              var cmail = document.querySelector('[name="cMail"]');
              window.localStorage.setItem('usuario_email', cmail.value);
            }

            $('#login-form').removeClass('no-loguin');

            if (response.oCliente.nResultado === 0) {
              $('#respuesta-login').css('display', 'none');
              init(response, api);
              api.cliente = response.oCliente;
              window.localStorage.setItem('client', JSON.stringify(response.oCliente));
            } else {
              api.cliente = {};
              submit.classList.add('load');
              submit.disabled = true;
              $('#respuesta-login').html(response.oCliente.cMensaje).css('display', 'block');
              $('#login-form').addClass('no-loguin');
            }
          }).catch(function (err) {
            if (err) console.log(err);
            api.cliente = {};
            $('#respuesta-login').html('No se pudo validar el usuario').css('display', 'block');
          }).finally(function () {
            submit.classList.remove('load');
            submit.disabled = false;
          });
        }
      });
    }

    $('#recuperoC').one('show.bs.modal', function () {
      $('#login-form').addClass('no-loguin');
      $('#ingreso').modal('hide');
    });
    resolve();
  });
});